import {BasicTextField} from "./field-components/common/BasicTextField";
import {DisplayNameField} from "./field-components/DisplayNameField";
import React, {useEffect, useState} from "react";
import {useWatch} from "react-hook-form";
import {makeDuplicateCheckApiCall} from "../../api/jobNimbusFunctions";
import {DuplicateCheckData} from "./CallTakingDialog";
import {DuplicateCheckResult} from "../../api/jobNimbusInterfaces";

interface IFirstLastCompanyDisplayNameFieldsProps {
    control: any;
    setValue: any;
    trigger: any;
    setDuplicateCheckResponseData: React.Dispatch<
        React.SetStateAction<DuplicateCheckResult[] | []>
    >;
    setTableDataIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    performReset: boolean;
    skipInitialAttempt: boolean;
    setSkipInitialAttempt: React.Dispatch<React.SetStateAction<boolean>>;

}

export const FirstLastCompanyDisplayNameFields: React.FC<IFirstLastCompanyDisplayNameFieldsProps> = ({
                                                                                                         control,
                                                                                                         setValue,
                                                                                                         trigger,
                                                                                                         setDuplicateCheckResponseData,
                                                                                                         setTableDataIsLoading,
                                                                                                         performReset,
                                                                                                         skipInitialAttempt,
                                                                                                         setSkipInitialAttempt
                                                                                                     }) => {
    //Contains:
    //  - display_name logic
    //  - duplicateCheck logic

    //display_name logic behavior:
    //  - Automatically fill displayName as "firstName lastName"
    //  - unless user has interacted with displayName
    //  - Return to original behavior if displayName is set to "" - ("firstName lastName")
    //  - Manage it from this parent component using state
    //  - Tap into react-hook-form to update display_name value

    //additions:
    //  - Using skipInitialAttempt (true on initial pageload and after copyInfo fires),
    //    Detect if we are using CopyInfo logic. We don't want it to override what we're importing
    //  -

    const first_name = useWatch({control, name: "first_name", defaultValue: ""});
    const last_name = useWatch({control, name: "last_name", defaultValue: ""});
    const display_name = useWatch({control, name: "display_name", defaultValue: ""});

    const [isDisplayNameCustom, setIsDisplayNameCustom] = useState<boolean>(false);
    const [displayNameWasUpdatedAutomatically, setDisplayNameWasUpdatedAutomatically] = useState<boolean>(false);

    interface IDisplayNameRefObj {
        first_name: string;
        last_name: string;
        display_name: string;
    }

    const [displayNameRefObj, setDisplayNameRefObj] = useState<IDisplayNameRefObj>({
        first_name: first_name,
        last_name: last_name,
        display_name: display_name,
    });

    const updateDisplayName = () => {

        //Dont perform logic if copyInfo was just triggered
        //Prevents overriding recently copied in info
        if (skipInitialAttempt){
            setSkipInitialAttempt(false)
            return
        }

        if (!isDisplayNameCustom) {
            setValue("display_name", `${first_name} ${last_name}`.trim());
            setDisplayNameWasUpdatedAutomatically(true);
            trigger("display_name");
        }

        setDisplayNameRefObj({
            first_name: first_name,
            last_name: last_name,
            display_name: `${first_name} ${last_name}`.trim(),
        });
    };

    // Effect for first_name and last_name updates
    useEffect(() => {
        updateDisplayName();
    }, [first_name, last_name]);

    // Effect for display_name updates
    useEffect(() => {
        if (
            first_name === displayNameRefObj.first_name &&
            last_name === displayNameRefObj.last_name &&
            display_name !== displayNameRefObj.display_name &&
            display_name !== "" &&
            !displayNameWasUpdatedAutomatically
        ) {
            setIsDisplayNameCustom(true);
        } else if (display_name === "" && !displayNameWasUpdatedAutomatically) {
            setIsDisplayNameCustom(false);
        }

        setDisplayNameRefObj({
            first_name: first_name,
            last_name: last_name,
            display_name: `${first_name} ${last_name}`.trim(),
        });

        setDisplayNameWasUpdatedAutomatically(false);
    }, [display_name]);

    //DUPLICATE CHECK start
    const [duplicateCheckData, setDuplicateCheckData] = useState<DuplicateCheckData>(getEmptyDuplicateCheckData());

    function getEmptyDuplicateCheckData(): DuplicateCheckData {
        return {
            display_name: "",
            first_name: "",
            last_name: "",
            company: "",
            result: true,
        };
    }

    function areDuplicateChecksEqual(obj1: DuplicateCheckData, obj2: DuplicateCheckData): boolean {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            // @ts-ignore
            if (obj1[key] !== obj2[key]) {
                return false;
            }
        }

        return true;
    }

    useEffect(() => {
        if (areDuplicateChecksEqual(duplicateCheckData, getEmptyDuplicateCheckData())) {
            return;
        }

        (async () => {
            try {
                setTableDataIsLoading(true)
                const duplicateCheckResponse = await makeDuplicateCheckApiCall(duplicateCheckData);
                setDuplicateCheckResponseData(duplicateCheckResponse?.results);
            } finally {
                setTableDataIsLoading(false)
            }
        })();
    }, [duplicateCheckData]);

    //Reset duplicateCheckData if we are RESET FORM
    useEffect(() => {
        if (performReset){
            setDuplicateCheckData(getEmptyDuplicateCheckData())
        }
    }, [performReset]);

    //DUPLICATE CHECK end


    const updateDuplicateCheckData = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, property_name: string) => {
        console.log(duplicateCheckData, property_name)
        if (e.target.value === duplicateCheckData[property_name]) {
            return;
        }

        setDuplicateCheckData((prevData) => {
            if (property_name in prevData) {
                return {
                    ...prevData,
                    [property_name]: e.target.value,
                };
            }

            return prevData;
        });
    };

    return (
        <>
            <BasicTextField
                control={control}
                label={"First Name"}
                name={"first_name"}
                updateDuplicateCheckData={updateDuplicateCheckData}
                assignFocus={true}
            />
            <BasicTextField
                control={control}
                label={"Last Name"}
                name={"last_name"}
                updateDuplicateCheckData={updateDuplicateCheckData}
            />
            <BasicTextField
                control={control}
                label={"Company"}
                name={"company"}
                updateDuplicateCheckData={updateDuplicateCheckData}
            />
            <DisplayNameField
                control={control}
                label={"Display Name"}
                name={"display_name"}
                updateDuplicateCheckData={updateDuplicateCheckData}
                required={"Display name cannot be blank"}
            />
        </>
    );
};
