import {FormInputOptionPropsStringString, FormInputProps} from "../FormInputProps.tsx";
import {BasicSelect} from "./common/BasicSelect.tsx";
import {useEffect, useState} from "react";
import {getSalesReps} from "../../../api/jobNimbusFunctions.ts";

export function SalesRepSelect({control, label, accountInfo, setValue}: FormInputProps) {

    const [salesReps, setSalesReps] = useState<FormInputOptionPropsStringString[]>([]);


    useEffect(() => {
        if (!accountInfo) {
            return;
        }
        let salesRepsTemp = getSalesReps(accountInfo)
        setSalesReps(salesRepsTemp);
        if (salesRepsTemp && salesRepsTemp.length > 0) {
            setValue("sales_rep_name", salesRepsTemp[0].value)
        }

    }, []);


    return (

        <BasicSelect control={control}
                     label={label}
                     options={salesReps}
                     name={"sales_rep_name"}
                     defaultValue={salesReps && salesReps.length > 0 ? salesReps[0].value : ""}
        />
    );
}