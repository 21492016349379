// SettingsPageCityColorsTab.tsx
import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import {Delete, Edit, Square} from '@mui/icons-material';

import {colorList} from './colorList';
import {AxiosResponse} from "axios";
import instance from "../../../providers/AxiosWithAuthProvider"; // Adjust the path as needed

const apiBaseUrl = import.meta.env.VITE_REACT_APP_API_SERVER_URL;

interface ICityColor {
    id?: number;
    cityMatcher: string;
    hexColor: string;
    colorName: string;
}

interface IColor {
    name: string;
    hex: string;
}

export const SettingsPageColorsTab: React.FC = () => {

    const [cityColors, setCityColors] = useState<ICityColor[]>([]);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [isEditDialogOpen, setEditDialogOpen] = useState<boolean>(false);

    const [cityColorNameToDelete, setCityColorNameToDelete] = useState<string | undefined>();
    const [cityColorIdToDelete, setCityColorIdToDelete] = useState<number | undefined>();
    const [deleteConfirmation, setDeleteConfirmation] = useState<string>('');
    const [newCityColor, setNewCityColor] = useState<ICityColor>({cityMatcher: '', hexColor: '', colorName: ''});
    const [newCityColorError, setNewCityColorError] = useState<string>('');
    const [editCityColor, setEditCityColor] = useState<ICityColor>({cityMatcher: '', hexColor: '', colorName: ''});

    useEffect(() => {
        loadCityColors();
    }, []);

    const loadCityColors = async () => {
        try {
            const response: AxiosResponse<ICityColor[]> = await instance.get(`${apiBaseUrl}/api1/citycolors`);
            setCityColors(response.data);
        } catch (error) {
            console.error('Error loading city colors:', error);
        }
    };
    const handleDeleteDialogOpen = (cityColor: ICityColor) => {
        setDeleteDialogOpen(true);
        setCityColorNameToDelete(cityColor?.cityMatcher);
        setCityColorIdToDelete(cityColor?.id)
        setDeleteConfirmation('');
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
        setCityColorNameToDelete(undefined);
        setDeleteConfirmation('');
    };

    const handleDeleteCityColor = async () => {
        try {
            await instance.delete(`${apiBaseUrl}/api1/citycolors/${cityColorIdToDelete}`);
            await loadCityColors();
            handleDeleteDialogClose();
        } catch (error) {
            console.error('Error deleting city color:', error);
        }
    };
    const handleCreateCityColor = async () => {
        if (newCityColor.cityMatcher.trim() === '' || newCityColor.hexColor.trim() === '' || newCityColor.colorName.trim() === '') {
            setNewCityColorError('City name, hex color, and color name cannot be blank');
            return;
        }

        setNewCityColorError('');

        try {
            const response: AxiosResponse = await instance.post(`${apiBaseUrl}/api1/citycolors`, newCityColor);

            if (response.status !== 201) {
                console.error('Error creating city color:', response.statusText);
                return;
            }

            await loadCityColors();
            setNewCityColor({ cityMatcher: '', hexColor: '', colorName: '' });
        } catch (error) {
            console.error('Error creating city color:', error);
        }
    };

    const [originalCityColor, setOriginalCityColor] = useState<ICityColor | null>(null);
    const handleEditCityColor = (cityColor: ICityColor) => {
        setEditCityColor({...cityColor});
        setOriginalCityColor({...cityColor});
        setEditDialogOpen(true);
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
        setEditCityColor({cityMatcher: '', hexColor: '', colorName: ''});
    };

    const handleUpdateCityColor = async () => {
        try {
            const response: AxiosResponse = await instance.put(`${apiBaseUrl}/api1/citycolors/${editCityColor.id}`, editCityColor);

            if (response.status !== 200) {
                console.error('Error updating city color:', response.statusText);
            }

            await loadCityColors();
            handleEditDialogClose();
        } catch (error) {
            console.error('Error updating city color:', error);
        }
    };

    return (
        <>
            <Grid container spacing={2} p={2}>
                {/* Left side: Non-working days and working hours */}
                <Grid item xs={12} md={6} lg={6}>

                    <Card elevation={4}>
                        <CardHeader title="City Colors"/>
                        <CardContent style={{
                            borderTop: '1px solid grey',
                            borderBottom: '1px solid grey',
                            maxHeight: '50vh',
                            overflow: 'auto'
                        }}>
                            <List>
                                {cityColors.map((cityColor, index) => (
                                    <React.Fragment key={`fragment-${cityColor.id}`}>
                                        <ListItem key={"list-" + cityColor.id}>
                                            <ListItemIcon><Square sx={{color: cityColor.hexColor}}/></ListItemIcon>
                                            <ListItemText primary={cityColor.cityMatcher}
                                                          secondary={cityColor.colorName}/>
                                            <ListItemSecondaryAction>
                                                <IconButton edge="end" aria-label="edit"
                                                            onClick={() => handleEditCityColor(cityColor)}>
                                                    <Edit/>
                                                </IconButton>
                                                <IconButton edge="end" aria-label="delete"
                                                            onClick={() => handleDeleteDialogOpen(cityColor)}>
                                                    <Delete/>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        {index < cityColors.length - 1 && <Divider/>}
                                    </React.Fragment>
                                ))}
                            </List>
                        </CardContent>
                        <CardActions sx={{ml: "16px", mr: "16px", mt: "8px", mb: "8px"}}>
                            {/* Add more buttons as needed */}
                            <Box display="flex" alignItems="center">
                                <TextField
                                    label="City Name"
                                    value={newCityColor.cityMatcher}
                                    onChange={(e) => setNewCityColor({...newCityColor, cityMatcher: e.target.value})}
                                    variant="outlined"
                                    size="small"
                                    error={Boolean(newCityColorError)}
                                    helperText={newCityColorError}
                                />
                                <FormControl size="small" sx={{width: 400, ml: 2, height: "auto"}}>
                                    <InputLabel>Color</InputLabel>
                                    <Select
                                        label="Color"
                                        value={newCityColor.hexColor}
                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            let color = colorList.find((color: IColor) => color.hex === e.target.value)

                                            setNewCityColor(
                                                {
                                                    ...newCityColor,
                                                    hexColor: e.target.value as string,
                                                    colorName: color?.name ? color.name : "Color Name Not Found" as string
                                                }
                                            )
                                        }}
                                        variant="outlined"
                                        size="small"
                                    >
                                        {colorList.map((color) => (
                                            <MenuItem key={"new-" + color.hex} value={color.hex}>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <Square sx={{color: color.hex}}/>
                                                    <div>{color.name}</div>
                                                </div>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>

                            {/*<Button*/}
                            {/*    variant={"contained"}*/}
                            {/*    onClick={handleCreateCityColor}*/}
                            {/*    sx={{ml: 2}}*/}
                            {/*    disabled={!newCityColor.code.trim()}*/}
                            {/*>Add Job Code</Button>*/}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCreateCityColor}
                                sx={{ml: 2}}
                                disabled={!newCityColor.cityMatcher.trim() || !newCityColor.hexColor.trim() || !newCityColor.colorName.trim()}
                            >
                                Add
                            </Button>
                            {/* Add your icon or button component */}

                        </CardActions>
                    </Card>

                    {/*Delete City Color*/}
                    <Dialog open={isDeleteDialogOpen} onClose={handleDeleteDialogClose}>
                        <DialogTitle>Confirm Delete</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Typography sx={{pb: 1}}>
                                    Are you sure you want to delete the city color with
                                    name <strong>{cityColorNameToDelete}</strong>?
                                </Typography>
                                <Typography sx={{pb: 1}}>
                                    If you're sure, type the city color name into the box below and click DELETE.
                                </Typography>
                            </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="City Color Name"
                                fullWidth
                                value={deleteConfirmation}
                                onChange={(e) => setDeleteConfirmation(e.target.value)}
                                variant="outlined"
                                size="small"
                                placeholder={`Type "${cityColorNameToDelete}" here to enable delete button`}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDeleteDialogClose} color="primary">
                                Cancel
                            </Button>
                            <Button
                                onClick={handleDeleteCityColor}
                                color="primary"
                                style={{
                                    color: deleteConfirmation !== `${cityColorNameToDelete}` ? 'default' : 'red',
                                }}
                                disabled={deleteConfirmation !== `${cityColorNameToDelete}`}
                            >
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/*Edit City Color*/}
                    <Dialog open={isEditDialogOpen} onClose={handleEditDialogClose}>
                        <DialogTitle>Edit City Color</DialogTitle>
                        <DialogContent>
                            <Box sx={{mb: 2, mt: 2}} display="flex" alignItems="center">
                                <TextField
                                    label="City Name"
                                    value={editCityColor.cityMatcher}
                                    onChange={(e) => setEditCityColor({...editCityColor, cityMatcher: e.target.value})}
                                    variant="outlined"
                                    size="small"
                                />
                                <FormControl size="small" sx={{width: 400, ml: 2}}>
                                    <InputLabel>Color</InputLabel>
                                    <Select
                                        label="Color"
                                        value={editCityColor.hexColor}
                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            let color = colorList.find((color: IColor) => color.hex === e.target.value)

                                            setEditCityColor(
                                                {
                                                    ...editCityColor,
                                                    hexColor: e.target.value as string,
                                                    colorName: color?.name ? color.name : "Color Name Not Found" as string
                                                }
                                            )
                                        }}
                                        variant="outlined"
                                        size="small"
                                    >
                                        {colorList.map((color: IColor) => (
                                            <MenuItem key={"edit-" + color.hex} value={color.hex}>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <Square sx={{color: color.hex}}/>
                                                    <div>{color.name}</div>
                                                </div>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleEditDialogClose} color="primary">
                                Cancel
                            </Button>
                            <Button
                                onClick={handleUpdateCityColor}
                                color="primary"
                                disabled={!cityColorChanged() || cityColorInvalid()}
                            >
                                Update
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>

                {/* Right side*/}
                <Grid item xs={12} md={6}></Grid>
            </Grid>


        </>
    );

    function cityColorChanged() {
        return (
            editCityColor.cityMatcher !== cityColorMatcher() ||
            editCityColor.hexColor !== cityColorHexColor()
        );
    }

    function cityColorInvalid() {
        return (
            editCityColor.cityMatcher === null ||
            editCityColor.cityMatcher === ''
        );
    }

    function cityColorMatcher() {
        return originalCityColor?.cityMatcher;
    }

    function cityColorHexColor() {
        return originalCityColor?.hexColor;
    }
};
