import {FormInputOptionPropsStringString, FormInputProps} from "../FormInputProps.tsx";
import {toCamelCaseValidHtmlId} from "../../../helpers/utility.tsx";
import {useEffect, useState} from "react";
import {Controller} from "react-hook-form";
import {Autocomplete, TextField} from "@mui/material";
import {getSubcontractors} from "../../../api/jobNimbusFunctions.ts";

export function SubcontractorsAutocomplete({control, label, name}: FormInputProps) {
    let nameNormalized = toCamelCaseValidHtmlId(label);

    const [subcontractors, setSubcontractors] = useState<FormInputOptionPropsStringString[]>([]);

    useEffect(() => {

        (async () => {
            let subcontractorsTemp = await getSubcontractors();
            setSubcontractors(subcontractorsTemp);
        })();


    }, []);


    return (
        <Controller
            control={control}
            //@ts-expect-error
            name={name ? name : nameNormalized}
            render={({field: {onChange, value}}) => (
                <Autocomplete
                    size="small"
                    sx={{pb: 2,}}
                    multiple
                    filterSelectedOptions
                    onChange={(_, selectedOptions) => {
                        onChange(selectedOptions);
                    }}
                    options={subcontractors}
                    getOptionLabel={(teamMember) => teamMember?.label}
                    value={(value as FormInputOptionPropsStringString[]) || []}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                        />
                    )}
                />
            )}
        />
    );
}
