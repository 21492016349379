import {Box, Stack} from "@mui/material";
import Toolbar from '@mui/material/Toolbar';
import {CustomScheduler} from "./CustomScheduler.tsx";
import Typography from "@mui/material/Typography";
import {Construction} from "@mui/icons-material";

const DISABLE_SCHEDULER = import.meta.env.VITE_DISABLE_SCHEDULER
console.log(`Disable Scheduler = ${DISABLE_SCHEDULER}`)
function UnderConstruction() {
    return (
        <Stack direction={"column"} spacing={3} alignItems="center" justifyContent="center" color={"black"} height={"100%"}>
            <Typography variant="h4" style={{marginTop: 16}} color={"inherit"}>
                <Construction fontSize={"medium"} color={"inherit"}/> Under Construction <Construction fontSize={"medium"} color={"inherit"}/>
            </Typography>
        </Stack>
    );
}

export default function SchedulerPage() {
    return (
        <Box
            // component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
            }}
        >
            <Toolbar/>

            <Box sx={{height: '100%'}}>
                {DISABLE_SCHEDULER === 'true' ?
                    <UnderConstruction/> :
                    <CustomScheduler/>
                }

            </Box>
        </Box>
    );
}
