import {Grid,} from "@mui/material";
import {WorkExceptions} from "./WorkExceptions.tsx";
import {WorkingDays} from "./WorkingDays";

export function SettingsPageWorkingHoursTab() {

    return (
        <>
            <Grid container spacing={2} p={2}>
                {/* Left side: Non-working days and working hours */}
                <Grid item xs={12} md={6} lg={6}>
                    <WorkingDays/>
                </Grid>

                {/* Right side: Exceptions */}
                <Grid item xs={12} md={6}>
                    <WorkExceptions/>
                </Grid>
            </Grid>
        </>
    );
}
