import { EventModel, EventModelConfig } from '@bryntum/scheduler';
import {ExternalPushMapping} from "../settings/employees-tab/ManageEmployees";

export type AppEventModelConfig = EventModelConfig & {
    desc: string;
    eventType: string;
    externalPushMappings: ExternalPushMapping[];
    externalId?: string;
    customerName?: string;
    customerContact?: string;
    customerType?: string;
    addressLine1?: string;
    addressLine2?: string;
    state?: string;
    city?: string;
    zip?: string;
}

export class SchedulerEventModel extends EventModel {

    static get $name() {
        return 'SchedulerEventModel';
    }

    static get fields() {
        return [
            { name: "name", type: "string", defaultValue : ""},
            { name: "desc", type: "string", defaultValue : ""},
            'eventType',
            'startDate',
            'endDate',
            { name: "resourceIds", persist: true},
            { name: "externalPushMappings", persist: true},
            { name: "externalId", type: "string", persist: true, defaultValue: null },
            { name: "customerName", type: "string", persist: true, defaultValue: "" },
            { name: "customerContact", type: "string", persist: true, defaultValue: "" },
            { name: "customerType", type: "string", persist: true, defaultValue: "" },
            { name: "addressLine1", type: "string", persist: true, defaultValue: "" },
            { name: "addressLine2", type: "string", persist: true, defaultValue: "" },
            { name: "state", type: "string", persist: true, defaultValue: "" },
            { name: "city", type: "string", persist: true, defaultValue: "" },
            { name: "zip", type: "string", persist: true, defaultValue: "" },
        ];
    }

    // public desc: string | undefined;
    // public eventType: string | undefined;

    constructor(config: Partial<AppEventModelConfig>) {
        super(config);
    }

}
