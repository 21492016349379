import {useEffect, useState} from "react";
import {format} from "date-fns";
import {Toast} from "@bryntum/scheduler";
import {TOAST_TIMEOUT} from "../../../constants";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    FormControl,
    FormGroup
} from "@mui/material";
import {LocalizationProvider, TimePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {useColors} from "../../../hooks/useColors";
import instance from "../../../providers/AxiosWithAuthProvider";

const apiBaseUrl = import.meta.env.VITE_REACT_APP_API_SERVER_URL;
// const apiBaseUrlProxy = apiBaseUrl + "/api1/proxy";
// const apiBaseUrlEndpoints = apiBaseUrl + "/api1";


interface PushEvent {
    eventsHaveBeenPushedToday: boolean;
    lastPushedAt?: string; // Assuming the date will be represented as a string
    executePushAt: string; // Assuming the time will be represented as a string
}

interface UpdatePushEventDto {
    executePushAt: string;
}


export const ManagePushEvent = () => {

    //Push Event start
    const loadPushEvent = async () => {
        try {
            console.log("Fetching pushEvent")
            await new Promise((resolve) => setTimeout(resolve, 250));
            const response = await instance.get(`${apiBaseUrl}/api1/pushtime`);
            const data: PushEvent = response.data;
            console.log("pushEvent", data)
            setPushEvent(data);
            console.log("executePushAt", data?.executePushAt)
            setExecutePushAt(new Date(`01/01/1970 ${data.executePushAt}`))
        } catch (error) {
            console.error('Error loading pushEvent:', error);
        }
    };
    useEffect(() => {
        loadPushEvent()
    }, []);

    const [pushEvent, setPushEvent] = useState<PushEvent | null>(null);
    const [executePushAt, setExecutePushAt] = useState<Date>(new Date()); // Set a default value
    const [disableSaveButton, setDisableSaveButton] = useState<boolean>(true);

    const handleSave = () => {
        const updatedPushEvent: UpdatePushEventDto = {
            executePushAt: format(executePushAt, 'HH:mm:ss')
        }
        updatePushEvent(updatedPushEvent)
        setDisableSaveButton(true)
        Toast.show({
            html: `Updated Events Push Time to: ${executePushAt.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true })}`,
            timeout: TOAST_TIMEOUT,
        });
    }

    const handleCancel = () => {
        // @ts-ignore
        setExecutePushAt(new Date(`01/01/1970 ${pushEvent.executePushAt}`))
        setDisableSaveButton(true)
    }

    const updatePushEvent = async (updatedPushEvent: UpdatePushEventDto) => {
        try {
            console.log("Updating pushEvent:", updatedPushEvent);
            const response = await instance.put(`${apiBaseUrl}/api1/pushtime`, updatedPushEvent, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                console.log("PushEvent updated successfully");
            } else {
                console.error('Failed to update pushEvent:', response.statusText);
            }
        } catch (error) {
            console.error('Error updating pushEvent:', error);
        }
    };


    //Push Event end

    const colors = useColors()

    return (
        <Card elevation={4}>
            <CardHeader title="Push Events"/>
            <CardContent style={{
                borderTop: '1px solid grey',
                borderBottom: '1px solid grey',
                maxHeight: '50vh',
                overflow: 'auto'
            }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <FormGroup>
                        <FormControl>
                            {!pushEvent ? <CircularProgress style={{color: colors.lightBlue}}/> :
                                <TimePicker
                                    label="When to push events"
                                    value={executePushAt}
                                    onChange={(date) => {
                                        setExecutePushAt(date ? date : new Date())
                                        setDisableSaveButton(false)
                                    }}
                                />
                            }
                        </FormControl>
                    </FormGroup>
                </LocalizationProvider>
            </CardContent>
            <CardActions sx={{ml: "16px", mr: "16px", mt: "8px", mb: "8px"}}>
                {/* Additional buttons for the action bar */}
                {/*<IconButton aria-label="add">*/}
                <Button
                    variant={"contained"}
                    onClick={handleSave}
                    disabled={disableSaveButton}
                >
                    Save</Button>
                <Button
                    variant={"outlined"}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                {/* Add your icon or button component */}
                {/*</IconButton>*/}
                {/* Add more buttons as needed */}
            </CardActions>
        </Card>
    );
}
