import {Controller} from "react-hook-form";
import {FormControl, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import {getStateOptions} from "../../../api/jobNimbusFunctions";

export const csz: { [key: string]: CSZObject } = {
    "73007": {"city":"Arcadia", "state": "OK"},
    "73011": {"city":"Bradley", "state": "OK"},
    "73019": {"city":"Norman", "state": "OK"},
    "73029": {"city":"Cyril", "state": "OK"},
    "73043": {"city":"Greenfield", "state": "OK"},
    "73448": {"city":"Marietta", "state": "OK"},
    "73449": {"city":"Mead", "state": "OK"},
    "73455": {"city":"Ravia", "state": "OK"},
    "73487": {"city":"Tatums", "state": "OK"},
    "73554": {"city":"Mangum", "state": "OK"},
    "73560": {"city":"Olustee", "state": "OK"},
    "73632": {"city":"Cordell", "state": "OK"},
    "73641": {"city":"Dill City", "state": "OK"},
    "73718": {"city":"Ames", "state": "OK"},
    "73743": {"city":"Hillsdale", "state": "OK"},
    "73755": {"city":"Longdale", "state": "OK"},
    "74020": {"city":"Cleveland", "state": "OK"},
    "74027": {"city":"Delaware", "state": "OK"},
    "74078": {"city":"Stillwater", "state": "OK"},
    "74104": {"city":"Tulsa", "state": "OK"},
    "74346": {"city":"Jay", "state": "OK"},
    "74368": {"city":"Twin Oaks", "state": "OK"},
    "74438": {"city":"Hitchita", "state": "OK"},
    "74570": {"city":"Stuart", "state": "OK"},
    "74646": {"city":"Nardin", "state": "OK"},
    "74734": {"city":"Eagletown", "state": "OK"},
    "74759": {"city":"Soper", "state": "OK"},
    "74872": {"city":"Stratford", "state": "OK"},
    "73024": {"city":"Corn", "state": "OK"},
    "73052": {"city":"Lindsay", "state": "OK"},
    "73090": {"city":"Union City", "state": "OK"},
    "73120": {"city":"Oklahoma City", "state": "OK"},
    "73149": {"city":"Oklahoma City", "state": "OK"},
    "73434": {"city":"Foster", "state": "OK"},
    "73463": {"city":"Wilson", "state": "OK"},
    "73505": {"city":"Lawton", "state": "OK"},
    "73647": {"city":"Foss", "state": "OK"},
    "73658": {"city":"Oakwood", "state": "OK"},
    "73753": {"city":"Kremlin", "state": "OK"},
    "74134": {"city":"Tulsa", "state": "OK"},
    "74361": {"city":"Pryor", "state": "OK"},
    "74366": {"city":"Spavinaw", "state": "OK"},
    "74454": {"city":"Porter", "state": "OK"},
    "74471": {"city":"Welling", "state": "OK"},
    "74522": {"city":"Alderson", "state": "OK"},
    "74536": {"city":"Clayton", "state": "OK"},
    "74538": {"city":"Coalgate", "state": "OK"},
    "74540": {"city":"Daisy", "state": "OK"},
    "74637": {"city":"Fairfax", "state": "OK"},
    "74701": {"city":"Durant", "state": "OK"},
    "74747": {"city":"Kemp", "state": "OK"},
    "74761": {"city":"Swink", "state": "OK"},
    "74837": {"city":"Cromwell", "state": "OK"},
    "73002": {"city":"Alex", "state": "OK"},
    "73006": {"city":"Apache", "state": "OK"},
    "73030": {"city":"Davis", "state": "OK"},
    "73059": {"city":"Minco", "state": "OK"},
    "73097": {"city":"Wheatland", "state": "OK"},
    "73159": {"city":"Oklahoma City", "state": "OK"},
    "73507": {"city":"Lawton", "state": "OK"},
    "73553": {"city":"Loveland", "state": "OK"},
    "73620": {"city":"Arapaho", "state": "OK"},
    "73666": {"city":"Sweetwater", "state": "OK"},
    "73701": {"city":"Enid", "state": "OK"},
    "73801": {"city":"Woodward", "state": "OK"},
    "73851": {"city":"May", "state": "OK"},
    "73858": {"city":"Shattuck", "state": "OK"},
    "74044": {"city":"Mannford", "state": "OK"},
    "74053": {"city":"Oologah", "state": "OK"},
    "74105": {"city":"Tulsa", "state": "OK"},
    "74423": {"city":"Braggs", "state": "OK"},
    "74427": {"city":"Cookson", "state": "OK"},
    "74555": {"city":"Lane", "state": "OK"},
    "74631": {"city":"Blackwell", "state": "OK"},
    "74740": {"city":"Haworth", "state": "OK"},
    "74873": {"city":"Tecumseh", "state": "OK"},
    "74939": {"city":"Hodgen", "state": "OK"},
    "74944": {"city":"Mccurtain", "state": "OK"},
    "74951": {"city":"Panama", "state": "OK"},
    "73064": {"city":"Mustang", "state": "OK"},
    "73128": {"city":"Oklahoma City", "state": "OK"},
    "73145": {"city":"Oklahoma City", "state": "OK"},
    "73162": {"city":"Oklahoma City", "state": "OK"},
    "73438": {"city":"Healdton", "state": "OK"},
    "73537": {"city":"Eldorado", "state": "OK"},
    "73562": {"city":"Randlett", "state": "OK"},
    "73571": {"city":"Vinson", "state": "OK"},
    "73601": {"city":"Clinton", "state": "OK"},
    "73646": {"city":"Fay", "state": "OK"},
    "73661": {"city":"Rocky", "state": "OK"},
    "73720": {"city":"Bison", "state": "OK"},
    "73832": {"city":"Arnett", "state": "OK"},
    "73835": {"city":"Camargo", "state": "OK"},
    "73855": {"city":"Rosston", "state": "OK"},
    "73932": {"city":"Beaver", "state": "OK"},
    "74041": {"city":"Kiefer", "state": "OK"},
    "74051": {"city":"Ochelata", "state": "OK"},
    "74107": {"city":"Tulsa", "state": "OK"},
    "74441": {"city":"Hulbert", "state": "OK"},
    "74521": {"city":"Albion", "state": "OK"},
    "74644": {"city":"Marland", "state": "OK"},
    "74826": {"city":"Asher", "state": "OK"},
    "74865": {"city":"Roff", "state": "OK"},
    "73005": {"city":"Anadarko", "state": "OK"},
    "73034": {"city":"Edmond", "state": "OK"},
    "73107": {"city":"Oklahoma City", "state": "OK"},
    "73112": {"city":"Oklahoma City", "state": "OK"},
    "73121": {"city":"Oklahoma City", "state": "OK"},
    "73131": {"city":"Oklahoma City", "state": "OK"},
    "73173": {"city":"Oklahoma City", "state": "OK"},
    "73439": {"city":"Kingston", "state": "OK"},
    "73456": {"city":"Ringling", "state": "OK"},
    "73572": {"city":"Walters", "state": "OK"},
    "73644": {"city":"Elk City", "state": "OK"},
    "73763": {"city":"Okeene", "state": "OK"},
    "73852": {"city":"Mooreland", "state": "OK"},
    "73938": {"city":"Forgan", "state": "OK"},
    "74030": {"city":"Drumright", "state": "OK"},
    "74038": {"city":"Jennings", "state": "OK"},
    "74058": {"city":"Pawnee", "state": "OK"},
    "74330": {"city":"Adair", "state": "OK"},
    "74458": {"city":"Redbird", "state": "OK"},
    "74530": {"city":"Bromide", "state": "OK"},
    "74557": {"city":"Moyers", "state": "OK"},
    "74560": {"city":"Pittsburg", "state": "OK"},
    "74565": {"city":"Savanna", "state": "OK"},
    "74569": {"city":"Stringtown", "state": "OK"},
    "74724": {"city":"Bethel", "state": "OK"},
    "74729": {"city":"Caddo", "state": "OK"},
    "74736": {"city":"Garvin", "state": "OK"},
    "73103": {"city":"Oklahoma City", "state": "OK"},
    "73169": {"city":"Oklahoma City", "state": "OK"},
    "73432": {"city":"Coleman", "state": "OK"},
    "73453": {"city":"Overbrook", "state": "OK"},
    "73541": {"city":"Fletcher", "state": "OK"},
    "73627": {"city":"Carter", "state": "OK"},
    "73655": {"city":"Lone Wolf", "state": "OK"},
    "73834": {"city":"Buffalo", "state": "OK"},
    "73931": {"city":"Balko", "state": "OK"},
    "73933": {"city":"Boise City", "state": "OK"},
    "74010": {"city":"Bristow", "state": "OK"},
    "74016": {"city":"Chelsea", "state": "OK"},
    "74085": {"city":"Yale", "state": "OK"},
    "74534": {"city":"Centrahoma", "state": "OK"},
    "74840": {"city":"Earlsboro", "state": "OK"},
    "74854": {"city":"Maud", "state": "OK"},
    "74884": {"city":"Wewoka", "state": "OK"},
    "74931": {"city":"Bunch", "state": "OK"},
    "74941": {"city":"Keota", "state": "OK"},
    "74956": {"city":"Shady Point", "state": "OK"},
    "74960": {"city":"Stilwell", "state": "OK"},
    "74966": {"city":"Wister", "state": "OK"},
    "73010": {"city":"Blanchard", "state": "OK"},
    "73032": {"city":"Dougherty", "state": "OK"},
    "73056": {"city":"Marshall", "state": "OK"},
    "73063": {"city":"Mulhall", "state": "OK"},
    "73078": {"city":"Piedmont", "state": "OK"},
    "73531": {"city":"Devol", "state": "OK"},
    "73532": {"city":"Duke", "state": "OK"},
    "73550": {"city":"Hollis", "state": "OK"},
    "73567": {"city":"Sterling", "state": "OK"},
    "73659": {"city":"Putnam", "state": "OK"},
    "73727": {"city":"Carrier", "state": "OK"},
    "73735": {"city":"Drummond", "state": "OK"},
    "73762": {"city":"Okarche", "state": "OK"},
    "73842": {"city":"Freedom", "state": "OK"},
    "74331": {"city":"Afton", "state": "OK"},
    "74343": {"city":"Fairland", "state": "OK"},
    "74352": {"city":"Locust Grove", "state": "OK"},
    "74401": {"city":"Muskogee", "state": "OK"},
    "74430": {"city":"Crowder", "state": "OK"},
    "74731": {"city":"Cartwright", "state": "OK"},
    "74827": {"city":"Atwood", "state": "OK"},
    "74830": {"city":"Bowlegs", "state": "OK"},
    "74843": {"city":"Fitzhugh", "state": "OK"},
    "74857": {"city":"Newalla", "state": "OK"},
    "74869": {"city":"Sparks", "state": "OK"},
    "74902": {"city":"Pocola", "state": "OK"},
    "74935": {"city":"Fanshawe", "state": "OK"},
    "74942": {"city":"Leflore", "state": "OK"},
    "74948": {"city":"Muldrow", "state": "OK"},
    "73014": {"city":"Calumet", "state": "OK"},
    "73016": {"city":"Cashion", "state": "OK"},
    "73026": {"city":"Norman", "state": "OK"},
    "73065": {"city":"Newcastle", "state": "OK"},
    "73069": {"city":"Norman", "state": "OK"},
    "73110": {"city":"Oklahoma City", "state": "OK"},
    "73150": {"city":"Oklahoma City", "state": "OK"},
    "73160": {"city":"Oklahoma City", "state": "OK"},
    "73736": {"city":"Fairmont", "state": "OK"},
    "73737": {"city":"Fairview", "state": "OK"},
    "73758": {"city":"Manchester", "state": "OK"},
    "73771": {"city":"Wakita", "state": "OK"},
    "73945": {"city":"Hooker", "state": "OK"},
    "74001": {"city":"Avant", "state": "OK"},
    "74019": {"city":"Claremore", "state": "OK"},
    "74059": {"city":"Perkins", "state": "OK"},
    "74060": {"city":"Prue", "state": "OK"},
    "74108": {"city":"Tulsa", "state": "OK"},
    "74110": {"city":"Tulsa", "state": "OK"},
    "74340": {"city":"Disney", "state": "OK"},
    "74501": {"city":"Mcalester", "state": "OK"},
    "74641": {"city":"Kaw City", "state": "OK"},
    "74733": {"city":"Colbert", "state": "OK"},
    "74760": {"city":"Spencerville", "state": "OK"},
    "74855": {"city":"Meeker", "state": "OK"},
    "74901": {"city":"Arkoma", "state": "OK"},
    "74964": {"city":"Watts", "state": "OK"},
    "73047": {"city":"Hinton", "state": "OK"},
    "73115": {"city":"Oklahoma City", "state": "OK"},
    "73526": {"city":"Blair", "state": "OK"},
    "73538": {"city":"Elgin", "state": "OK"},
    "73539": {"city":"Elmer", "state": "OK"},
    "73543": {"city":"Geronimo", "state": "OK"},
    "73547": {"city":"Granite", "state": "OK"},
    "73548": {"city":"Hastings", "state": "OK"},
    "73729": {"city":"Cleo Springs", "state": "OK"},
    "74035": {"city":"Hominy", "state": "OK"},
    "74052": {"city":"Oilton", "state": "OK"},
    "74070": {"city":"Skiatook", "state": "OK"},
    "74073": {"city":"Sperry", "state": "OK"},
    "74332": {"city":"Big Cabin", "state": "OK"},
    "74367": {"city":"Strang", "state": "OK"},
    "74462": {"city":"Stigler", "state": "OK"},
    "74477": {"city":"Wagoner", "state": "OK"},
    "74546": {"city":"Haileyville", "state": "OK"},
    "74556": {"city":"Lehigh", "state": "OK"},
    "74572": {"city":"Tupelo", "state": "OK"},
    "74647": {"city":"Newkirk", "state": "OK"},
    "74743": {"city":"Hugo", "state": "OK"},
    "74750": {"city":"Millerton", "state": "OK"},
    "74831": {"city":"Byars", "state": "OK"},
    "74833": {"city":"Castle", "state": "OK"},
    "74878": {"city":"Wanette", "state": "OK"},
    "74949": {"city":"Muse", "state": "OK"},
    "74962": {"city":"Vian", "state": "OK"},
    "73018": {"city":"Chickasha", "state": "OK"},
    "73058": {"city":"Meridian", "state": "OK"},
    "73074": {"city":"Paoli", "state": "OK"},
    "73093": {"city":"Washington", "state": "OK"},
    "73102": {"city":"Oklahoma City", "state": "OK"},
    "73130": {"city":"Oklahoma City", "state": "OK"},
    "73450": {"city":"Milburn", "state": "OK"},
    "73556": {"city":"Martha", "state": "OK"},
    "73559": {"city":"Mountain Park", "state": "OK"},
    "73650": {"city":"Hammon", "state": "OK"},
    "73663": {"city":"Seiling", "state": "OK"},
    "73669": {"city":"Thomas", "state": "OK"},
    "73757": {"city":"Lucien", "state": "OK"},
    "74046": {"city":"Milfay", "state": "OK"},
    "74117": {"city":"Tulsa", "state": "OK"},
    "74129": {"city":"Tulsa", "state": "OK"},
    "74131": {"city":"Tulsa", "state": "OK"},
    "74133": {"city":"Tulsa", "state": "OK"},
    "74136": {"city":"Tulsa", "state": "OK"},
    "74146": {"city":"Tulsa", "state": "OK"},
    "74342": {"city":"Eucha", "state": "OK"},
    "74451": {"city":"Park Hill", "state": "OK"},
    "74469": {"city":"Warner", "state": "OK"},
    "74528": {"city":"Blanco", "state": "OK"},
    "74533": {"city":"Caney", "state": "OK"},
    "74577": {"city":"Whitesboro", "state": "OK"},
    "74636": {"city":"Deer Creek", "state": "OK"},
    "74864": {"city":"Prague", "state": "OK"},
    "74943": {"city":"Lequire", "state": "OK"},
    "74953": {"city":"Poteau", "state": "OK"},
    "73008": {"city":"Bethany", "state": "OK"},
    "73114": {"city":"Oklahoma City", "state": "OK"},
    "73446": {"city":"Madill", "state": "OK"},
    "73544": {"city":"Gould", "state": "OK"},
    "73552": {"city":"Indiahoma", "state": "OK"},
    "73946": {"city":"Kenton", "state": "OK"},
    "74071": {"city":"Slick", "state": "OK"},
    "74074": {"city":"Stillwater", "state": "OK"},
    "74081": {"city":"Terlton", "state": "OK"},
    "74563": {"city":"Red Oak", "state": "OK"},
    "74735": {"city":"Fort Towson", "state": "OK"},
    "74766": {"city":"Wright City", "state": "OK"},
    "73012": {"city":"Edmond", "state": "OK"},
    "73042": {"city":"Gracemont", "state": "OK"},
    "73099": {"city":"Yukon", "state": "OK"},
    "73135": {"city":"Oklahoma City", "state": "OK"},
    "73430": {"city":"Burneyville", "state": "OK"},
    "73565": {"city":"Ryan", "state": "OK"},
    "73566": {"city":"Snyder", "state": "OK"},
    "73703": {"city":"Enid", "state": "OK"},
    "73705": {"city":"Enid", "state": "OK"},
    "73766": {"city":"Pond Creek", "state": "OK"},
    "73944": {"city":"Hardesty", "state": "OK"},
    "74036": {"city":"Inola", "state": "OK"},
    "74561": {"city":"Quinton", "state": "OK"},
    "73027": {"city":"Coyle", "state": "OK"},
    "73067": {"city":"Ninnekah", "state": "OK"},
    "73116": {"city":"Oklahoma City", "state": "OK"},
    "73401": {"city":"Ardmore", "state": "OK"},
    "73437": {"city":"Graham", "state": "OK"},
    "73443": {"city":"Lone Grove", "state": "OK"},
    "73569": {"city":"Terral", "state": "OK"},
    "73642": {"city":"Durham", "state": "OK"},
    "73645": {"city":"Erick", "state": "OK"},
    "73667": {"city":"Taloga", "state": "OK"},
    "73719": {"city":"Amorita", "state": "OK"},
    "73746": {"city":"Hopeton", "state": "OK"},
    "73747": {"city":"Isabella", "state": "OK"},
    "74008": {"city":"Bixby", "state": "OK"},
    "74026": {"city":"Davenport", "state": "OK"},
    "74039": {"city":"Kellyville", "state": "OK"},
    "74079": {"city":"Stroud", "state": "OK"},
    "74333": {"city":"Bluejacket", "state": "OK"},
    "74344": {"city":"Grove", "state": "OK"},
    "74460": {"city":"Schulter", "state": "OK"},
    "74574": {"city":"Tuskahoma", "state": "OK"},
    "74604": {"city":"Ponca City", "state": "OK"},
    "74825": {"city":"Allen", "state": "OK"},
    "74859": {"city":"Okemah", "state": "OK"},
    "74930": {"city":"Bokoshe", "state": "OK"},
    "73117": {"city":"Oklahoma City", "state": "OK"},
    "73179": {"city":"Oklahoma City", "state": "OK"},
    "73481": {"city":"Ratliff City", "state": "OK"},
    "73546": {"city":"Grandfield", "state": "OK"},
    "73651": {"city":"Hobart", "state": "OK"},
    "73734": {"city":"Dover", "state": "OK"},
    "73756": {"city":"Loyal", "state": "OK"},
    "73857": {"city":"Sharon", "state": "OK"},
    "74050": {"city":"Oakhurst", "state": "OK"},
    "74080": {"city":"Talala", "state": "OK"},
    "74084": {"city":"Wynona", "state": "OK"},
    "74339": {"city":"Commerce", "state": "OK"},
    "74456": {"city":"Preston", "state": "OK"},
    "74727": {"city":"Boswell", "state": "OK"},
    "74801": {"city":"Shawnee", "state": "OK"},
    "73053": {"city":"Lookeba", "state": "OK"},
    "73077": {"city":"Perry", "state": "OK"},
    "73106": {"city":"Oklahoma City", "state": "OK"},
    "73622": {"city":"Bessie", "state": "OK"},
    "73624": {"city":"Burns Flat", "state": "OK"},
    "73626": {"city":"Canute", "state": "OK"},
    "73664": {"city":"Sentinel", "state": "OK"},
    "73768": {"city":"Ringwood", "state": "OK"},
    "73843": {"city":"Gage", "state": "OK"},
    "73859": {"city":"Vici", "state": "OK"},
    "73950": {"city":"Turpin", "state": "OK"},
    "74002": {"city":"Barnsdall", "state": "OK"},
    "74023": {"city":"Cushing", "state": "OK"},
    "74063": {"city":"Sand Springs", "state": "OK"},
    "74128": {"city":"Tulsa", "state": "OK"},
    "74135": {"city":"Tulsa", "state": "OK"},
    "74337": {"city":"Chouteau", "state": "OK"},
    "74358": {"city":"North Miami", "state": "OK"},
    "74523": {"city":"Antlers", "state": "OK"},
    "74651": {"city":"Red Rock", "state": "OK"},
    "74754": {"city":"Ringold", "state": "OK"},
    "73015": {"city":"Carnegie", "state": "OK"},
    "73033": {"city":"Eakly", "state": "OK"},
    "73041": {"city":"Gotebo", "state": "OK"},
    "73073": {"city":"Orlando", "state": "OK"},
    "73111": {"city":"Oklahoma City", "state": "OK"},
    "73165": {"city":"Oklahoma City", "state": "OK"},
    "73442": {"city":"Loco", "state": "OK"},
    "73444": {"city":"Hennepin", "state": "OK"},
    "73459": {"city":"Thackerville", "state": "OK"},
    "73555": {"city":"Manitou", "state": "OK"},
    "73568": {"city":"Temple", "state": "OK"},
    "73662": {"city":"Sayre", "state": "OK"},
    "73738": {"city":"Garber", "state": "OK"},
    "73739": {"city":"Goltry", "state": "OK"},
    "74003": {"city":"Bartlesville", "state": "OK"},
    "74132": {"city":"Tulsa", "state": "OK"},
    "74365": {"city":"Salina", "state": "OK"},
    "74434": {"city":"Fort Gibson", "state": "OK"},
    "74470": {"city":"Webbers Falls", "state": "OK"},
    "74633": {"city":"Burbank", "state": "OK"},
    "74730": {"city":"Calera", "state": "OK"},
    "74829": {"city":"Boley", "state": "OK"},
    "74834": {"city":"Chandler", "state": "OK"},
    "74875": {"city":"Tryon", "state": "OK"},
    "74883": {"city":"Wetumka", "state": "OK"},
    "73020": {"city":"Choctaw", "state": "OK"},
    "73089": {"city":"Tuttle", "state": "OK"},
    "73098": {"city":"Wynnewood", "state": "OK"},
    "73141": {"city":"Oklahoma City", "state": "OK"},
    "73491": {"city":"Velma", "state": "OK"},
    "73542": {"city":"Frederick", "state": "OK"},
    "73557": {"city":"Medicine Park", "state": "OK"},
    "73638": {"city":"Crawford", "state": "OK"},
    "73742": {"city":"Hennessey", "state": "OK"},
    "74028": {"city":"Depew", "state": "OK"},
    "74083": {"city":"Wann", "state": "OK"},
    "74145": {"city":"Tulsa", "state": "OK"},
    "74354": {"city":"Miami", "state": "OK"},
    "74748": {"city":"Kenefic", "state": "OK"},
    "74849": {"city":"Konawa", "state": "OK"},
    "73017": {"city":"Cement", "state": "OK"},
    "73049": {"city":"Jones", "state": "OK"},
    "73139": {"city":"Oklahoma City", "state": "OK"},
    "73142": {"city":"Oklahoma City", "state": "OK"},
    "73151": {"city":"Oklahoma City", "state": "OK"},
    "73460": {"city":"Tishomingo", "state": "OK"},
    "73520": {"city":"Addington", "state": "OK"},
    "73529": {"city":"Comanche", "state": "OK"},
    "73573": {"city":"Waurika", "state": "OK"},
    "73625": {"city":"Butler", "state": "OK"},
    "73728": {"city":"Cherokee", "state": "OK"},
    "73754": {"city":"Lahoma", "state": "OK"},
    "73773": {"city":"Waukomis", "state": "OK"},
    "73838": {"city":"Chester", "state": "OK"},
    "73853": {"city":"Mutual", "state": "OK"},
    "73942": {"city":"Guymon", "state": "OK"},
    "74006": {"city":"Bartlesville", "state": "OK"},
    "74042": {"city":"Lenapah", "state": "OK"},
    "74370": {"city":"Wyandotte", "state": "OK"},
    "74403": {"city":"Muskogee", "state": "OK"},
    "74447": {"city":"Okmulgee", "state": "OK"},
    "74450": {"city":"Oktaha", "state": "OK"},
    "74459": {"city":"Rentiesville", "state": "OK"},
    "74552": {"city":"Kinta", "state": "OK"},
    "74650": {"city":"Ralston", "state": "OK"},
    "74720": {"city":"Achille", "state": "OK"},
    "74722": {"city":"Battiest", "state": "OK"},
    "74745": {"city":"Idabel", "state": "OK"},
    "74836": {"city":"Connerville", "state": "OK"},
    "74842": {"city":"Fittstown", "state": "OK"},
    "74867": {"city":"Sasakwa", "state": "OK"},
    "73013": {"city":"Edmond", "state": "OK"},
    "73021": {"city":"Colony", "state": "OK"},
    "73054": {"city":"Luther", "state": "OK"},
    "73055": {"city":"Marlow", "state": "OK"},
    "73726": {"city":"Carmen", "state": "OK"},
    "73761": {"city":"Nash", "state": "OK"},
    "74014": {"city":"Broken Arrow", "state": "OK"},
    "74029": {"city":"Dewey", "state": "OK"},
    "74056": {"city":"Pawhuska", "state": "OK"},
    "74066": {"city":"Sapulpa", "state": "OK"},
    "74115": {"city":"Tulsa", "state": "OK"},
    "74130": {"city":"Tulsa", "state": "OK"},
    "74349": {"city":"Ketchum", "state": "OK"},
    "74422": {"city":"Boynton", "state": "OK"},
    "74562": {"city":"Rattan", "state": "OK"},
    "74578": {"city":"Wilburton", "state": "OK"},
    "74756": {"city":"Sawyer", "state": "OK"},
    "74804": {"city":"Shawnee", "state": "OK"},
    "74946": {"city":"Moffett", "state": "OK"},
    "74954": {"city":"Roland", "state": "OK"},
    "74955": {"city":"Sallisaw", "state": "OK"},
    "73003": {"city":"Edmond", "state": "OK"},
    "73061": {"city":"Morrison", "state": "OK"},
    "73080": {"city":"Purcell", "state": "OK"},
    "73092": {"city":"Verden", "state": "OK"},
    "73105": {"city":"Oklahoma City", "state": "OK"},
    "73109": {"city":"Oklahoma City", "state": "OK"},
    "73530": {"city":"Davidson", "state": "OK"},
    "73717": {"city":"Alva", "state": "OK"},
    "73744": {"city":"Hitchcock", "state": "OK"},
    "73841": {"city":"Fort Supply", "state": "OK"},
    "74032": {"city":"Glencoe", "state": "OK"},
    "74033": {"city":"Glenpool", "state": "OK"},
    "74048": {"city":"Nowata", "state": "OK"},
    "74062": {"city":"Ripley", "state": "OK"},
    "74116": {"city":"Tulsa", "state": "OK"},
    "74421": {"city":"Beggs", "state": "OK"},
    "74455": {"city":"Porum", "state": "OK"},
    "74525": {"city":"Atoka", "state": "OK"},
    "74576": {"city":"Wardville", "state": "OK"},
    "74839": {"city":"Dustin", "state": "OK"},
    "74868": {"city":"Seminole", "state": "OK"},
    "74871": {"city":"Stonewall", "state": "OK"},
    "74881": {"city":"Wellston", "state": "OK"},
    "73036": {"city":"El Reno", "state": "OK"},
    "73040": {"city":"Geary", "state": "OK"},
    "73048": {"city":"Hydro", "state": "OK"},
    "73057": {"city":"Maysville", "state": "OK"},
    "73071": {"city":"Norman", "state": "OK"},
    "73433": {"city":"Elmore City", "state": "OK"},
    "73441": {"city":"Leon", "state": "OK"},
    "73840": {"city":"Fargo", "state": "OK"},
    "73937": {"city":"Felt", "state": "OK"},
    "74360": {"city":"Picher", "state": "OK"},
    "74435": {"city":"Gore", "state": "OK"},
    "74442": {"city":"Indianola", "state": "OK"},
    "74472": {"city":"Whitefield", "state": "OK"},
    "74558": {"city":"Nashoba", "state": "OK"},
    "74764": {"city":"Valliant", "state": "OK"},
    "74844": {"city":"Francis", "state": "OK"},
    "74945": {"city":"Marble City", "state": "OK"},
    "74957": {"city":"Smithville", "state": "OK"},
    "73051": {"city":"Lexington", "state": "OK"},
    "73066": {"city":"Nicoma Park", "state": "OK"},
    "73458": {"city":"Springer", "state": "OK"},
    "73501": {"city":"Lawton", "state": "OK"},
    "73527": {"city":"Cache", "state": "OK"},
    "73639": {"city":"Custer City", "state": "OK"},
    "73716": {"city":"Aline", "state": "OK"},
    "73730": {"city":"Covington", "state": "OK"},
    "73731": {"city":"Dacoma", "state": "OK"},
    "73764": {"city":"Omega", "state": "OK"},
    "73844": {"city":"Gate", "state": "OK"},
    "73949": {"city":"Texhoma", "state": "OK"},
    "74061": {"city":"Ramona", "state": "OK"},
    "74127": {"city":"Tulsa", "state": "OK"},
    "74338": {"city":"Colcord", "state": "OK"},
    "74369": {"city":"Welch", "state": "OK"},
    "74431": {"city":"Dewar", "state": "OK"},
    "74464": {"city":"Tahlequah", "state": "OK"},
    "74468": {"city":"Wainwright", "state": "OK"},
    "74531": {"city":"Calvin", "state": "OK"},
    "74543": {"city":"Finley", "state": "OK"},
    "74571": {"city":"Talihina", "state": "OK"},
    "74652": {"city":"Shidler", "state": "OK"},
    "74653": {"city":"Tonkawa", "state": "OK"},
    "74728": {"city":"Broken Bow", "state": "OK"},
    "74852": {"city":"Macomb", "state": "OK"},
    "74936": {"city":"Gans", "state": "OK"},
    "73075": {"city":"Pauls Valley", "state": "OK"},
    "73082": {"city":"Rush Springs", "state": "OK"},
    "73086": {"city":"Sulphur", "state": "OK"},
    "73104": {"city":"Oklahoma City", "state": "OK"},
    "73129": {"city":"Oklahoma City", "state": "OK"},
    "73461": {"city":"Wapanucka", "state": "OK"},
    "73521": {"city":"Altus", "state": "OK"},
    "73533": {"city":"Duncan", "state": "OK"},
    "73628": {"city":"Cheyenne", "state": "OK"},
    "73760": {"city":"Meno", "state": "OK"},
    "74045": {"city":"Maramec", "state": "OK"},
    "74137": {"city":"Tulsa", "state": "OK"},
    "74301": {"city":"Vinita", "state": "OK"},
    "74446": {"city":"Okay", "state": "OK"},
    "74630": {"city":"Billings", "state": "OK"},
    "74755": {"city":"Rufe", "state": "OK"},
    "73050": {"city":"Langston", "state": "OK"},
    "73062": {"city":"Mountain View", "state": "OK"},
    "73079": {"city":"Pocasset", "state": "OK"},
    "73095": {"city":"Wayne", "state": "OK"},
    "73119": {"city":"Oklahoma City", "state": "OK"},
    "73134": {"city":"Oklahoma City", "state": "OK"},
    "73528": {"city":"Chattanooga", "state": "OK"},
    "73724": {"city":"Canton", "state": "OK"},
    "73741": {"city":"Helena", "state": "OK"},
    "73759": {"city":"Medford", "state": "OK"},
    "73772": {"city":"Watonga", "state": "OK"},
    "73901": {"city":"Adams", "state": "OK"},
    "73947": {"city":"Keyes", "state": "OK"},
    "74103": {"city":"Tulsa", "state": "OK"},
    "74106": {"city":"Tulsa", "state": "OK"},
    "74112": {"city":"Tulsa", "state": "OK"},
    "74350": {"city":"Langley", "state": "OK"},
    "74363": {"city":"Quapaw", "state": "OK"},
    "74426": {"city":"Checotah", "state": "OK"},
    "74429": {"city":"Coweta", "state": "OK"},
    "74437": {"city":"Henryetta", "state": "OK"},
    "74463": {"city":"Taft", "state": "OK"},
    "74567": {"city":"Snow", "state": "OK"},
    "74820": {"city":"Ada", "state": "OK"},
    "74937": {"city":"Heavener", "state": "OK"},
    "74940": {"city":"Howe", "state": "OK"},
    "73028": {"city":"Crescent", "state": "OK"},
    "73038": {"city":"Fort Cobb", "state": "OK"},
    "73068": {"city":"Noble", "state": "OK"},
    "73118": {"city":"Oklahoma City", "state": "OK"},
    "73132": {"city":"Oklahoma City", "state": "OK"},
    "73170": {"city":"Oklahoma City", "state": "OK"},
    "73440": {"city":"Lebanon", "state": "OK"},
    "73564": {"city":"Roosevelt", "state": "OK"},
    "73860": {"city":"Waynoka", "state": "OK"},
    "74011": {"city":"Broken Arrow", "state": "OK"},
    "74068": {"city":"Shamrock", "state": "OK"},
    "74347": {"city":"Kansas", "state": "OK"},
    "74364": {"city":"Rose", "state": "OK"},
    "74432": {"city":"Eufaula", "state": "OK"},
    "74445": {"city":"Morris", "state": "OK"},
    "74467": {"city":"Wagoner", "state": "OK"},
    "74723": {"city":"Bennington", "state": "OK"},
    "74845": {"city":"Hanna", "state": "OK"},
    "74850": {"city":"Lamar", "state": "OK"},
    "73004": {"city":"Amber", "state": "OK"},
    "73044": {"city":"Guthrie", "state": "OK"},
    "73045": {"city":"Harrah", "state": "OK"},
    "73072": {"city":"Norman", "state": "OK"},
    "73084": {"city":"Spencer", "state": "OK"},
    "73122": {"city":"Oklahoma City", "state": "OK"},
    "73127": {"city":"Oklahoma City", "state": "OK"},
    "73447": {"city":"Mannsville", "state": "OK"},
    "73503": {"city":"Fort Sill", "state": "OK"},
    "73551": {"city":"Hollister", "state": "OK"},
    "73722": {"city":"Burlington", "state": "OK"},
    "73749": {"city":"Jet", "state": "OK"},
    "73848": {"city":"Laverne", "state": "OK"},
    "74015": {"city":"Catoosa", "state": "OK"},
    "74017": {"city":"Claremore", "state": "OK"},
    "74072": {"city":"S Coffeyville", "state": "OK"},
    "74549": {"city":"Honobia", "state": "OK"},
    "74553": {"city":"Kiowa", "state": "OK"},
    "74726": {"city":"Bokchito", "state": "OK"},
    "74753": {"city":"Platter", "state": "OK"},
    "74824": {"city":"Agra", "state": "OK"},
    "74832": {"city":"Carney", "state": "OK"},
    "74856": {"city":"Mill Creek", "state": "OK"},
    "74860": {"city":"Paden", "state": "OK"},
    "74959": {"city":"Spiro", "state": "OK"},
    "74965": {"city":"Westville", "state": "OK"},
    "73025": {"city":"Edmond", "state": "OK"},
    "73108": {"city":"Oklahoma City", "state": "OK"},
    "73425": {"city":"Countyline", "state": "OK"},
    "73540": {"city":"Faxon", "state": "OK"},
    "73549": {"city":"Headrick", "state": "OK"},
    "73570": {"city":"Tipton", "state": "OK"},
    "73654": {"city":"Leedey", "state": "OK"},
    "73660": {"city":"Reydon", "state": "OK"},
    "74021": {"city":"Collinsville", "state": "OK"},
    "74034": {"city":"Hallett", "state": "OK"},
    "74047": {"city":"Mounds", "state": "OK"},
    "74055": {"city":"Owasso", "state": "OK"},
    "74075": {"city":"Stillwater", "state": "OK"},
    "74114": {"city":"Tulsa", "state": "OK"},
    "74119": {"city":"Tulsa", "state": "OK"},
    "74457": {"city":"Proctor", "state": "OK"},
    "74535": {"city":"Clarita", "state": "OK"},
    "74547": {"city":"Hartshorne", "state": "OK"},
    "74554": {"city":"Krebs", "state": "OK"},
    "74601": {"city":"Ponca City", "state": "OK"},
    "74738": {"city":"Grant", "state": "OK"},
    "74741": {"city":"Hendrix", "state": "OK"},
    "74848": {"city":"Holdenville", "state": "OK"},
    "74851": {"city":"Mcloud", "state": "OK"},
    "74880": {"city":"Weleetka", "state": "OK"},
    "73009": {"city":"Binger", "state": "OK"},
    "73096": {"city":"Weatherford", "state": "OK"},
    "73673": {"city":"Willow", "state": "OK"},
    "73733": {"city":"Douglas", "state": "OK"},
    "73750": {"city":"Kingfisher", "state": "OK"},
    "73939": {"city":"Goodwell", "state": "OK"},
    "73951": {"city":"Tyrone", "state": "OK"},
    "74012": {"city":"Broken Arrow", "state": "OK"},
    "74022": {"city":"Copan", "state": "OK"},
    "74037": {"city":"Jenks", "state": "OK"},
    "74054": {"city":"Osage", "state": "OK"},
    "74082": {"city":"Vera", "state": "OK"},
    "74120": {"city":"Tulsa", "state": "OK"},
    "74126": {"city":"Tulsa", "state": "OK"},
    "74359": {"city":"Oaks", "state": "OK"},
    "74425": {"city":"Canadian", "state": "OK"},
    "74428": {"city":"Council Hill", "state": "OK"},
    "74436": {"city":"Haskell", "state": "OK"},
    "74452": {"city":"Peggs", "state": "OK"},
    "74632": {"city":"Braman", "state": "OK"},
    "74640": {"city":"Hunter", "state": "OK"},
    "74643": {"city":"Lamont", "state": "OK"},
    "74932": {"city":"Cameron", "state": "OK"},
    "74963": {"city":"Watson", "state": "OK"},
    "63047": {"city":"Hematite", "state": "MO"},
    "63050": {"city":"Hillsboro", "state": "MO"},
    "63136": {"city":"Saint Louis", "state": "MO"},
    "63349": {"city":"Hawk Point", "state": "MO"},
    "63548": {"city":"Lancaster", "state": "MO"},
    "63630": {"city":"Cadet", "state": "MO"},
    "63780": {"city":"Scott City", "state": "MO"},
    "63782": {"city":"Sturdivant", "state": "MO"},
    "63823": {"city":"Bertrand", "state": "MO"},
    "63837": {"city":"Clarkton", "state": "MO"},
    "63841": {"city":"Dexter", "state": "MO"},
    "63876": {"city":"Senath", "state": "MO"},
    "63944": {"city":"Greenville", "state": "MO"},
    "64096": {"city":"Waverly", "state": "MO"},
    "64470": {"city":"Mound City", "state": "MO"},
    "64484": {"city":"Rushville", "state": "MO"},
    "64489": {"city":"Stanberry", "state": "MO"},
    "64501": {"city":"Saint Joseph", "state": "MO"},
    "64503": {"city":"Saint Joseph", "state": "MO"},
    "64624": {"city":"Braymer", "state": "MO"},
    "64640": {"city":"Gallatin", "state": "MO"},
    "64642": {"city":"Gilman City", "state": "MO"},
    "64648": {"city":"Jamesport", "state": "MO"},
    "64671": {"city":"Polo", "state": "MO"},
    "64676": {"city":"Rothville", "state": "MO"},
    "64750": {"city":"Harwood", "state": "MO"},
    "64762": {"city":"Liberal", "state": "MO"},
    "64804": {"city":"Joplin", "state": "MO"},
    "65026": {"city":"Eldon", "state": "MO"},
    "65068": {"city":"Prairie Home", "state": "MO"},
    "65254": {"city":"Glasgow", "state": "MO"},
    "65258": {"city":"Holliday", "state": "MO"},
    "65444": {"city":"Bucyrus", "state": "MO"},
    "65484": {"city":"Huggins", "state": "MO"},
    "65631": {"city":"Clever", "state": "MO"},
    "65667": {"city":"Hartville", "state": "MO"},
    "65791": {"city":"Thayer", "state": "MO"},
    "63084": {"city":"Union", "state": "MO"},
    "63106": {"city":"Saint Louis", "state": "MO"},
    "63121": {"city":"Saint Louis", "state": "MO"},
    "63376": {"city":"Saint Peters", "state": "MO"},
    "63433": {"city":"Ashburn", "state": "MO"},
    "63540": {"city":"Gibbs", "state": "MO"},
    "63543": {"city":"Gorin", "state": "MO"},
    "63566": {"city":"Winigan", "state": "MO"},
    "63801": {"city":"Sikeston", "state": "MO"},
    "63951": {"city":"Lowndes", "state": "MO"},
    "64063": {"city":"Lees Summit", "state": "MO"},
    "64072": {"city":"Missouri City", "state": "MO"},
    "64081": {"city":"Lees Summit", "state": "MO"},
    "64126": {"city":"Kansas City", "state": "MO"},
    "64127": {"city":"Kansas City", "state": "MO"},
    "64441": {"city":"Denver", "state": "MO"},
    "64448": {"city":"Faucett", "state": "MO"},
    "64752": {"city":"Hume", "state": "MO"},
    "64840": {"city":"Diamond", "state": "MO"},
    "65017": {"city":"Brumley", "state": "MO"},
    "65260": {"city":"Jacksonville", "state": "MO"},
    "65287": {"city":"Wooldridge", "state": "MO"},
    "65336": {"city":"Knob Noster", "state": "MO"},
    "65441": {"city":"Bourbon", "state": "MO"},
    "65529": {"city":"Jerome", "state": "MO"},
    "65560": {"city":"Salem", "state": "MO"},
    "65634": {"city":"Cross Timbers", "state": "MO"},
    "65723": {"city":"Pierce City", "state": "MO"},
    "65787": {"city":"Roach", "state": "MO"},
    "63303": {"city":"Saint Charles", "state": "MO"},
    "63343": {"city":"Elsberry", "state": "MO"},
    "63453": {"city":"Luray", "state": "MO"},
    "63531": {"city":"Baring", "state": "MO"},
    "63532": {"city":"Bevier", "state": "MO"},
    "63541": {"city":"Glenwood", "state": "MO"},
    "63660": {"city":"Mineral Point", "state": "MO"},
    "63826": {"city":"Braggadocio", "state": "MO"},
    "63834": {"city":"Charleston", "state": "MO"},
    "63846": {"city":"Essex", "state": "MO"},
    "63882": {"city":"Wyatt", "state": "MO"},
    "63936": {"city":"Dudley", "state": "MO"},
    "64098": {"city":"Weston", "state": "MO"},
    "64112": {"city":"Kansas City", "state": "MO"},
    "64137": {"city":"Kansas City", "state": "MO"},
    "64424": {"city":"Bethany", "state": "MO"},
    "64433": {"city":"Conception", "state": "MO"},
    "64458": {"city":"Hatfield", "state": "MO"},
    "64461": {"city":"Hopkins", "state": "MO"},
    "64491": {"city":"Tarkio", "state": "MO"},
    "64497": {"city":"Weatherby", "state": "MO"},
    "64625": {"city":"Breckenridge", "state": "MO"},
    "64630": {"city":"Browning", "state": "MO"},
    "64724": {"city":"Appleton City", "state": "MO"},
    "64733": {"city":"Chilhowee", "state": "MO"},
    "65010": {"city":"Ashland", "state": "MO"},
    "65050": {"city":"Latham", "state": "MO"},
    "65058": {"city":"Meta", "state": "MO"},
    "65077": {"city":"Steedman", "state": "MO"},
    "65244": {"city":"Clifton Hill", "state": "MO"},
    "65284": {"city":"Sturgeon", "state": "MO"},
    "65438": {"city":"Birch Tree", "state": "MO"},
    "65468": {"city":"Eunice", "state": "MO"},
    "65613": {"city":"Bolivar", "state": "MO"},
    "65646": {"city":"Everton", "state": "MO"},
    "65680": {"city":"Kissee Mills", "state": "MO"},
    "65738": {"city":"Republic", "state": "MO"},
    "65807": {"city":"Springfield", "state": "MO"},
    "63037": {"city":"Gerald", "state": "MO"},
    "63119": {"city":"Saint Louis", "state": "MO"},
    "63137": {"city":"Saint Louis", "state": "MO"},
    "63369": {"city":"Old Monroe", "state": "MO"},
    "63382": {"city":"Vandalia", "state": "MO"},
    "63385": {"city":"Wentzville", "state": "MO"},
    "63653": {"city":"Leadwood", "state": "MO"},
    "63744": {"city":"Delta", "state": "MO"},
    "63746": {"city":"Farrar", "state": "MO"},
    "63771": {"city":"Oran", "state": "MO"},
    "63863": {"city":"Malden", "state": "MO"},
    "63965": {"city":"Van Buren", "state": "MO"},
    "64101": {"city":"Kansas City", "state": "MO"},
    "64427": {"city":"Bolckow", "state": "MO"},
    "64479": {"city":"Ravenwood", "state": "MO"},
    "64658": {"city":"Marceline", "state": "MO"},
    "64740": {"city":"Deepwater", "state": "MO"},
    "64841": {"city":"Duenweg", "state": "MO"},
    "65020": {"city":"Camdenton", "state": "MO"},
    "65034": {"city":"Fortuna", "state": "MO"},
    "65035": {"city":"Freeburg", "state": "MO"},
    "65053": {"city":"Lohman", "state": "MO"},
    "65275": {"city":"Paris", "state": "MO"},
    "65351": {"city":"Sweet Springs", "state": "MO"},
    "65440": {"city":"Boss", "state": "MO"},
    "65627": {"city":"Cedarcreek", "state": "MO"},
    "65638": {"city":"Drury", "state": "MO"},
    "65649": {"city":"Fair Play", "state": "MO"},
    "65689": {"city":"Cabool", "state": "MO"},
    "65752": {"city":"South Greenfield", "state": "MO"},
    "65766": {"city":"Udall", "state": "MO"},
    "65786": {"city":"Macks Creek", "state": "MO"},
    "63016": {"city":"Cedar Hill", "state": "MO"},
    "63034": {"city":"Florissant", "state": "MO"},
    "63051": {"city":"House Springs", "state": "MO"},
    "63056": {"city":"Leslie", "state": "MO"},
    "63057": {"city":"Liguori", "state": "MO"},
    "63454": {"city":"Maywood", "state": "MO"},
    "63628": {"city":"Bonne Terre", "state": "MO"},
    "63703": {"city":"Cape Girardeau", "state": "MO"},
    "64012": {"city":"Belton", "state": "MO"},
    "64048": {"city":"Holt", "state": "MO"},
    "64060": {"city":"Kearney", "state": "MO"},
    "64111": {"city":"Kansas City", "state": "MO"},
    "64118": {"city":"Kansas City", "state": "MO"},
    "64163": {"city":"Kansas City", "state": "MO"},
    "64456": {"city":"Grant City", "state": "MO"},
    "64726": {"city":"Blairstown", "state": "MO"},
    "64738": {"city":"Collins", "state": "MO"},
    "64746": {"city":"Freeman", "state": "MO"},
    "64831": {"city":"Anderson", "state": "MO"},
    "64835": {"city":"Carterville", "state": "MO"},
    "65025": {"city":"Clarksburg", "state": "MO"},
    "65109": {"city":"Jefferson City", "state": "MO"},
    "65321": {"city":"Blackburn", "state": "MO"},
    "65334": {"city":"Hughesville", "state": "MO"},
    "65664": {"city":"Halltown", "state": "MO"},
    "65668": {"city":"Hermitage", "state": "MO"},
    "65710": {"city":"Morrisville", "state": "MO"},
    "65713": {"city":"Niangua", "state": "MO"},
    "65744": {"city":"Rueter", "state": "MO"},
    "65771": {"city":"Walnut Shade", "state": "MO"},
    "63015": {"city":"Catawissa", "state": "MO"},
    "63069": {"city":"Pacific", "state": "MO"},
    "63101": {"city":"Saint Louis", "state": "MO"},
    "63432": {"city":"Arbela", "state": "MO"},
    "63530": {"city":"Atlanta", "state": "MO"},
    "63624": {"city":"Bismarck", "state": "MO"},
    "63648": {"city":"Irondale", "state": "MO"},
    "63665": {"city":"Redford", "state": "MO"},
    "63673": {"city":"Saint Mary", "state": "MO"},
    "63743": {"city":"Daisy", "state": "MO"},
    "63755": {"city":"Jackson", "state": "MO"},
    "63781": {"city":"Sedgewickville", "state": "MO"},
    "63940": {"city":"Fisk", "state": "MO"},
    "64040": {"city":"Holden", "state": "MO"},
    "64092": {"city":"Waldron", "state": "MO"},
    "64114": {"city":"Kansas City", "state": "MO"},
    "64153": {"city":"Kansas City", "state": "MO"},
    "64166": {"city":"Kansas City", "state": "MO"},
    "64637": {"city":"Cowgill", "state": "MO"},
    "64639": {"city":"De Witt", "state": "MO"},
    "64683": {"city":"Trenton", "state": "MO"},
    "64745": {"city":"Foster", "state": "MO"},
    "64756": {"city":"Jerico Springs", "state": "MO"},
    "64854": {"city":"Noel", "state": "MO"},
    "65014": {"city":"Bland", "state": "MO"},
    "65062": {"city":"Mount Sterling", "state": "MO"},
    "65076": {"city":"Saint Thomas", "state": "MO"},
    "65202": {"city":"Columbia", "state": "MO"},
    "65265": {"city":"Mexico", "state": "MO"},
    "65280": {"city":"Rush Hill", "state": "MO"},
    "65340": {"city":"Marshall", "state": "MO"},
    "65456": {"city":"Davisville", "state": "MO"},
    "65483": {"city":"Houston", "state": "MO"},
    "65637": {"city":"Dora", "state": "MO"},
    "65654": {"city":"Freistatt", "state": "MO"},
    "65663": {"city":"Half Way", "state": "MO"},
    "65728": {"city":"Ponce De Leon", "state": "MO"},
    "65735": {"city":"Quincy", "state": "MO"},
    "65754": {"city":"Spokane", "state": "MO"},
    "65793": {"city":"Willow Springs", "state": "MO"},
    "65809": {"city":"Springfield", "state": "MO"},
    "63110": {"city":"Saint Louis", "state": "MO"},
    "63123": {"city":"Saint Louis", "state": "MO"},
    "63361": {"city":"Montgomery City", "state": "MO"},
    "63386": {"city":"West Alton", "state": "MO"},
    "63388": {"city":"Williamsburg", "state": "MO"},
    "63452": {"city":"Lewistown", "state": "MO"},
    "63459": {"city":"New London", "state": "MO"},
    "63629": {"city":"Bunker", "state": "MO"},
    "63732": {"city":"Altenburg", "state": "MO"},
    "63839": {"city":"Cooter", "state": "MO"},
    "63868": {"city":"Morehouse", "state": "MO"},
    "63879": {"city":"Wardell", "state": "MO"},
    "63932": {"city":"Broseley", "state": "MO"},
    "63953": {"city":"Naylor", "state": "MO"},
    "64037": {"city":"Higginsville", "state": "MO"},
    "64061": {"city":"Kingsville", "state": "MO"},
    "64066": {"city":"Levasy", "state": "MO"},
    "64459": {"city":"Helena", "state": "MO"},
    "64645": {"city":"Harris", "state": "MO"},
    "64723": {"city":"Amsterdam", "state": "MO"},
    "64735": {"city":"Clinton", "state": "MO"},
    "64843": {"city":"Goodman", "state": "MO"},
    "64858": {"city":"Racine", "state": "MO"},
    "64862": {"city":"Sarcoxie", "state": "MO"},
    "65240": {"city":"Centralia", "state": "MO"},
    "65462": {"city":"Edgar Springs", "state": "MO"},
    "65624": {"city":"Cape Fair", "state": "MO"},
    "65733": {"city":"Protem", "state": "MO"},
    "65767": {"city":"Urbana", "state": "MO"},
    "63017": {"city":"Chesterfield", "state": "MO"},
    "63088": {"city":"Valley Park", "state": "MO"},
    "63138": {"city":"Saint Louis", "state": "MO"},
    "63155": {"city":"Saint Louis", "state": "MO"},
    "63430": {"city":"Alexandria", "state": "MO"},
    "63451": {"city":"Leonard", "state": "MO"},
    "63472": {"city":"Wayland", "state": "MO"},
    "63544": {"city":"Green Castle", "state": "MO"},
    "63557": {"city":"New Boston", "state": "MO"},
    "63675": {"city":"Vulcan", "state": "MO"},
    "63738": {"city":"Brownwood", "state": "MO"},
    "63739": {"city":"Burfordville", "state": "MO"},
    "63833": {"city":"Catron", "state": "MO"},
    "63853": {"city":"Holland", "state": "MO"},
    "63878": {"city":"Tallapoosa", "state": "MO"},
    "64015": {"city":"Blue Springs", "state": "MO"},
    "64016": {"city":"Buckner", "state": "MO"},
    "64071": {"city":"Mayview", "state": "MO"},
    "64108": {"city":"Kansas City", "state": "MO"},
    "64130": {"city":"Kansas City", "state": "MO"},
    "64145": {"city":"Kansas City", "state": "MO"},
    "64150": {"city":"Riverside", "state": "MO"},
    "64442": {"city":"Eagleville", "state": "MO"},
    "64506": {"city":"Saint Joseph", "state": "MO"},
    "64623": {"city":"Bosworth", "state": "MO"},
    "64633": {"city":"Carrollton", "state": "MO"},
    "64655": {"city":"Lucerne", "state": "MO"},
    "64668": {"city":"Norborne", "state": "MO"},
    "64720": {"city":"Adrian", "state": "MO"},
    "64771": {"city":"Moundville", "state": "MO"},
    "64784": {"city":"Sheldon", "state": "MO"},
    "64830": {"city":"Alba", "state": "MO"},
    "64847": {"city":"Lanagan", "state": "MO"},
    "64857": {"city":"Purcell", "state": "MO"},
    "64870": {"city":"Webb City", "state": "MO"},
    "65048": {"city":"Koeltztown", "state": "MO"},
    "65246": {"city":"Dalton", "state": "MO"},
    "65263": {"city":"Madison", "state": "MO"},
    "65283": {"city":"Stoutsville", "state": "MO"},
    "65322": {"city":"Blackwater", "state": "MO"},
    "65552": {"city":"Plato", "state": "MO"},
    "65567": {"city":"Stoutland", "state": "MO"},
    "65605": {"city":"Aurora", "state": "MO"},
    "65614": {"city":"Bradleyville", "state": "MO"},
    "65617": {"city":"Brighton", "state": "MO"},
    "65708": {"city":"Monett", "state": "MO"},
    "65739": {"city":"Ridgedale", "state": "MO"},
    "65810": {"city":"Springfield", "state": "MO"},
    "63134": {"city":"Saint Louis", "state": "MO"},
    "63144": {"city":"Saint Louis", "state": "MO"},
    "63456": {"city":"Monroe City", "state": "MO"},
    "63460": {"city":"Novelty", "state": "MO"},
    "63545": {"city":"Green City", "state": "MO"},
    "63640": {"city":"Farmington", "state": "MO"},
    "63645": {"city":"Fredericktown", "state": "MO"},
    "63664": {"city":"Potosi", "state": "MO"},
    "63787": {"city":"Zalma", "state": "MO"},
    "63933": {"city":"Campbell", "state": "MO"},
    "63943": {"city":"Grandin", "state": "MO"},
    "63952": {"city":"Mill Spring", "state": "MO"},
    "63957": {"city":"Piedmont", "state": "MO"},
    "64020": {"city":"Concordia", "state": "MO"},
    "64024": {"city":"Excelsior Springs", "state": "MO"},
    "64085": {"city":"Richmond", "state": "MO"},
    "64120": {"city":"Kansas City", "state": "MO"},
    "64128": {"city":"Kansas City", "state": "MO"},
    "64748": {"city":"Golden City", "state": "MO"},
    "65066": {"city":"Owensville", "state": "MO"},
    "65101": {"city":"Jefferson City", "state": "MO"},
    "65215": {"city":"Columbia", "state": "MO"},
    "65354": {"city":"Syracuse", "state": "MO"},
    "65459": {"city":"Dixon", "state": "MO"},
    "65570": {"city":"Success", "state": "MO"},
    "65657": {"city":"Garrison", "state": "MO"},
    "65686": {"city":"Kimberling City", "state": "MO"},
    "65725": {"city":"Pleasant Hope", "state": "MO"},
    "65746": {"city":"Seymour", "state": "MO"},
    "65761": {"city":"Theodosia", "state": "MO"},
    "65779": {"city":"Wheatland", "state": "MO"},
    "63019": {"city":"Crystal City", "state": "MO"},
    "63026": {"city":"Fenton", "state": "MO"},
    "63048": {"city":"Herculaneum", "state": "MO"},
    "63367": {"city":"Lake Saint Louis", "state": "MO"},
    "63446": {"city":"Knox City", "state": "MO"},
    "63450": {"city":"Lentner", "state": "MO"},
    "63469": {"city":"Shelbyville", "state": "MO"},
    "63551": {"city":"Livonia", "state": "MO"},
    "63623": {"city":"Belleview", "state": "MO"},
    "63638": {"city":"Ellington", "state": "MO"},
    "63655": {"city":"Marquand", "state": "MO"},
    "63745": {"city":"Dutchtown", "state": "MO"},
    "63820": {"city":"Anniston", "state": "MO"},
    "63848": {"city":"Gideon", "state": "MO"},
    "63867": {"city":"Matthews", "state": "MO"},
    "63937": {"city":"Ellsinore", "state": "MO"},
    "64067": {"city":"Lexington", "state": "MO"},
    "64079": {"city":"Platte City", "state": "MO"},
    "64156": {"city":"Kansas City", "state": "MO"},
    "64725": {"city":"Archie", "state": "MO"},
    "64767": {"city":"Milo", "state": "MO"},
    "64776": {"city":"Osceola", "state": "MO"},
    "64788": {"city":"Urich", "state": "MO"},
    "64836": {"city":"Carthage", "state": "MO"},
    "65041": {"city":"Hermann", "state": "MO"},
    "65049": {"city":"Lake Ozark", "state": "MO"},
    "65067": {"city":"Portland", "state": "MO"},
    "65230": {"city":"Armstrong", "state": "MO"},
    "65276": {"city":"Pilot Grove", "state": "MO"},
    "65279": {"city":"Rocheport", "state": "MO"},
    "65325": {"city":"Cole Camp", "state": "MO"},
    "65457": {"city":"Devils Elbow", "state": "MO"},
    "65470": {"city":"Falcon", "state": "MO"},
    "65559": {"city":"Saint James", "state": "MO"},
    "65604": {"city":"Ash Grove", "state": "MO"},
    "65619": {"city":"Brookline","state": "MO"},
    "65632": {"city":"Conway","state": "MO"},
    "65640": {"city":"Dunnegan","state": "MO"},
    "65778": {"city":"Myrtle","state": "MO"},
    "65783": {"city":"Windyville","state": "MO"},
    "63049": {"city":"High","state": "Ridge"},
    "63053": {"city":"Kimmswick","state": "MO"},
    "63070": {"city":"Pevely","state": "MO"},
    "63103": {"city":"Saint","state": "Louis"},
    "63334": {"city":"Bowling","state": "Green"},
    "63344": {"city":"Eolia","state": "MO"},
    "63357": {"city":"Marthasville","state": "MO"},
    "63390": {"city":"Wright","state": "City"},
    "63458": {"city":"Newark","state": "MO"},
    "63764": {"city":"Marble","state": "Hill"},
    "63774": {"city":"Perkins","state": "MO"},
    "63877": {"city":"Steele","state": "MO"},
    "63880": {"city":"Whiteoak","state": "MO"},
    "63961": {"city":"Qulin","state": "MO"},
    "64050": {"city":"Independence","state": "MO"},
    "64070": {"city":"Lone","state": "Jack"},
    "64119": {"city":"Kansas","state": "City"},
    "64146": {"city":"Kansas","state": "City"},
    "64465": {"city":"Lathrop","state": "MO"},
    "64476": {"city":"Pickering","state": "MO"},
    "64505": {"city":"Saint","state": "Joseph"},
    "64636": {"city":"Coffey","state": "MO"},
    "64641": {"city":"Galt","state": "MO"},
    "64654": {"city":"Lock","state": "Springs"},
    "64722": {"city":"Amoret","state": "MO"},
    "64855": {"city":"Oronogo","state": "MO"},
    "64873": {"city":"Wentworth","state": "MO"},
    "65016": {"city":"Bonnots","state": "Mill"},
    "65024": {"city":"Chamois","state": "MO"},
    "65075": {"city":"Saint","state": "Elizabeth"},
    "65082": {"city":"Tuscumbia","state": "MO"},
    "65233": {"city":"Boonville","state": "MO"},
    "65329": {"city":"Florence","state": "MO"},
    "65536": {"city":"Lebanon","state": "MO"},
    "65571": {"city":"Summersville","state": "MO"},
    "65589": {"city":"Yukon","state": "MO"},
    "65676": {"city":"Isabella","state": "MO"},
    "65682": {"city":"Lockwood","state": "MO"},
    "65707": {"city":"Miller","state": "MO"},
    "65732": {"city":"Preston","state": "MO"},
    "63012": {"city":"Barnhart","state": "MO"},
    "63036": {"city":"French","state": "Village"},
    "63139": {"city":"Saint","state": "Louis"},
    "63431": {"city":"Anabel","state": "MO"},
    "63457": {"city":"Monticello","state": "MO"},
    "63465": {"city":"Revere","state": "MO"},
    "63547": {"city":"Hurdland","state": "MO"},
    "63662": {"city":"Patton","state": "MO"},
    "63670": {"city":"Sainte","state": "Genevieve"},
    "63751": {"city":"Glenallen","state": "MO"},
    "63769": {"city":"Oak","state": "Ridge"},
    "63849": {"city":"Gobler","state": "MO"},
    "63851": {"city":"Hayti","state": "MO"},
    "63869": {"city":"New","state": "Madrid"},
    "63966": {"city":"Wappapello","state": "MO"},
    "64021": {"city":"Corder","state": "MO"},
    "64058": {"city":"Independence","state": "MO"},
    "64131": {"city":"Kansas","state": "City"},
    "64439": {"city":"Dearborn","state": "MO"},
    "64454": {"city":"Gower","state": "MO"},
    "64649": {"city":"Kidder","state": "MO"},
    "64661": {"city":"Mercer","state": "MO"},
    "64834": {"city":"Carl","state": "Junction"},
    "65043": {"city":"Holts","state": "Summit"},
    "65282": {"city":"Santa","state": "Fe"},
    "65335": {"city":"Ionia","state": "MO"},
    "65347": {"city":"Nelson","state": "MO"},
    "65449": {"city":"Cook","state": "Sta"},
    "65464": {"city":"Elk","state": "Creek"},
    "65555": {"city":"Raymondville","state": "MO"},
    "65626": {"city":"Caulfield","state": "MO"},
    "65661": {"city":"Greenfield","state": "MO"},
    "65781": {"city":"Willard","state": "MO"},
    "65788": {"city":"Peace","state": "Valley"},
    "63031": {"city":"Florissant","state": "MO"},
    "63089": {"city":"Villa","state": "Ridge"},
    "63091": {"city":"Rosebud","state": "MO"},
    "63120": {"city":"Saint","state": "Louis"},
    "63438": {"city":"Durham","state": "MO"},
    "63467": {"city":"Saverton","state": "MO"},
    "63471": {"city":"Taylor","state": "MO"},
    "63474": {"city":"Wyaconda","state": "MO"},
    "63857": {"city":"Kennett","state": "MO"},
    "63874": {"city":"Risco","state": "MO"},
    "64019": {"city":"Centerview","state": "MO"},
    "64401": {"city":"Agency","state": "MO"},
    "64431": {"city":"Clearmont","state": "MO"},
    "64438": {"city":"Darlington","state": "MO"},
    "64446": {"city":"Fairfax","state": "MO"},
    "64469": {"city":"Maysville","state": "MO"},
    "64620": {"city":"Altamont","state": "MO"},
    "64741": {"city":"Deerfield","state": "MO"},
    "64801": {"city":"Joplin","state": "MO"},
    "64848": {"city":"La","state": "Russell"},
    "64861": {"city":"Rocky","state": "Comfort"},
    "65023": {"city":"Centertown","state": "MO"},
    "65047": {"city":"Kaiser","state": "MO"},
    "65078": {"city":"Stover","state": "MO"},
    "65084": {"city":"Versailles","state": "MO"},
    "65255": {"city":"Hallsville","state": "MO"},
    "65262": {"city":"Kingdom","state": "City"},
    "65625": {"city":"Cassville","state": "MO"},
    "65720": {"city":"Oldfield","state": "MO"},
    "65722": {"city":"Phillipsburg","state": "MO"},
    "65740": {"city":"Rockaway","state": "Beach"},
    "65755": {"city":"Squires","state": "MO"},
    "65785": {"city":"Stockton","state": "MO"},
    "63025": {"city":"Eureka","state": "MO"},
    "63028": {"city":"Festus","state": "MO"},
    "63052": {"city":"Imperial","state": "MO"},
    "63060": {"city":"Lonedell","state": "MO"},
    "63105": {"city":"Saint","state": "Louis"},
    "63118": {"city":"Saint","state": "Louis"},
    "63350": {"city":"High","state": "Hill"},
    "63448": {"city":"La","state": "Grange"},
    "63468": {"city":"Shelbina","state": "MO"},
    "63561": {"city":"Queen","state": "City"},
    "63650": {"city":"Ironton","state": "MO"},
    "63784": {"city":"Vanduser","state": "MO"},
    "63862": {"city":"Lilbourn","state": "MO"},
    "63935": {"city":"Doniphan","state": "MO"},
    "64017": {"city":"Camden","state": "MO"},
    "64086": {"city":"Lees","state": "Summit"},
    "64402": {"city":"Albany","state": "MO"},
    "64422": {"city":"Amity","state": "MO"},
    "64490": {"city":"Stewartsville","state": "MO"},
    "64492": {"city":"Trimble","state": "MO"},
    "64601": {"city":"Chillicothe","state": "MO"},
    "64651": {"city":"Laclede","state": "MO"},
    "64688": {"city":"Wheeling","state": "MO"},
    "64761": {"city":"Leeton","state": "MO"},
    "65040": {"city":"Henley","state": "MO"},
    "65074": {"city":"Russellville","state": "MO"},
    "65203": {"city":"Columbia","state": "MO"},
    "65301": {"city":"Sedalia","state": "MO"},
    "65344": {"city":"Miami","state": "MO"},
    "65349": {"city":"Slater","state": "MO"},
    "65611": {"city":"Blue","state": "Eye"},
    "65648": {"city":"Fair","state": "Grove"},
    "65658": {"city":"Golden","state": "MO"},
    "63011": {"city":"Ballwin","state": "MO"},
    "63104": {"city":"Saint","state": "Louis"},
    "63115": {"city":"Saint","state": "Louis"},
    "63124": {"city":"Saint","state": "Louis"},
    "63129": {"city":"Saint","state": "Louis"},
    "63332": {"city":"Augusta","state": "MO"},
    "63339": {"city":"Curryville","state": "MO"},
    "63384": {"city":"Wellsville","state": "MO"},
    "63441": {"city":"Frankford","state": "MO"},
    "63462": {"city":"Perry","state": "MO"},
    "63538": {"city":"Elmer","state": "MO"},
    "63621": {"city":"Arcadia","state": "MO"},
    "63627": {"city":"Bloomsdale","state": "MO"},
    "63633": {"city":"Centerville","state": "MO"},
    "63701": {"city":"Cape","state": "Girardeau"},
    "63830": {"city":"Caruthersville","state": "MO"},
    "63870": {"city":"Parma","state": "MO"},
    "64036": {"city":"Henrietta","state": "MO"},
    "64064": {"city":"Lees","state": "Summit"},
    "64082": {"city":"Lees","state": "Summit"},
    "64102": {"city":"Kansas","state": "City"},
    "64161": {"city":"Kansas","state": "City"},
    "64443": {"city":"Easton","state": "MO"},
    "64482": {"city":"Rock","state": "Port"},
    "64728": {"city":"Bronaugh","state": "MO"},
    "64859": {"city":"Reeds","state": "MO"},
    "65046": {"city":"Jamestown","state": "MO"},
    "65350": {"city":"Smithton","state": "MO"},
    "65401": {"city":"Rolla","state": "MO"},
    "65582": {"city":"Vienna","state": "MO"},
    "65584": {"city":"Saint","state": "Robert"},
    "65622": {"city":"Buffalo","state": "MO"},
    "65647": {"city":"Exeter","state": "MO"},
    "65730": {"city":"Powell","state": "MO"},
    "65773": {"city":"Wasola","state": "MO"},
    "65804": {"city":"Springfield","state": "MO"},
    "63005": {"city":"Chesterfield","state": "MO"},
    "63033": {"city":"Florissant","state": "MO"},
    "63068": {"city":"New","state": "Haven"},
    "63080": {"city":"Sullivan","state": "MO"},
    "63102": {"city":"Saint","state": "Louis"},
    "63130": {"city":"Saint","state": "Louis"},
    "63133": {"city":"Saint","state": "Louis"},
    "63348": {"city":"Foristell","state": "MO"},
    "63366": {"city":"O","state": "Fallon"},
    "63443": {"city":"Hunnewell","state": "MO"},
    "63447": {"city":"La","state": "Belle"},
    "63534": {"city":"Callao","state": "MO"},
    "63666": {"city":"Reynolds","state": "MO"},
    "63760": {"city":"Leopold","state": "MO"},
    "63934": {"city":"Clubb","state": "MO"},
    "64052": {"city":"Independence","state": "MO"},
    "64076": {"city":"Odessa","state": "MO"},
    "64138": {"city":"Kansas","state": "City"},
    "64154": {"city":"Kansas","state": "City"},
    "64444": {"city":"Edgerton","state": "MO"},
    "64480": {"city":"Rea","state": "MO"},
    "64652": {"city":"Laredo","state": "MO"},
    "64744": {"city":"El","state": "Dorado Springs"},
    "64842": {"city":"Fairview","state": "MO"},
    "65065": {"city":"Osage","state": "Beach"},
    "65231": {"city":"Auxvasse","state": "MO"},
    "65248": {"city":"Fayette","state": "MO"},
    "65609": {"city":"Bakersfield","state": "MO"},
    "65620": {"city":"Bruner","state": "MO"},
    "65641": {"city":"Eagle","state": "Rock"},
    "65802": {"city":"Springfield","state": "MO"},
    "63039": {"city":"Gray","state": "Summit"},
    "63071": {"city":"Richwoods","state": "MO"},
    "63072": {"city":"Robertsville","state": "MO"},
    "63079": {"city":"Stanton","state": "MO"},
    "63146": {"city":"Saint","state": "Louis"},
    "63373": {"city":"Portage","state": "Des Sioux"},
    "63434": {"city":"Bethel","state": "MO"},
    "63440": {"city":"Ewing","state": "MO"},
    "63473": {"city":"Williamstown","state": "MO"},
    "63555": {"city":"Memphis","state": "MO"},
    "63829": {"city":"Cardwell","state": "MO"},
    "63845": {"city":"East","state": "Prairie"},
    "63860": {"city":"Kewanee","state": "MO"},
    "64030": {"city":"Grandview","state": "MO"},
    "64055": {"city":"Independence","state": "MO"},
    "64057": {"city":"Independence","state": "MO"},
    "64065": {"city":"Lees","state": "Summit"},
    "64074": {"city":"Napoleon","state": "MO"},
    "64077": {"city":"Orrick","state": "MO"},
    "64109": {"city":"Kansas","state": "City"},
    "64123": {"city":"Kansas","state": "City"},
    "64133": {"city":"Kansas","state": "City"},
    "64139": {"city":"Kansas","state": "City"},
    "64149": {"city":"Kansas","state": "City"},
    "64152": {"city":"Kansas","state": "City"},
    "64423": {"city":"Barnard","state": "MO"},
    "64473": {"city":"Oregon","state": "MO"},
    "64643": {"city":"Hale","state": "MO"},
    "64656": {"city":"Ludlow","state": "MO"},
    "64747": {"city":"Garden","state": "City"},
    "64780": {"city":"Rockville","state": "MO"},
    "65001": {"city":"Argyle","state": "MO"},
    "65332": {"city":"Green","state": "Ridge"},
    "65550": {"city":"Newburg","state": "MO"},
    "65565": {"city":"Steelville","state": "MO"},
    "65586": {"city":"Wesco","state": "MO"},
    "65590": {"city":"Long","state": "Lane"},
    "65704": {"city":"Mansfield","state": "MO"},
    "65721": {"city":"Ozark","state": "MO"},
    "65729": {"city":"Pontiac","state": "MO"},
    "65762": {"city":"Thornfield","state": "MO"},
    "65764": {"city":"Tunas","state": "MO"},
    "63010": {"city":"Arnold","state": "MO"},
    "63023": {"city":"Dittmer","state": "MO"},
    "63043": {"city":"Maryland","state": "Heights"},
    "63044": {"city":"Bridgeton","state": "MO"},
    "63107": {"city":"Saint","state": "Louis"},
    "63131": {"city":"Saint","state": "Louis"},
    "63140": {"city":"Saint","state": "Louis"},
    "63304": {"city":"Saint","state": "Charles"},
    "63345": {"city":"Farber","state": "MO"},
    "63381": {"city":"Truxton","state": "MO"},
    "63445": {"city":"Kahoka","state": "MO"},
    "63601": {"city":"Park","state": "Hills"},
    "63663": {"city":"Pilot","state": "Knob"},
    "63852": {"city":"Holcomb","state": "MO"},
    "63967": {"city":"Williamsville","state": "MO"},
    "64034": {"city":"Greenwood","state": "MO"},
    "64035": {"city":"Hardin","state": "MO"},
    "64062": {"city":"Lawson","state": "MO"},
    "64068": {"city":"Liberty","state": "MO"},
    "64088": {"city":"Sibley","state": "MO"},
    "64421": {"city":"Amazonia","state": "MO"},
    "64451": {"city":"Forest","state": "City"},
    "64453": {"city":"Gentry","state": "MO"},
    "64463": {"city":"King","state": "City"},
    "64628": {"city":"Brookfield","state": "MO"},
    "64679": {"city":"Spickard","state": "MO"},
    "64849": {"city":"Neck","state": "City"},
    "65018": {"city":"California","state": "MO"},
    "65059": {"city":"Mokane","state": "MO"},
    "65320": {"city":"Arrow","state": "Rock"},
    "65327": {"city":"Emma","state": "MO"},
    "65436": {"city":"Beulah","state": "MO"},
    "65580": {"city":"Vichy","state": "MO"},
    "65603": {"city":"Arcola","state": "MO"},
    "65618": {"city":"Brixey","state": "MO"},
    "65679": {"city":"Kirbyville","state": "MO"},
    "65702": {"city":"Macomb","state": "MO"},
    "65731": {"city":"Powersite","state": "MO"},
    "63020": {"city":"De","state": "Soto"},
    "63042": {"city":"Hazelwood","state": "MO"},
    "63061": {"city":"Luebbering","state": "MO"},
    "63111": {"city":"Saint","state": "Louis"},
    "63126": {"city":"Saint","state": "Louis"},
    "63379": {"city":"Troy","state": "MO"},
    "63387": {"city":"Whiteside","state": "MO"},
    "63436": {"city":"Center","state": "MO"},
    "63556": {"city":"Milan","state": "MO"},
    "63620": {"city":"Annapolis","state": "MO"},
    "63636": {"city":"Des","state": "Arc"},
    "63730": {"city":"Advance","state": "MO"},
    "63742": {"city":"Commerce","state": "MO"},
    "63763": {"city":"McGee","state": "MO"},
    "63939": {"city":"Fairdealing","state": "MO"},
    "63954": {"city":"Neelyville","state": "MO"},
    "63962" : { "city": "Rombauer", "state":"MO"},
    "63964" : { "city": "Silva", "state":"MO"},
    "64437" : { "city": "Craig", "state":"MO"},
    "64471" : { "city": "New Hampton", "state":"MO"},
    "64474" : { "city": "Osborn", "state":"MO"},
    "64483" : { "city": "Rosendale", "state":"MO"},
    "64504" : { "city": "Saint Joseph", "state":"MO"},
    "64507" : { "city": "Saint Joseph", "state":"MO"},
    "64638" : { "city": "Dawn", "state":"MO"},
    "64660" : { "city": "Mendon", "state":"MO"},
    "64681" : { "city": "Sumner", "state":"MO"},
    "64739" : { "city": "Creighton", "state":"MO"},
    "64763" : { "city": "Lowry City", "state":"MO"},
    "64769" : { "city": "Mindenmines", "state":"MO"},
    "65052" : { "city": "Linn Creek", "state":"MO"},
    "65064" : { "city": "Olean", "state":"MO"},
    "65259" : { "city": "Huntsville", "state":"MO"},
    "65324" : { "city": "Climax Springs", "state":"MO"},
    "65345" : { "city": "Mora", "state":"MO"},
    "65452" : { "city": "Crocker", "state":"MO"},
    "65479" : { "city": "Hartshorn", "state":"MO"},
    "65535" : { "city": "Leasburg", "state":"MO"},
    "65623" : { "city": "Butterfield", "state":"MO"},
    "65630" : { "city": "Chestnutridge", "state":"MO"},
    "65672" : { "city": "Hollister", "state":"MO"},
    "65747" : { "city": "Shell Knob", "state":"MO"},
    "65753" : { "city": "Sparta", "state":"MO"},
    "65806" : { "city": "Springfield", "state":"MO"},
    "63108" : { "city": "Saint Louis", "state":"MO"},
    "63114" : { "city": "Saint Louis", "state":"MO"},
    "63333" : { "city": "Bellflower", "state":"MO"},
    "63336" : { "city": "Clarksville", "state":"MO"},
    "63347" : { "city": "Foley", "state":"MO"},
    "63560" : { "city": "Pollock", "state":"MO"},
    "63766" : { "city": "Millersville", "state":"MO"},
    "63783" : { "city": "Uniontown", "state":"MO"},
    "63785" : { "city": "Whitewater", "state":"MO"},
    "63824" : { "city": "Blodgett", "state":"MO"},
    "63827" : { "city": "Bragg City", "state":"MO"},
    "63847" : { "city": "Gibson", "state":"MO"},
    "64014" : { "city": "Blue Springs", "state":"MO"},
    "64018" : { "city": "Camden Point", "state":"MO"},
    "64106" : { "city": "Kansas City", "state":"MO"},
    "64110" : { "city": "Kansas City", "state":"MO"},
    "64116" : { "city": "Kansas City", "state":"MO"},
    "64117" : { "city": "Kansas City", "state":"MO"},
    "64134" : { "city": "Kansas City", "state":"MO"},
    "64157" : { "city": "Kansas City", "state":"MO"},
    "64158" : { "city": "Kansas City", "state":"MO"},
    "64647" : { "city": "Jameson", "state":"MO"},
    "64672" : { "city": "Powersville", "state":"MO"},
    "64755" : { "city": "Jasper", "state":"MO"},
    "64765" : { "city": "Metz", "state":"MO"},
    "64779" : { "city": "Rich Hill", "state":"MO"},
    "64844" : { "city": "Granby", "state":"MO"},
    "64866" : { "city": "Stark City", "state":"MO"},
    "65013" : { "city": "Belle", "state":"MO"},
    "65080" : { "city": "Tebbetts", "state":"MO"},
    "65256" : { "city": "Harrisburg", "state":"MO"},
    "65330" : { "city": "Gilliam", "state":"MO"},
    "65473" : { "city": "Fort Leonard Wood", "state":"MO"},
    "65542" : { "city": "Licking", "state":"MO"},
    "65588" : { "city": "Winona", "state":"MO"},
    "65727" : { "city": "Polk", "state":"MO"},
    "65734" : { "city": "Purdy", "state":"MO"},
    "63013" : { "city": "Beaufort", "state":"MO"},
    "63030" : { "city": "Fletcher", "state":"MO"},
    "63087" : { "city": "Valles Mines", "state":"MO"},
    "63501" : { "city": "Kirksville", "state":"MO"},
    "63622" : { "city": "Belgrade", "state":"MO"},
    "63631" : { "city": "Caledonia", "state":"MO"},
    "63654" : { "city": "Lesterville", "state":"MO"},
    "64078" : { "city": "Peculiar", "state":"MO"},
    "64083" : { "city": "Raymore", "state":"MO"},
    "64132" : { "city": "Kansas City", "state":"MO"},
    "64430" : { "city": "Clarksdale", "state":"MO"},
    "64455" : { "city": "Graham", "state":"MO"},
    "64468" : { "city": "Maryville", "state":"MO"},
    "64477" : { "city": "Plattsburg", "state":"MO"},
    "64682" : { "city": "Tina", "state":"MO"},
    "64832" : { "city": "Asbury", "state":"MO"},
    "64863" : { "city": "South West City", "state":"MO"},
    "64874" : { "city": "Wheaton", "state":"MO"},
    "65063" : { "city": "New Bloomfield", "state":"MO"},
    "65069" : { "city": "Rhineland", "state":"MO"},
    "65079" : { "city": "Sunrise Beach", "state":"MO"},
    "65083" : { "city": "Ulman", "state":"MO"},
    "65201" : { "city": "Columbia", "state":"MO"},
    "65250" : { "city": "Franklin", "state":"MO"},
    "65251" : { "city": "Fulton", "state":"MO"},
    "65261" : { "city": "Keytesville", "state":"MO"},
    "65270" : { "city": "Moberly", "state":"MO"},
    "65285" : { "city": "Thompson", "state":"MO"},
    "65610" : { "city": "Billings", "state":"MO"},
    "65669" : { "city": "Highlandville", "state":"MO"},
    "65715" : { "city": "Noble", "state":"MO"},
    "65759" : { "city": "Taneyville", "state":"MO"},
    "65760" : { "city": "Tecumseh", "state":"MO"},
    "65803" : { "city": "Springfield", "state":"MO"},
    "63074" : { "city": "Saint Ann", "state":"MO"},
    "63090" : { "city": "Washington", "state":"MO"},
    "63109" : { "city": "Saint Louis", "state":"MO"},
    "63113" : { "city": "Saint Louis", "state":"MO"},
    "63117" : { "city": "Saint Louis", "state":"MO"},
    "63127" : { "city": "Saint Louis", "state":"MO"},
    "63128" : { "city": "Saint Louis", "state":"MO"},
    "63353" : { "city": "Louisiana", "state":"MO"},
    "63370" : { "city": "Olney", "state":"MO"},
    "63533" : { "city": "Brashear", "state":"MO"},
    "63535" : { "city": "Coatsville", "state":"MO"},
    "63537" : { "city": "Edina", "state":"MO"},
    "63558" : { "city": "New Cambria", "state":"MO"},
    "63565" : { "city": "Unionville", "state":"MO"},
    "63637" : { "city": "Doe Run", "state":"MO"},
    "64022" : { "city": "Dover", "state":"MO"},
    "64029" : { "city": "Grain Valley", "state":"MO"},
    "64054" : { "city": "Independence", "state":"MO"},
    "64056" : { "city": "Independence", "state":"MO"},
    "64093" : { "city": "Warrensburg", "state":"MO"},
    "64164" : { "city": "Kansas City", "state":"MO"},
    "64165" : { "city": "Kansas City", "state":"MO"},
    "64420" : { "city": "Allendale", "state":"MO"},
    "64498" : { "city": "Westboro", "state":"MO"},
    "64659" : { "city": "Meadville", "state":"MO"},
    "64674" : { "city": "Purdin", "state":"MO"},
    "64770" : { "city": "Montrose", "state":"MO"},
    "64772" : { "city": "Nevada", "state":"MO"},
    "65237" : { "city": "Bunceton", "state":"MO"},
    "65247" : { "city": "Excello", "state":"MO"},
    "65360" : { "city": "Windsor", "state":"MO"},
    "65443" : { "city": "Brinktown", "state":"MO"},
    "65566" : { "city": "Viburnum", "state":"MO"},
    "65650" : { "city": "Flemington", "state":"MO"},
    "65674" : { "city": "Humansville", "state":"MO"},
    "65705" : { "city": "Marionville", "state":"MO"},
    "65717" : { "city": "Norwood", "state":"MO"},
    "65757" : { "city": "Strafford", "state":"MO"},
    "65770" : { "city": "Walnut Grove", "state":"MO"},
    "65772" : { "city": "Washburn", "state":"MO"},
    "65777" : { "city": "Moody", "state":"MO"},
    "65789" : { "city": "Pomona", "state":"MO"},
    "63077" : { "city": "Saint Clair", "state":"MO"},
    "63363" : { "city": "New Florence", "state":"MO"},
    "63377" : { "city": "Silex", "state":"MO"},
    "63389" : { "city": "Winfield", "state":"MO"},
    "63461" : { "city": "Palmyra", "state":"MO"},
    "63463" : { "city": "Philadelphia", "state":"MO"},
    "63549" : { "city": "La Plata", "state":"MO"},
    "63552" : { "city": "Macon", "state":"MO"},
    "63873" : { "city": "Portageville", "state":"MO"},
    "64084" : { "city": "Rayville", "state":"MO"},
    "64105" : { "city": "Kansas City", "state":"MO"},
    "64147" : { "city": "Kansas City", "state":"MO"},
    "64426" : { "city": "Blythedale", "state":"MO"},
    "64432" : { "city": "Clyde", "state":"MO"},
    "64440" : { "city": "De Kalb", "state":"MO"},
    "64457" : { "city": "Guilford", "state":"MO"},
    "64486" : { "city": "Sheridan", "state":"MO"},
    "64631" : { "city": "Bucklin", "state":"MO"},
    "64667" : { "city": "Newtown", "state":"MO"},
    "64670" : { "city": "Pattonsburg", "state":"MO"},
    "64778" : { "city": "Richards", "state":"MO"},
    "64781" : { "city": "Roscoe", "state":"MO"},
    "64783" : { "city": "Schell City", "state":"MO"},
    "64833" : { "city": "Avilla", "state":"MO"},
    "65072" : { "city": "Rocky Mount", "state":"MO"},
    "65257" : { "city": "Higbee", "state":"MO"},
    "65264" : { "city": "Martinsburg", "state":"MO"},
    "65281" : { "city": "Salisbury", "state":"MO"},
    "65286" : { "city": "Triplett", "state":"MO"},
    "65337" : { "city": "La Monte", "state":"MO"},
    "65339" : { "city": "Malta Bend", "state":"MO"},
    "65446" : { "city": "Cherryville", "state":"MO"},
    "65453" : { "city": "Cuba", "state":"MO"},
    "65556" : { "city": "Richland", "state":"MO"},
    "65608" : { "city": "Ava", "state":"MO"},
    "65655" : { "city": "Gainesville", "state":"MO"},
    "65690" : { "city": "Couch", "state":"MO"},
    "65737" : { "city": "Reeds Spring", "state":"MO"},
    "65756" : { "city": "Stotts City", "state":"MO"},
    "63038" : { "city": "Wildwood", "state":"MO"},
    "63135" : { "city": "Saint Louis", "state":"MO"},
    "63383" : { "city": "Warrenton", "state":"MO"},
    "63401" : { "city": "Hannibal", "state":"MO"},
    "63437" : { "city": "Clarence", "state":"MO"},
    "63567" : { "city": "Worthington", "state":"MO"},
    "63674" : { "city": "Tiff", "state":"MO"},
    "63736" : { "city": "Benton", "state":"MO"},
    "63740" : { "city": "Chaffee", "state":"MO"},
    "63758" : { "city": "Kelso", "state":"MO"},
    "63770" : { "city": "Old Appleton", "state":"MO"},
    "63821" : { "city": "Arbyrd", "state":"MO"},
    "63822" : { "city": "Bernie", "state":"MO"},
    "63855" : { "city": "Hornersville", "state":"MO"},
    "63866" : { "city": "Marston", "state":"MO"},
    "64097" : { "city": "Wellington", "state":"MO"},
    "64124" : { "city": "Kansas City", "state":"MO"},
    "64449" : { "city": "Fillmore", "state":"MO"},
    "64466" : { "city": "Maitland", "state":"MO"},
    "64487" : { "city": "Skidmore", "state":"MO"},
    "64622" : { "city": "Bogard", "state":"MO"},
    "64689" : { "city": "Winston", "state":"MO"},
    "64743" : { "city": "East Lynne", "state":"MO"},
    "64865" : { "city": "Seneca", "state":"MO"},
    "64867" : { "city": "Stella", "state":"MO"},
    "65011" : { "city": "Barnett", "state":"MO"},
    "65085" : { "city": "Westphalia", "state":"MO"},
    "65243" : { "city": "Clark", "state":"MO"},
    "65274" : { "city": "New Franklin", "state":"MO"},
    "65323" : { "city": "Calhoun", "state":"MO"},
    "65461" : { "city": "Duke", "state":"MO"},
    "65463" : { "city": "Eldridge", "state":"MO"},
    "65534" : { "city": "Laquey", "state":"MO"},
    "65606" : { "city": "Alton", "state":"MO"},
    "65644" : { "city": "Elkland", "state":"MO"},
    "65681" : { "city": "Lampe", "state":"MO"},
    "65774" : { "city": "Weaubleau", "state":"MO"},
    "65790" : { "city": "Pottersville", "state":"MO"},
    "63055" : { "city": "Labadie", "state":"MO"},
    "63368" : { "city": "O'Fallon", "state":"MO"},
    "63539" : { "city": "Ethel", "state":"MO"},
    "63546" : { "city": "Greentop", "state":"MO"},
    "63656" : { "city": "Middle Brook", "state":"MO"},
    "63775" : { "city": "Perryville", "state":"MO"},
    "63825" : { "city": "Bloomfield", "state":"MO"},
    "63828" : { "city": "Canalou", "state":"MO"},
    "63945" : { "city": "Harviell", "state":"MO"},
    "64089" : { "city": "Smithville", "state":"MO"},
    "64129" : { "city": "Kansas City", "state":"MO"},
    "64136" : { "city": "Kansas City", "state":"MO"},
    "64151" : { "city": "Kansas City", "state":"MO"},
    "64436" : { "city": "Cosby", "state":"MO"},
    "64467" : { "city": "Martinsville", "state":"MO"},
    "64644" : { "city": "Hamilton", "state":"MO"},
    "64653" : { "city": "Linneus", "state":"MO"},
    "65037" : { "city": "Gravois Mills", "state":"MO"},
    "65061" : { "city": "Morrison", "state":"MO"},
    "65236" : { "city": "Brunswick", "state":"MO"},
    "65439" : { "city": "Bixby", "state":"MO"},
    "65543" : { "city": "Lynchburg", "state":"MO"},
    "65616" : { "city": "Branson", "state":"MO"},
    "65633" : { "city": "Crane", "state":"MO"},
    "65656" : { "city": "Galena", "state":"MO"},
    "65706" : { "city": "Marshfield", "state":"MO"},
    "65784" : { "city": "Zanoni", "state":"MO"},
    "63014" : { "city": "Berger", "state":"MO"},
    "63073" : { "city": "Saint Albans", "state":"MO"},
    "63125" : { "city": "Saint Louis", "state":"MO"},
    "63141" : { "city": "Saint Louis", "state":"MO"},
    "63301" : { "city": "Saint Charles", "state":"MO"},
    "63330" : { "city": "Annada", "state":"MO"},
    "63359" : { "city": "Middletown", "state":"MO"},
    "63439" : { "city": "Emden", "state":"MO"},
    "63536" : { "city": "Downing", "state":"MO"},
    "63559" : { "city": "Novinger", "state":"MO"},
    "63563" : { "city": "Rutledge", "state":"MO"},
    "63625" : { "city": "Black", "state":"MO"},
    "63956" : { "city": "Patterson", "state":"MO"},
    "63960" : { "city": "Puxico", "state":"MO"},
    "64075" : { "city": "Oak Grove", "state":"MO"},
    "64080" : { "city": "Pleasant Hill", "state":"MO"},
    "64090" : { "city": "Strasburg", "state":"MO"},
    "64125" : { "city": "Kansas City", "state":"MO"},
    "64167" : { "city": "Kansas City", "state":"MO"},
    "64428" : { "city": "Burlington Junction", "state":"MO"},
    "64429" : { "city": "Cameron", "state":"MO"},
    "64445" : { "city": "Elmo", "state":"MO"},
    "64485" : { "city": "Savannah", "state":"MO"},
    "64499" : { "city": "Worth", "state":"MO"},
    "64646" : { "city": "Humphreys", "state":"MO"},
    "64650" : { "city": "Kingston", "state":"MO"},
    "64664" : { "city": "Mooresville", "state":"MO"},
    "64701" : { "city": "Harrisonville", "state":"MO"},
    "64730" : { "city": "Butler", "state":"MO"},
    "64734" : { "city": "Cleveland", "state":"MO"},
    "64856" : { "city": "Pineville", "state":"MO"},
    "65051" : { "city": "Linn", "state":"MO"},
    "65239" : { "city": "Cairo", "state":"MO"},
    "65278" : { "city": "Renick", "state":"MO"},
    "65348" : { "city": "Otterville", "state":"MO"},
    "65466" : { "city": "Eminence", "state":"MO"},
    "65501" : { "city": "Jadwin", "state":"MO"},
    "65548" : { "city": "Mountain View", "state":"MO"},
    "65557" : { "city": "Roby", "state":"MO"},
    "65629" : { "city": "Chadwick", "state":"MO"},
    "65652" : { "city": "Fordland", "state":"MO"},
    "65660" : { "city": "Graff", "state":"MO"},
    "65685" : { "city": "Louisburg", "state":"MO"},
    "65712" : { "city": "Mount Vernon", "state":"MO"},
    "65745" : { "city": "Seligman", "state":"MO"},
    "65768" : { "city": "Vanzant", "state":"MO"},
    "63021" : { "city": "Ballwin", "state":"MO"},
    "63041" : { "city": "Grubville", "state":"MO"},
    "63112" : { "city": "Saint Louis", "state":"MO"},
    "63116" : { "city": "Saint Louis", "state":"MO"},
    "63143" : { "city": "Saint Louis", "state":"MO"},
    "63147" : { "city": "Saint Louis", "state":"MO"},
    "63341" : { "city": "Defiance", "state":"MO"},
    "63352" : { "city": "Laddonia", "state":"MO"},
    "63362" : { "city": "Moscow Mills", "state":"MO"},
    "63735" : { "city": "Bell City", "state":"MO"},
    "63747" : { "city": "Friedheim", "state":"MO"},
    "63748" : { "city": "Frohna", "state":"MO"},
    "63767" : { "city": "Morley", "state":"MO"},
    "63901" : { "city": "Poplar Bluff", "state":"MO"},
    "63941" : { "city": "Fremont", "state":"MO"},
    "63955" : { "city": "Oxly", "state":"MO"},
    "64011" : { "city": "Bates City", "state":"MO"},
    "64053" : { "city": "Independence", "state":"MO"},
    "64155" : { "city": "Kansas City", "state":"MO"},
    "64434" : { "city": "Conception Junction", "state":"MO"},
    "64475" : { "city": "Parnell", "state":"MO"},
    "64494" : { "city": "Union Star", "state":"MO"},
    "64496" : { "city": "Watson", "state":"MO"},
    "64632" : { "city": "Cainsville", "state":"MO"},
    "64635" : { "city": "Chula", "state":"MO"},
    "64742" : { "city": "Drexel", "state":"MO"},
    "65039" : { "city": "Hartsburg", "state":"MO"},
    "65232" : { "city": "Benton City", "state":"MO"},
    "65326" : { "city": "Edwards", "state":"MO"},
    "65333" : { "city": "Houstonia", "state":"MO"},
    "65338" : { "city": "Lincoln", "state":"MO"},
    "65541" : { "city": "Lenox", "state":"MO"},
    "65564" : { "city": "Solo", "state":"MO"},
    "65591" : { "city": "Montreal", "state":"MO"},
    "65724" : { "city": "Pittsburg", "state":"MO"},
    "65775" : { "city": "West Plains", "state":"MO"},
    "63040" : { "city": "Wildwood", "state":"MO"},
    "63045" : { "city": "Earth City", "state":"MO"},
    "63122" : { "city": "Saint Louis", "state":"MO"},
    "63132" : { "city": "Saint Louis", "state":"MO"},
    "63351" : { "city": "Jonesburg", "state":"MO"},
    "63435" : { "city": "Canton", "state":"MO"},
    "63626" : { "city": "Blackwell", "state":"MO"},
    "63750" : { "city": "Gipsy", "state":"MO"},
    "63902" : { "city": "Poplar Bluff", "state":"MO"},
    "63942" : { "city": "Gatewood", "state":"MO"},
    "64001" : { "city": "Alma", "state":"MO"},
    "64113" : { "city": "Kansas City", "state":"MO"},
    "64481" : { "city": "Ridgeway", "state":"MO"},
    "64493" : { "city": "Turney", "state":"MO"},
    "64657" : { "city": "McFall", "state":"MO"},
    "64673" : { "city": "Princeton", "state":"MO"},
    "64686" : { "city": "Utica", "state":"MO"},
    "64759" : { "city": "Lamar", "state":"MO"},
    "64790" : { "city": "Walker", "state":"MO"},
    "64850" : { "city": "Neosho", "state":"MO"},
    "65032" : { "city": "Eugene", "state":"MO"},
    "65054" : { "city": "Loose Creek", "state":"MO"},
    "65081" : { "city": "Tipton", "state":"MO"},
    "65305" : { "city": "Whiteman Air Force Base", "state":"MO"},
    "65355" : { "city": "Warsaw", "state":"MO"},
    "65486" : { "city": "Iberia", "state":"MO"},
    "65583" : { "city": "Waynesville", "state":"MO"},
    "65601" : { "city": "Aldrich", "state":"MO"},
    "65612" : { "city": "Bois D Arc", "state":"MO"},
    "65635" : { "city": "Dadeville", "state":"MO"},
    "65653" : { "city": "Forsyth", "state":"MO"},
    "65662" : { "city": "Grovespring", "state":"MO"},
    "65692" : { "city": "Koshkonong", "state":"MO"},
    "65711" : { "city": "Mountain Grove", "state":"MO"},
    "65714" : { "city": "Nixa", "state":"MO"},
    "65742" : { "city": "Rogersville", "state":"MO"},
    "65769" : { "city": "Verona", "state":"MO"},
    "71661" : { "city": "Parkdale", "state":"AR"},
    "71909" : { "city": "Hot Springs Village", "state":"AR"},
    "71944" : { "city": "Grannis", "state":"AR"},
    "71957" : { "city": "Mount Ida", "state":"AR"},
    "72035" : { "city": "Conway", "state":"AR"},
    "72328" : { "city": "Crumrod", "state":"AR"},
    "72410" : { "city": "Alicia", "state":"AR"},
    "72445" : { "city": "Minturn", "state":"AR"},
    "72524" : { "city": "Cord", "state":"AR"},
    "72530" : { "city": "Drasco", "state":"AR"},
    "72537" : { "city": "Gamaliel", "state":"AR"},
    "72672" : { "city": "Pyatt", "state":"AR"},
    "72687" : { "city": "Yellville", "state":"AR"},
    "72762" : { "city": "Springdale", "state":"AR"},
    "72855" : { "city": "Paris", "state":"AR"},
    "71749" : { "city": "Junction City", "state":"AR"},
    "71823" : { "city": "Ben Lomond", "state":"AR"},
    "71826" : { "city": "Bradley", "state":"AR"},
    "71827" : { "city": "Buckner", "state":"AR"},
    "71855" : { "city": "Ozan", "state":"AR"},
    "72051" : { "city": "Fox", "state":"AR"},
    "72157" : { "city": "Springfield", "state":"AR"},
    "72338" : { "city": "Frenchmans Bayou", "state":"AR"},
    "72466" : { "city": "Smithville", "state":"AR"},
    "72471" : { "city": "Swifton", "state":"AR"},
    "72472" : { "city": "Trumann", "state":"AR"},
    "72565" : { "city": "Oxford", "state":"AR"},
    "72668" : { "city": "Peel", "state":"AR"},
    "72669" : { "city": "Pindall", "state":"AR"},
    "72719" : { "city": "Centerton", "state":"AR"},
    "72841" : { "city": "Harvey", "state":"AR"},
    "72853" : { "city": "Ola", "state":"AR"},
    "71658" : { "city": "Montrose", "state":"AR"},
    "71820" : { "city": "Alleene", "state":"AR"},
    "71846" : { "city": "Lockesburg", "state":"AR"},
    "71945" : { "city": "Hatfield", "state":"AR"},
    "72128" : { "city": "Poyen", "state":"AR"},
    "72132" : { "city": "Redfield", "state":"AR"},
    "72170" : { "city": "Ulm", "state":"AR"},
    "72175" : { "city": "Wabbaseka", "state":"AR"},
    "72320" : { "city": "Brickeys", "state":"AR"},
    "72325" : { "city": "Clarkedale", "state":"AR"},
    "72332" : { "city": "Edmondson", "state":"AR"},
    "72364" : { "city": "Marion", "state":"AR"},
    "72365" : { "city": "Marked Tree", "state":"AR"},
    "72394" : { "city": "Widener", "state":"AR"},
    "72628" : { "city": "Deer", "state":"AR"},
    "72662" : { "city": "Omaha", "state":"AR"},
    "72753" : { "city": "Prairie Grove", "state":"AR"},
    "72758" : { "city": "Rogers", "state":"AR"},
    "72821" : { "city": "Altus", "state":"AR"},
    "72928" : { "city": "Branch", "state":"AR"},
    "72946" : { "city": "Mountainburg", "state":"AR"},
    "71647" : { "city": "Hermitage", "state":"AR"},
    "71752" : { "city": "McNeil", "state":"AR"},
    "71759" : { "city": "Norphlet", "state":"AR"},
    "71857" : { "city": "Prescott", "state":"AR"},
    "72315" : { "city": "Blytheville", "state":"AR"},
    "72331" : { "city": "Earle", "state":"AR"},
    "72428" : { "city": "Etowah", "state":"AR"},
    "72436" : { "city": "Lafe", "state":"AR"},
    "72543" : { "city": "Heber Springs", "state":"AR"},
    "72569" : { "city": "Poughkeepsie", "state":"AR"},
    "72802" : { "city": "Russellville", "state":"AR"},
    "72949" : { "city": "Ozark", "state":"AR"},
    "71630" : { "city": "Arkansas City", "state":"AR"},
    "71667" : { "city": "Star City", "state":"AR"},
    "71845" : { "city": "Lewisville", "state":"AR"},
    "71854" : { "city": "Texarkana", "state":"AR"},
    "71929" : { "city": "Bismarck", "state":"AR"},
    "71970" : { "city": "Story", "state":"AR"},
    "71972" : { "city": "Vandervoort", "state":"AR"},
    "72029" : { "city": "Clarendon", "state":"AR"},
    "72037" : { "city": "Coy", "state":"AR"},
    "72059" : { "city": "Gregory", "state":"AR"},
    "72064" : { "city": "Hazen", "state":"AR"},
    "72082" : { "city": "Kensett", "state":"AR"},
    "72103" : { "city": "Mabelvale", "state":"AR"},
    "72126" : { "city": "Perryville", "state":"AR"},
    "72137" : { "city": "Rose Bud", "state":"AR"},
    "72167" : { "city": "Traskwood", "state":"AR"},
    "72426" : { "city": "Dell", "state":"AR"},
    "72427" : { "city": "Egypt", "state":"AR"},
    "72438" : { "city": "Leachville", "state":"AR"},
    "72447" : { "city": "Monette", "state":"AR"},
    "72550" : { "city": "Locust Grove", "state":"AR"},
    "72587" : { "city": "Wiseman", "state":"AR"},
    "72630" : { "city": "Diamond City", "state":"AR"},
    "72642" : { "city": "Lakeview", "state":"AR"},
    "72734" : { "city": "Gentry", "state":"AR"},
    "72745" : { "city": "Lowell", "state":"AR"},
    "72842" : { "city": "Havana", "state":"AR"},
    "71822" : { "city": "Ashdown", "state":"AR"},
    "72002" : { "city": "Alexander", "state":"AR"},
    "72022" : { "city": "Bryant", "state":"AR"},
    "72055" : { "city": "Gillett", "state":"AR"},
    "72057" : { "city": "Grapevine", "state":"AR"},
    "72063" : { "city": "Hattieville", "state":"AR"},
    "72073" : { "city": "Humphrey", "state":"AR"},
    "72108" : { "city": "Monroe", "state":"AR"},
    "72118" : { "city": "North Little Rock", "state":"AR"},
    "72131" : { "city": "Quitman", "state":"AR"},
    "72166" : { "city": "Tichnor", "state":"AR"},
    "72435" : { "city": "Knobel", "state":"AR"},
    "72464" : { "city": "Saint Francis", "state":"AR"},
    "72522" : { "city": "Charlotte", "state":"AR"},
    "72556" : { "city": "Melbourne", "state":"AR"},
    "72645" : { "city": "Leslie", "state":"AR"},
    "72703" : { "city": "Fayetteville", "state":"AR"},
    "71643" : { "city": "Gould", "state":"AR"},
    "71644" : { "city": "Grady", "state":"AR"},
    "71660" : { "city": "New Edinburg", "state":"AR"},
    "71670" : { "city": "Tillar", "state":"AR"},
    "71671" : { "city": "Warren", "state":"AR"},
    "71763" : { "city": "Sparkman", "state":"AR"},
    "71825" : { "city": "Blevins", "state":"AR"},
    "71971" : { "city": "Umpire", "state":"AR"},
    "72003" : { "city": "Almyra", "state":"AR"},
    "72040" : { "city": "Des Arc", "state":"AR"},
    "72084" : { "city": "Leola", "state":"AR"},
    "72101" : { "city": "McCrory", "state":"AR"},
    "72173" : { "city": "Vilonia", "state":"AR"},
    "72201" : { "city": "Little Rock", "state":"AR"},
    "72227" : { "city": "Little Rock", "state":"AR"},
    "72330" : { "city": "Dyess", "state":"AR"},
    "72392" : { "city": "Wheatley", "state":"AR"},
    "72411" : { "city": "Bay", "state":"AR"},
    "72433" : { "city": "Hoxie", "state":"AR"},
    "72453" : { "city": "Peach Orchard", "state":"AR"},
    "72461" : { "city": "Rector", "state":"AR"},
    "72479" : { "city": "Weiner", "state":"AR"},
    "72732" : { "city": "Garfield", "state":"AR"},
    "72760" : { "city": "Saint Paul", "state":"AR"},
    "72835" : { "city": "Delaware", "state":"AR"},
    "72927" : { "city": "Booneville", "state":"AR"},
    "72952" : { "city": "Rudy", "state":"AR"},
    "71726" : { "city": "Chidester", "state":"AR"},
    "71753" : { "city": "Magnolia", "state":"AR"},
    "71772" : { "city": "Whelen Springs", "state":"AR"},
    "71839" : { "city": "Garland City", "state":"AR"},
    "71842" : { "city": "Horatio", "state":"AR"},
    "71847" : { "city": "McCaskill", "state":"AR"},
    "71866" : { "city": "Winthrop", "state":"AR"},
    "71950" : { "city": "Kirby", "state":"AR"},
    "71962" : { "city": "Okolona", "state":"AR"},
    "72010" : { "city": "Bald Knob", "state":"AR"},
    "72016" : { "city": "Bigelow", "state":"AR"},
    "72047" : { "city": "Enola", "state":"AR"},
    "72076" : { "city": "Jacksonville", "state":"AR"},
    "72110" : { "city": "Morrilton", "state":"AR"},
    "72160" : { "city": "Stuttgart", "state":"AR"},
    "72329" : { "city": "Driver", "state":"AR"},
    "72335" : { "city": "Forrest City", "state":"AR"},
    "72424" : { "city": "Datto", "state":"AR"},
    "72432" : { "city": "Harrisburg", "state":"AR"},
    "72501" : { "city": "Batesville", "state":"AR"},
    "72526" : { "city": "Cushman", "state":"AR"},
    "72546" : { "city": "Ida", "state":"AR"},
    "72680" : { "city": "Timbo", "state":"AR"},
    "72729" : { "city": "Evansville", "state":"AR"},
    "72764" : { "city": "Springdale", "state":"AR"},
    "72846" : { "city": "Lamar", "state":"AR"},
    "72935" : { "city": "Dyer", "state":"AR"},
    "72940" : { "city": "Huntington", "state":"AR"},
    "72944" : { "city": "Mansfield", "state":"AR"},
    "72947" : { "city": "Mulberry", "state":"AR"},
    "71747" : { "city": "Huttig", "state":"AR"},
    "71853" : { "city": "Ogden", "state":"AR"},
    "71861" : { "city": "Taylor", "state":"AR"},
    "72021" : { "city": "Brinkley", "state":"AR"},
    "72079" : { "city": "Jefferson", "state":"AR"},
    "72122" : { "city": "Paron", "state":"AR"},
    "72135" : { "city": "Roland", "state":"AR"},
    "72140" : { "city": "Saint Charles", "state":"AR"},
    "72150" : { "city": "Sheridan", "state":"AR"},
    "72321" : { "city": "Burdette", "state":"AR"},
    "72333" : { "city": "Elaine", "state":"AR"},
    "72412" : { "city": "Beech Grove", "state":"AR"},
    "72527" : { "city": "Desha", "state":"AR"},
    "72617" : { "city": "Big Flat", "state":"AR"},
    "72619" : { "city": "Bull Shoals", "state":"AR"},
    "72648" : { "city": "Marble Falls", "state":"AR"},
    "72677" : { "city": "Summit", "state":"AR"},
    "72827" : { "city": "Bluffton", "state":"AR"},
    "71640" : { "city": "Eudora", "state":"AR"},
    "71832" : { "city": "De Queen", "state":"AR"},
    "72024" : { "city": "Carlisle", "state":"AR"},
    "72068" : { "city": "Higginson", "state":"AR"},
    "72134" : { "city": "Roe", "state":"AR"},
    "72366" : { "city": "Marvell", "state":"AR"},
    "72386" : { "city": "Tyronza", "state":"AR"},
    "72454" : { "city": "Piggott", "state":"AR"},
    "72467" : { "city": "State University", "state":"AR"},
    "72718" : { "city": "Cave Springs", "state":"AR"},
    "72933" : { "city": "Charleston", "state":"AR"},
    "72938" : { "city": "Hartford", "state":"AR"},
    "71659" : { "city": "Moscow", "state":"AR"},
    "71676" : { "city": "Wilmot", "state":"AR"},
    "71801" : { "city": "Hope", "state":"AR"},
    "71833" : { "city": "Dierks", "state":"AR"},
    "72005" : { "city": "Amagon", "state":"AR"},
    "72086" : { "city": "Lonoke", "state":"AR"},
    "72106" : { "city": "Mayflower", "state":"AR"},
    "72117" : { "city": "North Little Rock", "state":"AR"},
    "72459" : { "city": "Ravenden", "state":"AR"},
    "72573" : { "city": "Sage", "state":"AR"},
    "72635" : { "city": "Gassville", "state":"AR"},
    "72663" : { "city": "Onia", "state":"AR"},
    "72752" : { "city": "Pettigrew", "state":"AR"},
    "72826" : { "city": "Blue Mountain", "state":"AR"},
    "72847" : { "city": "London", "state":"AR"},
    "72941" : { "city": "Lavaca", "state":"AR"},
    "72958" : { "city": "Waldron", "state":"AR"},
    "71665" : { "city": "Rison", "state":"AR"},
    "71730" : { "city": "El Dorado", "state":"AR"},
    "71758" : { "city": "Mount Holly", "state":"AR"},
    "71921" : { "city": "Amity", "state":"AR"},
    "71949" : { "city": "Jessieville", "state":"AR"},
    "71965" : { "city": "Pencil Bluff", "state":"AR"},
    "72007" : { "city": "Austin", "state":"AR"},
    "72012" : { "city": "Beebe", "state":"AR"},
    "72020" : { "city": "Bradford", "state":"AR"},
    "72113" : { "city": "Maumelle", "state":"AR"},
    "72212" : { "city": "Little Rock", "state":"AR"},
    "72223" : { "city": "Little Rock", "state":"AR"},
    "72341" : { "city": "Haynes", "state":"AR"},
    "72414" : { "city": "Black Oak", "state":"AR"},
    "72441" : { "city": "McDougal", "state":"AR"},
    "72473" : { "city": "Tuckerman", "state":"AR"},
    "72553" : { "city": "Magness", "state":"AR"},
    "72572" : { "city": "Saffell", "state":"AR"},
    "72685" : { "city": "Western Grove", "state":"AR"},
    "72715" : { "city": "Bella Vista", "state":"AR"},
    "72761" : { "city": "Siloam Springs", "state":"AR"},
    "72852" : { "city": "Oark", "state":"AR"},
    "72854" : { "city": "Ozone", "state":"AR"},
    "71655" : { "city": "Monticello", "state":"AR"},
    "71711" : { "city": "Camden", "state":"AR"},
    "71744" : { "city": "Hampton", "state":"AR"},
    "71766" : { "city": "Thornton", "state":"AR"},
    "71922" : { "city": "Antoine", "state":"AR"},
    "71943" : { "city": "Glenwood", "state":"AR"},
    "72065" : { "city": "Hensley", "state":"AR"},
    "72142" : { "city": "Scott", "state":"AR"},
    "72168" : { "city": "Tucker", "state":"AR"},
    "72202" : { "city": "Little Rock", "state":"AR"},
    "72204" : { "city": "Little Rock", "state":"AR"},
    "72301" : { "city": "West Memphis", "state":"AR"},
    "72350" : { "city": "Joiner", "state":"AR"},
    "72370" : { "city": "Osceola", "state":"AR"},
    "72425" : { "city": "Delaplaine", "state":"AR"},
    "72443" : { "city": "Marmaduke", "state":"AR"},
    "72449" : { "city": "O'Kean", "state":"AR"},
    "72450" : { "city": "Paragould", "state":"AR"},
    "72540" : { "city": "Guion", "state":"AR"},
    "72629" : { "city": "Dennard", "state":"AR"},
    "72640" : { "city": "Hasty", "state":"AR"},
    "72736" : { "city": "Gravette", "state":"AR"},
    "72768" : { "city": "Sulphur Springs", "state":"AR"},
    "72774" : { "city": "West Fork", "state":"AR"},
    "72945" : { "city": "Midland", "state":"AR"},
    "71651" : { "city": "Jersey", "state":"AR"},
    "71838" : { "city": "Fulton", "state":"AR"},
    "72011" : { "city": "Bauxite", "state":"AR"},
    "72081" : { "city": "Judsonia", "state":"AR"},
    "72141" : { "city": "Scotland", "state":"AR"},
    "72152" : { "city": "Sherrill", "state":"AR"},
    "72176" : { "city": "Ward", "state":"AR"},
    "72211" : { "city": "Little Rock", "state":"AR"},
    "72373" : { "city": "Parkin", "state":"AR"},
    "72517" : { "city": "Brockwell", "state":"AR"},
    "72521" : { "city": "Cave City", "state":"AR"},
    "72536" : { "city": "Franklin", "state":"AR"},
    "72554" : { "city": "Mammoth Spring", "state":"AR"},
    "72568" : { "city": "Pleasant Plains", "state":"AR"},
    "72581" : { "city": "Tumbling Shoals", "state":"AR"},
    "72583" : { "city": "Viola", "state":"AR"},
    "72584" : { "city": "Violet Hill", "state":"AR"},
    "72651" : { "city": "Midway", "state":"AR"},
    "72653" : { "city": "Mountain Home", "state":"AR"},
    "72776" : { "city": "Witter", "state":"AR"},
    "72823" : { "city": "Atkins", "state":"AR"},
    "72857" : { "city": "Plainview", "state":"AR"},
    "72865" : { "city": "Subiaco", "state":"AR"},
    "72903" : { "city": "Fort Smith", "state":"AR"},
    "72908" : { "city": "Fort Smith", "state":"AR"},
    "72930" : { "city": "Cecil", "state":"AR"},
    "72956" : { "city": "Van Buren", "state":"AR"},
    "71601" : { "city": "Pine Bluff", "state":"AR"},
    "71675" : { "city": "Wilmar", "state":"AR"},
    "71677" : { "city": "Winchester", "state":"AR"},
    "71953" : { "city": "Mena", "state":"AR"},
    "72014" : { "city": "Beedeville", "state":"AR"},
    "72070" : { "city": "Houston", "state":"AR"},
    "72087" : { "city": "Lonsdale", "state":"AR"},
    "72119" : { "city": "North Little Rock", "state":"AR"},
    "72374" : { "city": "Poplar Grove", "state":"AR"},
    "72422" : { "city": "Corning", "state":"AR"},
    "72560" : { "city": "Mountain View", "state":"AR"},
    "72641" : { "city": "Jasper", "state":"AR"},
    "72742" : { "city": "Kingston", "state":"AR"},
    "72773" : { "city": "Wesley", "state":"AR"},
    "72856" : { "city": "Pelsor", "state":"AR"},
    "72904" : { "city": "Fort Smith", "state":"AR"},
    "71602" : { "city": "White Hall", "state":"AR"},
    "71740" : { "city": "Emerson", "state":"AR"},
    "71745" : { "city": "Harrell", "state":"AR"},
    "71858" : { "city": "Rosston", "state":"AR"},
    "71937" : { "city": "Cove", "state":"AR"},
    "71999" : { "city": "Arkadelphia", "state":"AR"},
    "72030" : { "city": "Cleveland", "state":"AR"},
    "72044" : { "city": "Edgemont", "state":"AR"},
    "72130" : { "city": "Prim", "state":"AR"},
    "72169" : { "city": "Tupelo", "state":"AR"},
    "72322" : { "city": "Caldwell", "state":"AR"},
    "72415" : { "city": "Black Rock", "state":"AR"},
    "72416" : { "city": "Bono", "state":"AR"},
    "72429" : { "city": "Fisher", "state":"AR"},
    "72437" : { "city": "Lake City", "state":"AR"},
    "72532" : { "city": "Evening Shade", "state":"AR"},
    "72567" : { "city": "Pleasant Grove", "state":"AR"},
    "72714" : { "city": "Bella Vista", "state":"AR"},
    "72738" : { "city": "Hindsville", "state":"AR"},
    "72756" : { "city": "Rogers", "state":"AR"},
    "72801" : { "city": "Russellville", "state":"AR"},
    "72838" : { "city": "Gravelly", "state":"AR"},
    "72943" : { "city": "Magazine", "state":"AR"},
    "71722" : { "city": "Bluff City", "state":"AR"},
    "72006" : { "city": "Augusta", "state":"AR"},
    "72036" : { "city": "Cotton Plant", "state":"AR"},
    "72061" : { "city": "Guy", "state":"AR"},
    "72080" : { "city": "Jerusalem", "state":"AR"},
    "72088" : { "city": "Fairfield Bay", "state":"AR"},
    "72327" : { "city": "Crawfordsville", "state":"AR"},
    "72367" : { "city": "Mellwood", "state":"AR"},
    "72368" : { "city": "Moro", "state":"AR"},
    "72379" : { "city": "Snow Lake", "state":"AR"},
    "72413" : { "city": "Biggers", "state":"AR"},
    "72419" : { "city": "Caraway", "state":"AR"},
    "72431" : { "city": "Grubbs", "state":"AR"},
    "72523" : { "city": "Concord", "state":"AR"},
    "72529" : { "city": "Cherokee Village", "state":"AR"},
    "72576" : { "city": "Salem", "state":"AR"},
    "72578" : { "city": "Sturkie", "state":"AR"},
    "72579" : { "city": "Sulphur Rock", "state":"AR"},
    "72611" : { "city": "Alpena", "state":"AR"},
    "72644" : { "city": "Lead Hill", "state":"AR"},
    "72834" : { "city": "Dardanelle", "state":"AR"},
    "72916" : { "city": "Fort Smith", "state":"AR"},
    "72951" : { "city": "Ratcliff", "state":"AR"},
    "71638" : { "city": "Dermott", "state":"AR"},
    "71958" : { "city": "Murfreesboro", "state":"AR"},
    "71969" : { "city": "Sims", "state":"AR"},
    "72025" : { "city": "Casa", "state":"AR"},
    "72099" : { "city": "Little Rock Air Force Base", "state":"AR"},
    "72149" : { "city": "Searcy", "state":"AR"},
    "72326" : { "city": "Colt", "state":"AR"},
    "72355" : { "city": "Lexa", "state":"AR"},
    "72372" : { "city": "Palestine", "state":"AR"},
    "72421" : { "city": "Cash", "state":"AR"},
    "72430" : { "city": "Greenway", "state":"AR"},
    "72458" : { "city": "Powhatan", "state":"AR"},
    "72476" : { "city": "Walnut Ridge", "state":"AR"},
    "72555" : { "city": "Marcella", "state":"AR"},
    "72561" : { "city": "Mount Pleasant", "state":"AR"},
    "72566" : { "city": "Pineville", "state":"AR"},
    "72828" : { "city": "Briggsville", "state":"AR"},
    "72955" : { "city": "Uniontown", "state":"AR"},
    "72959" : { "city": "Winslow", "state":"AR"},
    "71851" : { "city": "Mineral Springs", "state":"AR"},
    "71941" : { "city": "Donaldson", "state":"AR"},
    "72013" : { "city": "Bee Branch", "state":"AR"},
    "72027" : { "city": "Center Ridge", "state":"AR"},
    "72039" : { "city": "Damascus", "state":"AR"},
    "72058" : { "city": "Greenbrier", "state":"AR"},
    "72060" : { "city": "Griffithville", "state":"AR"},
    "72102" : { "city": "McRae", "state":"AR"},
    "72125" : { "city": "Perry", "state":"AR"},
    "72156" : { "city": "Solgohachia", "state":"AR"},
    "72205" : { "city": "Little Rock", "state":"AR"},
    "72347" : { "city": "Hickory Ridge", "state":"AR"},
    "72359" : { "city": "Madison", "state":"AR"},
    "72376" : { "city": "Proctor", "state":"AR"},
    "72383" : { "city": "Turner", "state":"AR"},
    "72626" : { "city": "Cotter", "state":"AR"},
    "72660" : { "city": "Oak Grove", "state":"AR"},
    "72727" : { "city": "Elkins", "state":"AR"},
    "72740" : { "city": "Huntsville", "state":"AR"},
    "72843" : { "city": "Hector", "state":"AR"},
    "72921" : { "city": "Alma", "state":"AR"},
    "71631" : { "city": "Banks", "state":"AR"},
    "71652" : { "city": "Kingsland", "state":"AR"},
    "71663" : { "city": "Portland", "state":"AR"},
    "71742" : { "city": "Fordyce", "state":"AR"},
    "71743" : { "city": "Gurdon", "state":"AR"},
    "71762" : { "city": "Smackover", "state":"AR"},
    "71835" : { "city": "Emmet", "state":"AR"},
    "71841" : { "city": "Gillham", "state":"AR"},
    "72034" : { "city": "Conway", "state":"AR"},
    "72074" : { "city": "Hunter", "state":"AR"},
    "72083" : { "city": "Keo", "state":"AR"},
    "72339" : { "city": "Gilmore", "state":"AR"},
    "72353" : { "city": "Lambrook", "state":"AR"},
    "72404" : { "city": "Jonesboro", "state":"AR"},
    "72520" : { "city": "Camp", "state":"AR"},
    "72631" : { "city": "Eureka Springs", "state":"AR"},
    "72675" : { "city": "Saint Joe", "state":"AR"},
    "72679" : { "city": "Tilly", "state":"AR"},
    "72837" : { "city": "Dover", "state":"AR"},
    "72845" : { "city": "Knoxville", "state":"AR"},
    "72860" : { "city": "Rover", "state":"AR"},
    "72948" : { "city": "Natural Dam", "state":"AR"},
    "71639" : { "city": "Dumas", "state":"AR"},
    "71862" : { "city": "Washington", "state":"AR"},
    "71960" : { "city": "Norman", "state":"AR"},
    "72041" : { "city": "De Valls Bluff", "state":"AR"},
    "72069" : { "city": "Holly Grove", "state":"AR"},
    "72085" : { "city": "Letona", "state":"AR"},
    "72112" : { "city": "Newport", "state":"AR"},
    "72116" : { "city": "North Little Rock", "state":"AR"},
    "72127" : { "city": "Plumerville", "state":"AR"},
    "72207" : { "city": "Little Rock", "state":"AR"},
    "72340" : { "city": "Goodwin", "state":"AR"},
    "72348" : { "city": "Hughes", "state":"AR"},
    "72354" : { "city": "Lepanto", "state":"AR"},
    "72444" : { "city": "Maynard", "state":"AR"},
    "72533" : { "city": "Fifty Six", "state":"AR"},
    "72564" : { "city": "Oil Trough", "state":"AR"},
    "72585" : { "city": "Wideman", "state":"AR"},
    "72863" : { "city": "Scranton", "state":"AR"},
    "72937" : { "city": "Hackett", "state":"AR"},
    "71764" : { "city": "Stephens", "state":"AR"},
    "71765" : { "city": "Strong", "state":"AR"},
    "71836" : { "city": "Foreman", "state":"AR"},
    "71837" : { "city": "Fouke", "state":"AR"},
    "71933" : { "city": "Bonnerdale", "state":"AR"},
    "71935" : { "city": "Caddo Gap", "state":"AR"},
    "72001" : { "city": "Adona", "state":"AR"},
    "72004" : { "city": "Altheimer", "state":"AR"},
    "72045" : { "city": "El Paso", "state":"AR"},
    "72067" : { "city": "Higden", "state":"AR"},
    "72136" : { "city": "Romance", "state":"AR"},
    "72181" : { "city": "Wooster", "state":"AR"},
    "72358" : { "city": "Luxora", "state":"AR"},
    "72460" : { "city": "Ravenden Springs", "state":"AR"},
    "72528" : { "city": "Dolph", "state":"AR"},
    "72562" : { "city": "Newark", "state":"AR"},
    "72634" : { "city": "Flippin", "state":"AR"},
    "72639" : { "city": "Harriet", "state":"AR"},
    "72650" : { "city": "Marshall", "state":"AR"},
    "72704" : { "city": "Fayetteville", "state":"AR"},
    "72722" : { "city": "Decatur", "state":"AR"},
    "72832" : { "city": "Coal Hill", "state":"AR"},
    "72901" : { "city": "Fort Smith", "state":"AR"},
    "72932" : { "city": "Cedarville", "state":"AR"},
    "72950" : { "city": "Parks", "state":"AR"},
    "71635" : { "city": "Crossett", "state":"AR"},
    "71654" : { "city": "McGehee", "state":"AR"},
    "71770" : { "city": "Waldo", "state":"AR"},
    "72017" : { "city": "Biscoe", "state":"AR"},
    "72032" : { "city": "Conway", "state":"AR"},
    "72121" : { "city": "Pangburn", "state":"AR"},
    "72123" : { "city": "Patterson", "state":"AR"},
    "72153" : { "city": "Shirley", "state":"AR"},
    "72342" : { "city": "Helena", "state":"AR"},
    "72377" : { "city": "Rivervale", "state":"AR"},
    "72440" : { "city": "Lynn", "state":"AR"},
    "72513" : { "city": "Ash Flat", "state":"AR"},
    "72519" : { "city": "Calico Rock", "state":"AR"},
    "72531" : { "city": "Elizabeth", "state":"AR"},
    "72670" : { "city": "Ponca", "state":"AR"},
    "72701" : { "city": "Fayetteville", "state":"AR"},
    "72730" : { "city": "Farmington", "state":"AR"},
    "72747" : { "city": "Maysville", "state":"AR"},
    "72833" : { "city": "Danville", "state":"AR"},
    "72839" : { "city": "Hagarville", "state":"AR"},
    "71603" : { "city": "Pine Bluff", "state":"AR"},
    "71674" : { "city": "Watson", "state":"AR"},
    "71725" : { "city": "Carthage", "state":"AR"},
    "71859" : { "city": "Saratoga", "state":"AR"},
    "71860" : { "city": "Stamps", "state":"AR"},
    "71923" : { "city": "Arkadelphia", "state":"AR"},
    "71952" : { "city": "Langley", "state":"AR"},
    "72072" : { "city": "Humnoke", "state":"AR"},
    "72104" : { "city": "Malvern", "state":"AR"},
    "72107" : { "city": "Menifee", "state":"AR"},
    "72346" : { "city": "Heth", "state":"AR"},
    "72384" : { "city": "Turrell", "state":"AR"},
    "72455" : { "city": "Pocahontas", "state":"AR"},
    "72462" : { "city": "Reyno", "state":"AR"},
    "72544" : { "city": "Henderson", "state":"AR"},
    "72601" : { "city": "Harrison", "state":"AR"},
    "72623" : { "city": "Clarkridge", "state":"AR"},
    "72633" : { "city": "Everton", "state":"AR"},
    "72636" : { "city": "Gilbert", "state":"AR"},
    "72661" : { "city": "Oakland", "state":"AR"},
    "72769" : { "city": "Summers", "state":"AR"},
    "71662" : { "city": "Pickens", "state":"AR"},
    "71940" : { "city": "Delight", "state":"AR"},
    "72026" : { "city": "Casscoe", "state":"AR"},
    "72038" : { "city": "Crocketts Bluff", "state":"AR"},
    "72111" : { "city": "Mount Vernon", "state":"AR"},
    "72114" : { "city": "North Little Rock", "state":"AR"},
    "72209" : { "city": "Little Rock", "state":"AR"},
    "72210" : { "city": "Little Rock", "state":"AR"},
    "72311" : { "city": "Aubrey", "state":"AR"},
    "72324" : { "city": "Cherry Valley", "state":"AR"},
    "72390" : { "city": "West Helena", "state":"AR"},
    "72469" : { "city": "Strawberry", "state":"AR"},
    "72538" : { "city": "Gepp", "state":"AR"},
    "72577" : { "city": "Sidney", "state":"AR"},
    "72624" : { "city": "Compton", "state":"AR"},
    "72638" : { "city": "Green Forest", "state":"AR"},
    "72658" : { "city": "Norfork", "state":"AR"},
    "72739" : { "city": "Hiwasse", "state":"AR"},
    "72751" : { "city": "Pea Ridge", "state":"AR"},
    "72824" : { "city": "Belleville", "state":"AR"},
    "72851" : { "city": "New Blaine", "state":"AR"},
    "72923" : { "city": "Barling", "state":"AR"},
    "71642" : { "city": "Fountain Hill", "state":"AR"},
    "71646" : { "city": "Hamburg", "state":"AR"},
    "71751" : { "city": "Louann", "state":"AR"},
    "71852" : { "city": "Nashville", "state":"AR"},
    "71865" : { "city": "Wilton", "state":"AR"},
    "72015" : { "city": "Benton", "state":"AR"},
    "72023" : { "city": "Cabot", "state":"AR"},
    "72046" : { "city": "England", "state":"AR"},
    "72143" : { "city": "Searcy", "state":"AR"},
    "72165" : { "city": "Thida", "state":"AR"},
    "72351" : { "city": "Keiser", "state":"AR"},
    "72395" : { "city": "Wilson", "state":"AR"},
    "72456" : { "city": "Pollard", "state":"AR"},
    "72470" : { "city": "Success", "state":"AR"},
    "72478" : { "city": "Warm Springs", "state":"AR"},
    "72482" : { "city": "Williford", "state":"AR"},
    "72515" : { "city": "Bexar", "state":"AR"},
    "72534" : { "city": "Floral", "state":"AR"},
    "72616" : { "city": "Berryville", "state":"AR"},
    "72632" : { "city": "Eureka Springs", "state":"AR"},
    "72655" : { "city": "Mount Judea", "state":"AR"},
    "72712" : { "city": "Bentonville", "state":"AR"},
    "72840" : { "city": "Hartman", "state":"AR"},
    "72936" : { "city": "Greenwood", "state":"AR"},
    "71653" : { "city": "Lake Village", "state":"AR"},
    "71724" : { "city": "Calion", "state":"AR"},
    "71956" : { "city": "Mountain Pine", "state":"AR"},
    "71959" : { "city": "Newhope", "state":"AR"},
    "71964" : { "city": "Pearcy", "state":"AR"},
    "71973" : { "city": "Wickes", "state":"AR"},
    "72042" : { "city": "De Witt", "state":"AR"},
    "72139" : { "city": "Russell", "state":"AR"},
    "72179" : { "city": "Wilburn", "state":"AR"},
    "72360" : { "city": "Marianna", "state":"AR"},
    "72389" : { "city": "Wabash", "state":"AR"},
    "72401" : { "city": "Jonesboro", "state":"AR"},
    "72442" : { "city": "Manila", "state":"AR"},
    "72457" : { "city": "Portia", "state":"AR"},
    "72539" : { "city": "Glencoe", "state":"AR"},
    "72542" : { "city": "Hardy", "state":"AR"},
    "72571" : { "city": "Rosie", "state":"AR"},
    "72666" : { "city": "Parthenon", "state":"AR"},
    "72683" : { "city": "Vendor", "state":"AR"},
    "72717" : { "city": "Canehill", "state":"AR"},
    "72721" : { "city": "Combs", "state":"AR"},
    "72744" : { "city": "Lincoln", "state":"AR"},
    "72749" : { "city": "Morrow", "state":"AR"},
    "72830" : { "city": "Clarksville", "state":"AR"},
    "71666" : { "city": "Rohwer", "state":"AR"},
    "71701" : { "city": "Camden", "state":"AR"},
    "71720" : { "city": "Bearden", "state":"AR"},
    "71834" : { "city": "Doddridge", "state":"AR"},
    "71901" : { "city": "Hot Springs National Park", "state":"AR"},
    "71913" : { "city": "Hot Springs National Park", "state":"AR"},
    "71961" : { "city": "Oden", "state":"AR"},
    "71968" : { "city": "Royal", "state":"AR"},
    "71998" : { "city": "Arkadelphia", "state":"AR"},
    "72019" : { "city": "Benton", "state":"AR"},
    "72031" : { "city": "Clinton", "state":"AR"},
    "72048" : { "city": "Ethel", "state":"AR"},
    "72120" : { "city": "Sherwood", "state":"AR"},
    "72129" : { "city": "Prattsville", "state":"AR"},
    "72206" : { "city": "Little Rock", "state":"AR"},
    "72396" : { "city": "Wynne", "state":"AR"},
    "72417" : { "city": "Brookland", "state":"AR"},
    "72434" : { "city": "Imboden", "state":"AR"},
    "72512" : { "city": "Horseshoe Bend", "state":"AR"},
    "72682" : { "city": "Valley Springs", "state":"AR"},
    "72686" : { "city": "Witts Springs", "state":"AR"},
    "72858" : { "city": "Pottsville", "state":"AR"},
    "72926" : { "city": "Boles", "state":"AR"},
    "72934" : { "city": "Chester", "state":"AR"}
};

interface CSZObject {
    // zip: string;
    city: string;
    state: string;
}


interface IAddressFieldsProps {
    control: any; // Replace 'any' with the proper type for your 'control'
    setValue: any; // Replace 'any' with proper types
}

export const AddressFieldsZipAutofill: React.FC<IAddressFieldsProps> = ({control, setValue}) => {
    const handleZipChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const zip = event.target.value;
        const foundCsz = csz[zip]
        if (foundCsz) {
            setValue('city', csz[zip].city);
            setValue('state_text', csz[zip].state);
        }
    };

    const stateOptions = getStateOptions();

    return (
        <>
            <Controller
                name="address_line1"
                control={control}
                render={({field}) => (
                    <TextField {...field} label="Address Line 1" size="small" fullWidth margin="normal"/>
                )}
            />
            <Controller
                name="address_line2"
                control={control}
                render={({field}) => (
                    <TextField
                        {...field}
                        label="Address Line 2"
                        size="small"
                        fullWidth
                        margin="normal"
                        sx={{pb: 2}}
                    />
                )}
            />
            <Stack direction={"row"} spacing={2}>
                <Controller
                    name="zip"
                    control={control}
                    render={({field}) => (
                        <TextField
                            {...field}
                            label="Postal Code"
                            size="small"
                            fullWidth
                            margin="normal"
                            onChange={(event) => {
                                console.log("onchange asdf")
                                field.onChange(event); // Call the default react-hook-form onChange handler
                                handleZipChange(event); // Then call your custom handler
                            }}
                        />
                    )}
                />
                <FormControl
                    size="small"
                    fullWidth
                    sx={{pb: 2}}
                >
                    <InputLabel>State</InputLabel>
                    <Controller
                        name="state_text"
                        control={control}
                        render={({
                                     field: {onChange, value},
                                 }) => (
                            <Select
                                label="State"
                                onChange={onChange}
                                value={value}
                            >
                                {stateOptions && stateOptions.length > 0 && [stateOptions.map(stateOption => (
                                    <MenuItem value={stateOption.value}>{stateOption.label}</MenuItem>
                                ))]}
                            </Select>

                        )}
                    />
                </FormControl>
            </Stack>
            <Controller
                name="city"
                control={control}
                render={({field}) => (
                    <TextField {...field} label="City" size="small" fullWidth margin="normal"/>
                )}
            />


        </>
    );
};

