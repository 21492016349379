import {FormInputOptionPropsStringString, FormInputProps} from "../FormInputProps.tsx";
import {toCamelCaseValidHtmlId} from "../../../helpers/utility.tsx";
import {useEffect, useState} from "react";
import {Controller} from "react-hook-form";
import {Autocomplete, TextField} from "@mui/material";
import {getTeamMembers} from "../../../api/jobNimbusFunctions.ts";

export function AssignedToAutocomplete({control, label, name, accountInfo}: FormInputProps) {
    let nameNormalized = toCamelCaseValidHtmlId(label);

    // const [inputValue, setInputValue] = useState("");
    const [teamMembers, setTeamMembers] = useState<FormInputOptionPropsStringString[]>([]);

    //Start loading tags immediately
    useEffect(() => {
        if (!accountInfo) {
            return;
        }
        let teamMembersTemp = getTeamMembers(accountInfo);
        setTeamMembers(teamMembersTemp);

    }, []);


    return (
        <Controller
            control={control}
            //@ts-expect-error
            name={name ? name : nameNormalized}
            render={({ field: { onChange, value } }) => (
                <Autocomplete
                    size="small"
                    sx={{ pb: 2 }}
                    multiple
                    filterSelectedOptions
                    onChange={(_, selectedOptions) => {
                        onChange(selectedOptions);
                    }}
                    options={teamMembers}
                    getOptionLabel={(teamMember: FormInputOptionPropsStringString | string) =>
                        typeof teamMember === 'string' ? teamMember : teamMember.label}
                    //@ts-expect-error
                    value={Array.isArray(value) ? value : value === undefined ? [] : [value]}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}

                        />
                    )}
                />
            )}
        />
    );
}
