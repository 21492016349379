import React, {createContext, ReactNode, useContext} from "react";

interface ColorContextProps {
    darkBlue: string;
    lightBlue: string;
    darkBrown: string;
    lightBrown: string;
    errorRed: string;
}
const ColorContext = createContext<ColorContextProps | undefined>(undefined);
export const ColorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const colors = {
        darkBlue: '#2D62BE',
        lightBlue: '#4F8CF4',
        darkBrown: '#5D4018',
        lightBrown: '#9A7D55',
        errorRed: '#DF5343'
    };

    return <ColorContext.Provider value={colors}>{children}</ColorContext.Provider>;
};
export const useColors = () => {
    const context = useContext(ColorContext);
    if (!context) {
        throw new Error('useColors must be used within a ColorProvider');
    }
    return context;
};