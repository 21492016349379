import {FormInputProps} from "../../FormInputProps.tsx";
import {Controller} from "react-hook-form";
import {toCamelCaseValidHtmlId} from "../../../../helpers/utility.tsx";
import {TextField} from "@mui/material";
import React, {useEffect, useRef} from "react";

interface BasicTextFieldProps extends FormInputProps {
    updateDuplicateCheckData?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, name: string) => void;
    updateParentStateOnChange?: React.Dispatch<React.SetStateAction<string>>;
    assignFocus?: boolean;
    pbOverride?: string;
}


export function BasicTextField({ name, control, label, multiline, required, placeholder, updateDuplicateCheckData, assignFocus,
                                   updateParentStateOnChange, pbOverride}: BasicTextFieldProps) {

    //Assign focus when opening CallTakingDialog START
    const textFieldRef = useRef<HTMLInputElement | null>(null);
    if (assignFocus) {
        useEffect(() => {
            // Set focus when the component mounts
            if (textFieldRef.current) {
                textFieldRef.current.focus();
            }
        }, []);
    }
    //Assign focus when opening CallTakingDialog END


    return (
        <Controller
            // @ts-expect-error
            name={name ? name : toCamelCaseValidHtmlId(label)}
            control={control}
            rules={{ required: required }}
            render={({ field }) => (
                <TextField
                    {...field}
                    label={label}
                    multiline={multiline}
                    placeholder={placeholder ? placeholder : undefined}
                    size="small"
                    sx={{
                        pb: pbOverride ? pbOverride : 2,
                        width: "100%" }}
                    inputRef={assignFocus ? textFieldRef : null}
                    onChange={(event) => {
                        if (updateParentStateOnChange) {
                            updateParentStateOnChange(event.target.value);
                        }
                        field.onChange(event);
                    }}
                    onBlur={(e) => updateDuplicateCheckData?.(e, name as string)}
                />
            )}
        />
    );
}
