import axios from 'axios';
import React, {ReactNode, useEffect} from 'react';
import {useAuth0} from '@auth0/auth0-react';

let isRefreshing = false;
let refreshTokenPromise: Promise<any> | null = null;

const instance = axios.create();

interface AxiosWithAuthProviderProps {
    children: ReactNode;
}

const AxiosWithAuthProvider: React.FC<AxiosWithAuthProviderProps> = ({children}) => {
    const { logout } = useAuth0();


    useEffect(() => {
        // Add a request interceptor
        const requestInterceptor = instance.interceptors.request.use(
            async (req) => {
                const accessToken = localStorage.getItem('accessToken');
                if (accessToken) {
                    req.headers.Authorization = `Bearer ${accessToken}`;
                }
                return req;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        // Add a response interceptor
        const responseInterceptor = instance.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                const originalRequest = error.config;
                const status = error.response ? error.response.status : null;

                if (status === 401 && !originalRequest._retry) {
                    if (!isRefreshing) {
                        isRefreshing = true;
                        // logoutBackend()
                        logout({ // Logout with redirect to auth0 login page
                            logoutParams: {
                                returnTo: window.location.origin
                            }
                        });
                        // refreshTokenPromise = refreshToken();
                    }

                    await refreshTokenPromise;

                    originalRequest._retry = true;
                    return axios(originalRequest);
                }

                return Promise.reject(error);
            }
        );

        return () => {
            // Clean up interceptors when component unmounts
            instance.interceptors.request.eject(requestInterceptor);
            instance.interceptors.response.eject(responseInterceptor);
        };
    }, [logout]);

    return children;
};

export default instance;
export { AxiosWithAuthProvider }