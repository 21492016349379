import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import {BrowserRouter} from "react-router-dom";
import {Auth0ProviderWithNavigate} from "./providers/Auth0ProviderWithNavigate.tsx";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {ColorProvider} from "./hooks/useColors";
import {AxiosWithAuthProvider} from "./providers/AxiosWithAuthProvider";


// Create a custom theme with modified spacing
const theme = createTheme({
    components: {
        // MuiButton: {
        //     styleOverrides: {
        //         root: {
        //             backgroundColor: darkBrown
        //         }
        //     }
        // },
        MuiTextField: {
            styleOverrides: {
                root: {
                    // marginBottom: '1px !important', // Adjust the height as needed
                    size: 'small'
                },
            },
        },
    },
});


ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <BrowserRouter>
            <Auth0ProviderWithNavigate>
                <AxiosWithAuthProvider>
                    <ThemeProvider theme={theme}>
                        <ColorProvider>
                            {/*<LastContactProvider>*/}
                                <App/>
                            {/*</LastContactProvider>*/}
                        </ColorProvider>
                    </ThemeProvider>
                </AxiosWithAuthProvider>
            </Auth0ProviderWithNavigate>
        </BrowserRouter>
    </React.StrictMode>
)
