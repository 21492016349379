import {BasicSelect} from "./common/BasicSelect.tsx";
import {getContactTypes, getStatuses} from "../../../api/jobNimbusFunctions.ts";
import {FormInputOptionPropsStringString, FormInputProps} from "../FormInputProps.tsx";
import {useEffect, useState} from "react";
import {useWatch} from "react-hook-form";
import {Stack} from "@mui/material";

export function ContactTypeAndStatusSelects({control, accountInfo, setValue, contactTypeName, statusName}: FormInputProps & {contactTypeName: string, statusName: string}) {

    const [contactTypes, setContactTypes] = useState<FormInputOptionPropsStringString[]>([]);
    const [statuses, setStatuses] = useState<FormInputOptionPropsStringString[]>([]);

    //@ts-expect-error
    const watchContactType: string = useWatch({
        //@ts-expect-error
        name: contactTypeName,
        control: control,
        defaultValue: ""
    });

    //@ts-expect-error
    const watchStatus: string = useWatch({
        //@ts-expect-error
        name: statusName,
        control: control,
        defaultValue: ""
    });



    //Initial load
    useEffect(() => {
        if (!accountInfo) {
            return;
        }
        let contactTypesTemp = getContactTypes(accountInfo);
        // console.log(contactTypesTemp)
        setContactTypes(contactTypesTemp)

        //Update statuses on initial load
        if (statuses.length > 0) {
            // console.log("statuses length: " + statuses.length)
            return;
        }
        let statusesTemp = getStatuses(accountInfo, contactTypesTemp[0].value)
        setStatuses(statusesTemp)


    }, [accountInfo]);


    // Update statuses when Contact Type changes
    useEffect(() => {
        if (!accountInfo || watchContactType === "") {
            return
        }
        if (typeof watchContactType === 'number') {
            setValue(contactTypeName, contactTypes[0].value)
        }
        // console.log("watchContactType: " + watchContactType)
        let statusesTemp = getStatuses(accountInfo, watchContactType)
        setStatuses(statusesTemp)

        // console.log(statuses)
    }, [watchContactType]);

    //Wait until state is loaded before setting default option
    useEffect(() => {
        if (contactTypes && contactTypes.length > 0) {
            // console.log(contactTypes[0])
            setValue(contactTypeName, contactTypes[0].value)
        }
    }, [contactTypes]);
    //
    //Wait until state is loaded before setting default option
    useEffect(() => {
        if (statuses && statuses.length > 0 && watchStatus === "") {
            setValue(statusName, statuses[0].value)
        }
    }, [statuses]);

    // useEffect(() => {
    //     console.log("watchStatus",watchStatus)
    // }, [watchStatus]);

    if (!contactTypes) {
        return (<>Loading</>);
    }
    if (contactTypes.length === 0) {
        // console.log("loading")
        return (<>Loading</>);
    }

    // console.log("done loading")
    // console.log(contactTypes)

    return (
        <Stack direction={"row"} spacing={2}>
            <BasicSelect control={control} label={"Contact Type"} name={contactTypeName}
                // defaultValue={contactTypes && contactTypes.length > 0 ? contactTypes[0].value : ""}
                         defaultValue={""}
                         options={contactTypes}/>

            {/*status	The workflow status id for this record.	No	integer*/}
            {/*status_name	The workflow status name for this record.	Yes	text*/}
            <BasicSelect control={control} label={"Status"} name={statusName}
                // defaultValue={statuses && statuses.length > 0 ? statuses[0].value : ""}
                         defaultValue={""}
                         options={statuses}/>

        </Stack>

    )
}