import {FormInputProps} from "../../FormInputProps.tsx";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import {Controller} from "react-hook-form";
import {toCamelCaseValidHtmlId} from "../../../../helpers/utility.tsx";

export function BasicCheckbox({ name, control, label }: FormInputProps) {

    return (
        <FormGroup>
            <FormControlLabel control={
                <Controller
                    render={({ field: { onChange, value } }) => (
                        <Checkbox
                            checked={value as boolean} // Use checked instead of value
                            onChange={onChange}
                        />
                    )}
                    control={control}
                    // @ts-ignore
                    name={name ? name : toCamelCaseValidHtmlId(label)} // Pass name to Controller
                />
            } label={label} sx={{ pb: 2 }} />
        </FormGroup>
    );
}