import {WorkException} from "./WorkExceptions";
import {DatePicker, LocalizationProvider, TimePicker} from "@mui/x-date-pickers";
import {Box, Checkbox, FormControl, FormControlLabel, FormGroup, Stack, TextField} from "@mui/material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {useEffect} from "react";

interface ExceptionFieldsProps {
    exception: WorkException | null;
    setException: React.Dispatch<React.SetStateAction<WorkException | null>>;
    setIsSaveButtonDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function convertExceptionDateTimes(exception: WorkException) {
    const startDateTime = new Date(exception.dateStart);
    const dateStartTime = new Date(exception.startTime);
    if (exception.allDay) {
        startDateTime.setHours(0, 0, 0,0);
    } else {
        startDateTime.setHours(dateStartTime.getHours(), dateStartTime.getMinutes(), dateStartTime.getSeconds(), dateStartTime.getMilliseconds());
    }

    const endDateTime = new Date(exception.dateEnd);
    const dateEndTime = new Date(exception.endTime);
    if (exception.allDay) {
        endDateTime.setHours(23, 59, 59, 999);
    } else {
        endDateTime.setHours(dateEndTime.getHours(), dateEndTime.getMinutes(), dateEndTime.getSeconds(), dateEndTime.getMilliseconds());
    }
    return {startDateTime, endDateTime}
}

export const WorkingExceptionFields: React.FC<ExceptionFieldsProps> = ({exception, setException, setIsSaveButtonDisabled}) => {


    if (!exception || !setException){
        return;
    }


    useEffect(() => {

        const {startDateTime, endDateTime} = convertExceptionDateTimes(exception)
        //If so, enable save button
        if ((!exception.reason || exception.reason.length === 0)
            || startDateTime > endDateTime) {
            setIsSaveButtonDisabled ? setIsSaveButtonDisabled(true) : null
        } else {
            setIsSaveButtonDisabled ? setIsSaveButtonDisabled(false) : null
        }

        //If not, keep it disabled
    }, [exception]);
    return (
        <Box sx={{m: 2}}>
            <Stack direction={"column"}>
                <TextField
                    size={"small"}
                    label="Reason"
                    value={exception.reason}
                    onChange={(e) => setException({...exception, reason: e.target.value})}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size={"small"}
                                checked={exception.allDay}
                                onChange={(e) => {
                                    let exceptionInProgress = {
                                        ...exception,
                                        allDay: e.target.checked,
                                    };

                                    if (e.target.checked) {
                                        exceptionInProgress.startTime = new Date((new Date).setHours(0, 0, 0, 0));
                                        exceptionInProgress.endTime = new Date((new Date).setHours(23, 59, 59, 999));
                                    } else {
                                        exceptionInProgress.startTime = new Date((new Date).setHours(8, 0, 0, 0));
                                        exceptionInProgress.endTime = new Date((new Date).setHours(17, 0, 0, 0));
                                    }
                                    setException(exceptionInProgress);
                                }}
                            />
                        }
                        label="All Day"
                    />
                    <Stack direction={"row"}>
                        <FormGroup sx={{mt: 2}}>
                            <FormControl>
                                <DatePicker
                                    label="Date Start"
                                    value={exception.dateStart}
                                    onChange={(date) => setException({...exception, dateStart: date || new Date()})}
                                    slotProps={{textField: {size: 'small'}}}
                                />
                            </FormControl>
                            {!exception.allDay && (
                                <FormControl>
                                    <TimePicker
                                        label="Start Time"
                                        value={exception.startTime}
                                        onChange={(date) => setException({...exception, startTime: date || new Date()})}
                                        slotProps={{textField: {size: 'small'}}}
                                        sx={{
                                            mt: "10px"
                                        }}
                                    />
                                </FormControl>
                            )}
                        </FormGroup>
                        <FormGroup sx={{m: 2}}>
                            <FormControl>
                                <DatePicker
                                    label="Date End"
                                    value={exception.dateEnd}
                                    onChange={(date) => setException({...exception, dateEnd: date || new Date()})}
                                    slotProps={{textField: {size: 'small'}}}
                                />
                            </FormControl>
                            {!exception.allDay && (
                                <FormControl>
                                    <TimePicker
                                        label="End Time"
                                        value={exception.endTime}
                                        onChange={(date) => setException({...exception, endTime: date || new Date()})}
                                        slotProps={{textField: {size: 'small'}}}
                                        sx={{
                                            mt: "10px"
                                        }}
                                    />
                                </FormControl>
                            )}
                        </FormGroup>
                    </Stack>
                </LocalizationProvider>
            </Stack>
        </Box>
    );
};