import {Grid,} from "@mui/material";
import {ManageEmployees} from "./ManageEmployees";
import {ManagePushEvent} from "./ManagePushEvent";


export function SettingsPageEmployeesTab() {

    return (
        <Grid container spacing={2} p={2}>
            {/* Left side: Employees */}
            <Grid item xs={12} md={6}>
                <ManageEmployees/>
            </Grid>

            {/* Right side: Push Events */}
            <Grid item xs={12} md={6}>
                <ManagePushEvent/>
            </Grid>
        </Grid>
    );
}

