import Grid from "@mui/material/Grid";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import {FirstLastCompanyDisplayNameFields} from "./FirstLastCompanyDisplayNameFields";
import {BasicTextField} from "./field-components/common/BasicTextField";
import {createNewContact, fetchContactByJnid, updateContact} from "../../api/jobNimbusFunctions";
import {ContactTypeAndStatusSelects} from "./field-components/ContactTypeAndStatusSelects";
import {SalesRepSelect} from "./field-components/SalesRepSelect";
import {LeadSourceSelect} from "./field-components/LeadSourceSelect";
import {AssignedToAutocomplete} from "./field-components/AssignedToAutocomplete";
import {SubcontractorsAutocomplete} from "./field-components/SubcontractorsAutocomplete";
import {RelatedContactsAutocomplete} from "./field-components/RelatedContactsAutocomplete";
import {BasicCheckbox} from "./field-components/common/BasicCheckbox";
import Typography from "@mui/material/Typography";
import {NoteSelectAndContent} from "./field-components/NoteSelectAndContent";
import {IFormInput} from "./FormInputProps";
import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
//@ts-expect-error
import {FieldErrors} from "react-hook-form/dist/types/errors";
import {CombinedJobNimbusContactResponse, DuplicateCheckResult, IAccountInfo} from "../../api/jobNimbusInterfaces";
import {Toast} from "@bryntum/scheduler";
import {TOAST_TIMEOUT} from "../../constants";
import {useLastContactStore} from "../../hooks/useLastContactStore";
import {DateOfLossDatePicker} from "./field-components/DateOfLossDatePicker";
import {AddressFieldsZipAutofill} from "./field-components/AddressFieldsZipAutofill";

interface ICallTakingFormProps {
    accountInfo: IAccountInfo | undefined;
    setDuplicateCheckResponseData: React.Dispatch<
        React.SetStateAction<DuplicateCheckResult[] | []>
    >;
    setTableDataIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    performReset: boolean;
    setPerformReset: React.Dispatch<React.SetStateAction<boolean>>;
    setErrorsStoredInParent: React.Dispatch<React.SetStateAction<FieldErrors | null>>;
    performDialogSave: boolean;
    setPerformDialogSave: React.Dispatch<React.SetStateAction<boolean>>;
    performDialogUpdate: boolean;
    setPerformDialogUpdate: React.Dispatch<React.SetStateAction<boolean>>;
    setSubmitErrors: React.Dispatch<React.SetStateAction<string | undefined>>;
    setCallTakingDialogIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsCreatingContact: React.Dispatch<React.SetStateAction<boolean>>;
    setIsUpdatingContact: React.Dispatch<React.SetStateAction<boolean>>;
    relatedContactToAdd: DuplicateCheckResult | null;
    setRelatedContactToAdd: React.Dispatch<React.SetStateAction<DuplicateCheckResult | null>>;
    copyInfoContact: DuplicateCheckResult | null;
    setCopyInfoContact: React.Dispatch<React.SetStateAction<DuplicateCheckResult | null>>;
    skipInitialAttempt: boolean;
    setSkipInitialAttempt: React.Dispatch<React.SetStateAction<boolean>>;
    attemptingToCancelForm: boolean;
    setAttemptingToCancelForm: React.Dispatch<React.SetStateAction<boolean>>;
    jnidToUpdate: string | null;
    setJnidToUpdate: React.Dispatch<React.SetStateAction<string | null>>;
    setCancelForm: React.Dispatch<React.SetStateAction<boolean>>;
}

// interface IAddressFieldsProps extends FormInputProps {
//
// }

// export const AddressFields: React.FC<IAddressFieldsProps> = ({ control, }) => {
//     return (
//         <>
//             <BasicTextField control={control} label={"Address Line 1"} name={"address_line1"}/>
//             <BasicTextField control={control} label={"Address Line 2"} name={"address_line2"}/>
//             <BasicTextField control={control} label={"City"}/>
//             <BasicTextField control={control} label={"Postal Code"} name={"zip"} pbOverride={'0px'}/>
//             <BasicSelect
//                 control={control}
//                 label={"State"}
//                 name={"state_text"}
//                 options={getStateOptions()}
//             />
//
//         </>
//     )
// }


export const CallTakingForm: React.FC<ICallTakingFormProps> = ({
                                                                   accountInfo,
                                                                   setDuplicateCheckResponseData,
                                                                   setTableDataIsLoading,
                                                                   performReset,
                                                                   setPerformReset,
                                                                   setErrorsStoredInParent,
                                                                   performDialogSave,
                                                                   setPerformDialogSave,
                                                                   performDialogUpdate,
                                                                   setPerformDialogUpdate,
                                                                   jnidToUpdate,
                                                                   setJnidToUpdate,
                                                                   setSubmitErrors,
                                                                   setCallTakingDialogIsOpen,
                                                                   setIsCreatingContact,
                                                                   setIsUpdatingContact,
                                                                   relatedContactToAdd,
                                                                   setRelatedContactToAdd,
                                                                   copyInfoContact,
                                                                   setCopyInfoContact,
                                                                   skipInitialAttempt,
                                                                   setSkipInitialAttempt,
                                                                   attemptingToCancelForm,
                                                                   setAttemptingToCancelForm,
                                                                   setCancelForm,
                                                               }) => {

    // const defaultValues: IFormInput = import.meta.env.DEV ? testDefaultValues : prodDefaultValues;
    const defaultValues: IFormInput = prodDefaultValues;
    // const defaultValues: IFormInput = testDefaultValues;

    const methods = useForm<IFormInput>({
        defaultValues: defaultValues,
    });
    const {
        handleSubmit,
        reset,
        control,
        setValue,
        getValues,
        trigger,
        formState: {errors},
    } = methods;


    //Perform reset when triggered from parent component
    useEffect(() => {
        if (performReset) {
            reset();
            setPerformReset(false);
        }
    }, [performReset]);

    //Update eerrorsStoredInParent using react-hook-form errors variable
    useEffect(() => {
        if (errors) {
            setErrorsStoredInParent(errors);
        }
    }, [errors]);

    const lastContactStore = useLastContactStore();

    //Submit form when triggered from parent component
    const onFormSubmitCreate = async (formData: IFormInput) => {
        setIsCreatingContact(true);
        console.log("onSubmit firing - create");
        console.log(formData);

        try {
            // Create a promise for createNewContact
            const createContactPromise: Promise<CombinedJobNimbusContactResponse> = createNewContact(formData, accountInfo);

            // Wait at least a second
            await new Promise((resolve) => setTimeout(resolve, 1000));

            // Now, await the completion of createNewContact
            const response: CombinedJobNimbusContactResponse = await createContactPromise; // If this fails, it will throw an exception

            lastContactStore.setLastContact(response);

            // Success? Tell user
            Toast.show({
                html: `Contact created: ${response.jobNimbusContactResponse.display_name}`,
                timeout: TOAST_TIMEOUT,
            });

            setCallTakingDialogIsOpen(false);
        } catch (error: any) {
            // Handle the error here and update the state
            console.error("Error creating contact:", error);
            setSubmitErrors(
                error?.response?.data || error.message || "An error occurred while creating the contact"
            );
        } finally {
            setIsCreatingContact(false);
        }
    };
    const onFormSubmitUpdate = async (formData: IFormInput) => {
        setIsUpdatingContact(true);
        console.log("onSubmit firing - update");
        console.log(formData);

        if (!jnidToUpdate) {
            console.error("Trying to update contact, however jnid is not available")
            Toast.show({
                html: `Error! Failed to update contact`,
                timeout: TOAST_TIMEOUT,
            });
            setIsUpdatingContact(false);
            return
        }

        try {
            // Create a promise for createNewContact
            const updateContactPromise: Promise<CombinedJobNimbusContactResponse> = updateContact(jnidToUpdate, formData, accountInfo);

            // Wait at least a second
            await new Promise((resolve) => setTimeout(resolve, 1000));

            // Now, await the completion of createNewContact
            const response: CombinedJobNimbusContactResponse = await updateContactPromise; // If this fails, it will throw an exception

            // Success? Tell user
            Toast.show({
                html: `Contact updated: ${response.jobNimbusContactResponse.display_name}`,
                timeout: TOAST_TIMEOUT,
            });
            setJnidToUpdate(null)
            lastContactStore.setLastContact(response);
            setCallTakingDialogIsOpen(false);
        } catch (error: any) {
            // Handle the error here and update the state
            console.error("Error updating contact:", error);
            setSubmitErrors(
                // error.message ||
                error?.response?.data ||
                "An error occurred while updating the contact"
            );
        } finally {
            setIsUpdatingContact(false);
        }
    };

    const onFormSubmitError = async (error: any) => {
        console.log("onError firing");
        console.log(error);
        // await createNewContact(formData);
    };
    useEffect(() => {
        console.log("CallTakingForm - performDialogSave")
        if (performDialogSave) {
            // handleSubmit(onFormSubmit, onFormSubmitError);
            const submitFormCreate = async () => {
                try {
                    await handleSubmit(onFormSubmitCreate, onFormSubmitError)();
                } catch (error) {
                    console.error('Form submission error:', error);
                }
            };

            submitFormCreate();
            setPerformDialogSave(false);
        }
    }, [performDialogSave]);

    useEffect(() => {
        console.log("CallTakingForm - performDialogUpdate")
        if (performDialogUpdate) {
            // handleSubmit(onFormSubmit, onFormSubmitError);
            const submitFormUpdate = async () => {
                try {
                    await handleSubmit(onFormSubmitUpdate, onFormSubmitError)();
                } catch (error) {
                    console.error('Form submission error:', error);
                }
            };

            submitFormUpdate();
            setPerformDialogUpdate(false);
        }
    }, [performDialogUpdate]);

    const updateFormWithContactInfo = async (copyInfoContact: DuplicateCheckResult) => {
        // Update using setValue()
        let newFormValues = {...defaultValues};

        // Define a function to fetch contact by ID
        const fetchContactById = async (id: string) => {
            const contact = await fetchContactByJnid(id);
            return contact?.display_name;
        };

        console.log("copyInfoContact", copyInfoContact)

        // Asynchronously map the properties
        await Promise.all(
            Object.keys(newFormValues).map(async (key) => {
                if (copyInfoContact.hasOwnProperty(key)) {
                    // @ts-ignore
                    let contactPropValue = copyInfoContact[key];
                    if (Array.isArray(contactPropValue) && key !== 'tags') {
                        contactPropValue = await Promise.all(
                            contactPropValue.map(async (value) => {
                                const id = value?.id;
                                let label = value?.name;
                                if (id && !label) {
                                    // Try to fetch contact so we can get a label
                                    label = await fetchContactById(id);
                                }
                                return {
                                    value: id,
                                    label: label,
                                };
                            })
                        );
                    }
                    // @ts-ignore
                    newFormValues[key] = contactPropValue;
                }
            })
        );

        //Hardcode to Note
        newFormValues["note_type"] = "Note"

        // Identify keys in DuplicateCheckResult that do not have matching keys in newFormValues
        // @ts-ignore
        const nonMatchingKeys = Object.keys(copyInfoContact).filter(
            (key) => !newFormValues.hasOwnProperty(key)
        );
        if (nonMatchingKeys) {
            console.log("nonMatchingKeys:", nonMatchingKeys)
        }

        // Update formErrors with message to give a unique displayname
        reset(newFormValues, {
            keepDefaultValues: true,
        });

        // Reset copyInfoContact
        setCopyInfoContact(null);
    };

    // // Watch the form values
    // const formValues = watch();
    //
    // // Log the form values whenever they change
    // useEffect(() => {
    //     console.log('Form Values:', formValues);
    // }, [formValues]);

    //DUPLICATE CHECK copy info START
    useEffect(() => {
        if (copyInfoContact) {
            (async () => {
                await updateFormWithContactInfo(copyInfoContact)
            })()
        }
    }, [copyInfoContact]);
    //DUPLICATE CHECK copy info END

    //ATTEMPTING TO CANCEL FORM start
    const [cancelConfirmationDialogIsOpen, setCancelConfirmationDialogIsOpen] = useState<boolean>(false);
    useEffect(() => {
        if (attemptingToCancelForm) {
            setCancelConfirmationDialogIsOpen(true)
        }
    }, [attemptingToCancelForm]);
    const handleCancelFormConfirmed = () => {
        console.log("handleCancelFormConfirmed")
        setCancelForm(true) //cancel form
        setAttemptingToCancelForm(false) //reset trigger
        setCancelConfirmationDialogIsOpen(false) //close confirmation dialog
    }
    const handleCancelFormRejected = () => {
        console.log("handleCancelFormRejected")
        setAttemptingToCancelForm(false) //reset trigger
        setCancelConfirmationDialogIsOpen(false) //close confirmation dialog
    }

    //ATTEMPTING TO CANCEL FORM end


    return (
        <>

            <Grid container spacing={3} sx={{mt: '4px !important', pr: '24px !important'}}>
                <Grid item xs={3} sx={{pl: '0px !important'}}>
                    <Stack
                        direction={"column"}
                        //sx={{pl: 2, pr: 2}}
                    >
                        {/*Contains: */}
                        {/*    - first_name,last_name,company,display_name fields*/}
                        {/*    - display_name building logic*/}
                        {/*    - duplicateCheck logic. */}
                        <FirstLastCompanyDisplayNameFields
                            control={control}
                            setValue={setValue}
                            trigger={trigger}
                            setDuplicateCheckResponseData={setDuplicateCheckResponseData}
                            setTableDataIsLoading={setTableDataIsLoading} //controls table loading animation
                            performReset={performReset}
                            skipInitialAttempt={skipInitialAttempt}
                            setSkipInitialAttempt={setSkipInitialAttempt}
                            // callTakingDialogIsOpen={callTakingDialogIsOpen}
                        />

                        <BasicTextField control={control} label={"Email"}/>
                        <BasicTextField control={control} label={"Website"}/>
                    </Stack>
                </Grid>

                {/*Middle column*/}

                <Grid item xs={3}>
                    <Stack direction={"column"}>
                        <Stack direction={"row"} spacing={2}>
                            <BasicTextField control={control} label={"Main Phone"} name={"home_phone"}/>
                            <BasicTextField control={control} label={"Mobile Phone"} name={"mobile_phone"}/>
                        </Stack>
                        <Stack direction={"row"} spacing={2}>
                            <BasicTextField control={control} label={"Work Phone"} name={"work_phone"}/>
                            <BasicTextField control={control} label={"Fax Number"} name={"fax_number"}/>
                        </Stack>
                        <AddressFieldsZipAutofill control={control} setValue={setValue}/>

                    </Stack>
                </Grid>

                <Grid item xs={3}>
                    {/*//Status options change depending on value of ContactType*/}
                    <ContactTypeAndStatusSelects
                        control={control}
                        accountInfo={accountInfo}
                        label={""}
                        setValue={setValue}
                        contactTypeName={"record_type_name"}
                        statusName={"status_name"}
                    />

                    <SalesRepSelect
                        control={control}
                        setValue={setValue}
                        accountInfo={accountInfo}
                        label={"Sales Rep"}
                        name={"sales_rep_name"}
                    />

                    <LeadSourceSelect
                        control={control}
                        setValue={setValue}
                        accountInfo={accountInfo}
                        label={"Lead Source"}
                        name={"source_name"}
                    />

                    <AssignedToAutocomplete
                        control={control}
                        label={"Assigned To"}
                        name={"owners"}
                        placeholder={"Search for a team member"}
                        accountInfo={accountInfo}
                    />

                    <SubcontractorsAutocomplete
                        control={control}
                        label={"Subcontractors"}
                        name={"subcontractors"}
                    />
                    <RelatedContactsAutocomplete
                        control={control}
                        label={"Related Contacts"}
                        name={"related"}
                        relatedContactToAdd={relatedContactToAdd}
                        setRelatedContactToAdd={setRelatedContactToAdd}
                        getValues={getValues}
                        setValue={setValue}
                    />

                    <BasicTextField control={control} label={"Description"} pbOverride={'0px'}/>
                </Grid>

                {/*Right column*/}
                <Grid item xs={3}>
                    <Stack direction={"column"}>
                        <BasicCheckbox control={control} label={"Gated Community"} name={"Gated Community"}/>

                        <Stack direction={"row"} spacing={1}>
                            {/*<BasicTextField control={control} label={"Gate Code"}/>*/}
                            {/*<BasicDatePicker control={control} label={"Date of Loss"} name={"Date of Loss"}/>*/}
                            <DateOfLossDatePicker control={control} label={"Date of Loss"} name={"Date of Loss"}/>
                        </Stack>

                        <BasicTextField control={control} label={"Insurance Company"} name={"Insurance Co"}/>

                        <Stack direction={"row"} spacing={2}>
                            <BasicTextField control={control} label={"Claim #"} name={"Claim #"}/>
                            {/*<BasicTextField control={control} label={"Policy #"} name={"Policy #"}/>*/}
                        </Stack>

                        <Typography sx={{pt: 2, pb: 1}}>Add Note</Typography>
                        <NoteSelectAndContent
                            control={control}
                            setValue={setValue}
                            accountInfo={accountInfo}
                            label={""}
                            nameSelect={"note_type"} //note_type is our internal name, record_type_name is jobnimbus, but it clashes with contact field names
                            nameTextField={"note"}
                        />
                    </Stack>
                </Grid>
            </Grid>
            <Dialog open={cancelConfirmationDialogIsOpen} onClose={handleCancelFormRejected}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to close the form? Any info you've entered will be lost.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCancelFormConfirmed}
                        variant="contained"
                        color="primary"
                    >
                        CONFIRM
                    </Button>
                    <Button
                        onClick={handleCancelFormRejected}
                        variant="outlined"
                        color="primary"
                    >
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    );
};

const authUserString = localStorage.getItem("authUser");
const actor = authUserString ? JSON.parse(authUserString).name : '';

const prodDefaultValues: IFormInput = {
    actor: actor,
    first_name: "",
    last_name: "",
    company: "",
    display_name: "",
    email: "",
    website: "",
    home_phone: "",
    mobile_phone: "",
    work_phone: "",
    fax_number: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state_text: "AR",
    zip: "",
    tags: [],
    source_name: "",
    record_type_name: "",
    status_name: "",
    sales_rep_name: "",
    "Insurance Co": "",
    "Claim #": "",
    "Date of Loss": null,
    related: [],
    description: "",
    owners: [], //team members, assigned to
    subcontractors: [],
    "Gated Community": false,
    note_type: "",
    note: "",
}

// @ts-ignore
const testDefaultValues: IFormInput = {
    first_name: "Jane",
    last_name: "Tester",
    company: "Test Company",
    display_name: "Jane Tester",
    email: "mcarner@dfimail.com",
    website: "www.website.com",
    home_phone: "4791234567",
    mobile_phone: "4791234567",
    work_phone: "4791234567",
    fax_number: "4791234567",
    address_line1: "947 N Main Ave",
    address_line2: "Suite 200",
    city: "Greenland",
    state_text: "AR",
    zip: "72701",
    tags: ["test"],
    source_name: "",
    record_type_name: "",
    status_name: "",
    sales_rep_name: "",
    "Insurance Co": "test insurance co",
    "Claim #": "claim num 5678",
    related: [],
    description: "",
    owners: [], //team members, assigned to
    subcontractors: [],
    "Gated Community": false,
    note_type: "",
    note: "",
}