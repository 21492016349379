import {
    Backdrop,
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack
} from "@mui/material";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {fetchAccountInfo} from "../../api/jobNimbusApi.ts";
import {DuplicateCheckResult, IAccountInfo} from "../../api/jobNimbusInterfaces.ts";
import {useColors} from "../../hooks/useColors";
import {DuplicateContactsDataGrid} from "./DuplicateContactsDataGrid";
import {CallTakingForm} from "./CallTakingForm";
import {MRT_RowSelectionState} from "material-react-table";
//@ts-expect-error
import {FieldErrors} from "react-hook-form/dist/types/errors";
import {fetchContactByJnid} from "../../api/jobNimbusFunctions";

export interface ICallTakingDialog {
    callTakingDialogIsOpen: boolean;
    setCallTakingDialogIsOpen: Dispatch<SetStateAction<boolean>>;
}

export interface DuplicateCheckData {
    display_name: string;
    first_name: string;
    last_name: string;
    company: string;
    result: boolean; // Assuming that "result" is intended to be a boolean
    [key: string]: string | boolean; // Add an index signature
}

export const CallTakingDialog: React.FC<ICallTakingDialog> = ({
                                                                  callTakingDialogIsOpen,
                                                                  setCallTakingDialogIsOpen,
                                                              }) => {
    if (!callTakingDialogIsOpen) {
        return null;
    }


    //SUBMITTING FORM start
    const [performDialogSave, setPerformDialogSave] = useState<boolean>(false);
    const [performDialogUpdate, setPerformDialogUpdate] = useState<boolean>(false);

    function handleDialogSave() {
        console.log("Save clicked");
        setPerformDialogSave(true);
    }

    function handleDialogUpdate() {
        console.log("Update clicked");
        setPerformDialogUpdate(true);
    }

    //Load initial data from account info
    const [accountInfo, setAccountInfo] = useState<IAccountInfo | undefined>();

    useEffect(() => {
        (async () => {
            let accountInfo = await fetchAccountInfo();
            setAccountInfo(accountInfo);
        })();
    }, []);

    const [isCreatingContact, setIsCreatingContact] = useState<boolean>(false);

    //FORM ERRORS start
    const [errorsStoredInParent, setErrorsStoredInParent] = useState<FieldErrors | null>(null);
    const formatFormErrors = (errors: FieldErrors) => {
        // console.log(errors)
        if (errors) {
            return Object.keys(errors)
                .map((fieldName) => `${fieldName}: ${errors[fieldName].message}`)
                .join("\n");
        }
    };

    //These are not from react-hook-form, but errors on submit from the backend
    const [submitErrors, setSubmitErrors] = useState<string | undefined>();
    const formatSubmitErrors = () => {
        return submitErrors;
    };
    //FORM ERRORS end

    //RESET FORM start
    const [isResetConfirmationOpen, setResetConfirmationOpen] = useState(false);
    const [performReset, setPerformReset] = useState(false);
    const openResetConfirmationDialog = () => {
        // Show reset confirmation dialog
        setResetConfirmationOpen(true);
    };

    const handleConfirmReset = () => {
        // Reset the form using the reset function directly from useForm hook
        setPerformReset(true);
        // Close the reset confirmation dialog
        setResetConfirmationOpen(false)
        //Wipe DuplicateCheckResults
        setDuplicateCheckResponseData([]);
        //Reset UPDATE CONTACT
        setAttempingToUpdateContact(false)
    };

    const handleCancelReset = () => {
        // Close the reset confirmation dialog
        setResetConfirmationOpen(false);
    };

    //RESET FORM end

    //DUPLICATE CHECK DATAGRID start
    const [duplicateCheckResponseData, setDuplicateCheckResponseData] = useState<
        DuplicateCheckResult[] | []
    >([]);
    const [tableDataIsLoading, setTableDataIsLoading] = useState<boolean>(false);
    //DUPLICATE CHECK DATAGRID end

    //DUPLICATE CHECK Row Selection start
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    // useEffect(() => {
    //     console.log(rowSelection)
    // }, [rowSelection]);
    //DUPLICATE CHECK Row Selection END

    //DUPLICATE CHECK Action Buttons START
    const [duplicateGridButtonsActive, setDuplicateGridButtonsActive] = useState(false);
    //Activate buttons when selection is made
    useEffect(() => {
        // console.log("rowSelection useEffect",rowSelection)
        if (rowSelection && Object.keys(rowSelection).length > 0) {
            setDuplicateGridButtonsActive(true)
        } else {
            setDuplicateGridButtonsActive(false)

        }
    }, [rowSelection]);
    //Reset row selection when datagrid is empty
    useEffect(() => {
        // console.log('duplicateCheckResponseData useEffect',duplicateCheckResponseData)
        // if (duplicateCheckResponseData && duplicateCheckResponseData.length > 0){
        setRowSelection({})
        // }
    }, [duplicateCheckResponseData]);
    //DUPLICATE CHECK Action Buttons END

    //DUPLICATE DATAGRID Add as Related START
    const [relatedContactToAdd, setRelatedContactToAdd] = useState<DuplicateCheckResult | null>(null);
    const handleAddAsRelated = () => {
        console.log("handleAddAsRelated")
        //Make sure rowSelection is correct format
        const duplicateCheckResult = getDuplicateCheckContactByRowSelectionIndex()
        if (duplicateCheckResult) {
            console.log(duplicateCheckResult)
            setRelatedContactToAdd(duplicateCheckResult)
        }
    }

    const getDuplicateCheckContactByRowSelectionIndex = (): DuplicateCheckResult | null => {
        const keys = Object.keys(rowSelection);
        if (keys.length > 0) {
            const index: number = Number(keys[0]);
            const value = rowSelection[index];

            if (typeof value === 'boolean' && value) {
                return duplicateCheckResponseData[index]
            }
        }
        return null
    }
    //DUPLICATE DATAGRID Add as Related END

    //DUPLICATE DATAGRID copy info START
    const [copyInfoContact, setCopyInfoContact] = useState<DuplicateCheckResult | null>(null);

    //NOTE This is passed to FirstLastCompanyDisplayNameFields where it's used to :
    //     - ignore displayname validation check on initial page load (otherwise it always displays an error first)
    //     - to keep displayname logic from firing after using copyInfo (otherwise it will always alter name after copyInfo executes)
    //
    const [skipInitialAttempt, setSkipInitialAttempt] = useState<boolean>(true);
    const handleCopyInfo = async () => {
        //Get contact
        const duplicateCheckResult = getDuplicateCheckContactByRowSelectionIndex()
        const contact = await fetchContactByJnid(duplicateCheckResult?.jnid)
        //Reset lead source, we don't want the same one as before
        contact.source_name = "";
        contact.source = undefined
        //Filter out lead sources that are no longer valid
        // if (accountInfo){
        //     // Get the list of valid source names
        //     const validSourceNames = getLeadSources(accountInfo).map(option => option.value);
        //
        //     // Check if the source_name property exists in the valid source names
        //     if (!validSourceNames.includes(contact.source_name)) {
        //         // If not, set it to an empty string
        //         contact.source_name = "";
        //         contact.source = undefined
        //     }
        // }
        console.log(contact)
        if (duplicateCheckResult) {
            console.log(contact)
            setCopyInfoContact(contact)
            setSkipInitialAttempt(true)
        }
        //copyInfoContact is passed to CallTakingForm where it will be detected and used to update form values
    }
    //DUPLICATE DATAGRID copy info END

    //CANCEL FORM CHECK start
    //When user clicks the CANCEL button, trigger a check in the CallTakingForm
    const [attemptingToCancelForm, setAttemptingToCancelForm] = useState<boolean>(false);
    const [cancelForm, setCancelForm] = useState<boolean>(false);
    useEffect(() => {
        if (cancelForm) {
            console.log("Received trigger to cancel form. Closing CallTakingDialog now.")
            setCallTakingDialogIsOpen(false)
            setCancelForm(false)
        }
    }, [cancelForm]);

    function handleDialogCancel() {
        setAttemptingToCancelForm(true) //trigger CallTakingForm handling of cancel button
    }

    //CANCEL FORM CHECK end

    //UPDATE CONTACT start
    // @ts-ignore
    const [isUpdatingContact, setIsUpdatingContact] = useState<boolean>(false);
    const [attempingToUpdateContact, setAttempingToUpdateContact] = useState<boolean>(false);
    const [jnidToUpdate, setJnidToUpdate] = useState<string | null>(null);
    const handleUpdateContact = async () => {

        //Used to replace SAVE/CANCEL with new UPDATE/CANCEL buttons
        setAttempingToUpdateContact(true)
        //Disable duplicate grid
        setDuplicateGridButtonsActive(false)

        //Copy info from selected contact
        await handleCopyInfo()

        //Set jnidToUpdate, we'll use it when updating the contact
        const duplicateCheckResult = getDuplicateCheckContactByRowSelectionIndex()

        //If it's null for some reason, abort operation and reset flags
        if (!duplicateCheckResult?.jnid) {
            console.error("Tried to get jnid for updating contact, but it was null.")
            setAttempingToUpdateContact(false)
            setDuplicateGridButtonsActive(true)
            return;
        }

        setJnidToUpdate(duplicateCheckResult?.jnid)


    }


    //UPDATE CONTACT end


    const colors = useColors();

    return (
        <Dialog
            open={callTakingDialogIsOpen}
            // onClose={attemptToCloseCallTakingDialog}
            fullWidth
            maxWidth={false}
            // maxWidth="md"
        >
            <DialogTitle sx={{backgroundColor: colors.darkBlue, color: "white"}}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" alignItems="center">
                        <ButtonGroup>
                            <Button
                                variant="contained"
                                disabled={!duplicateGridButtonsActive}
                                sx={{
                                    backgroundColor: colors.lightBrown,
                                    color: 'white',
                                    mr: '6px'
                                }}
                                onClick={handleCopyInfo}
                            >
                                Copy Info
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!duplicateGridButtonsActive}
                                sx={{
                                    backgroundColor: colors.lightBrown,
                                    color: 'white',
                                    mr: '6px'
                                }}
                                onClick={handleAddAsRelated}
                            >
                                Add As Related
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!duplicateGridButtonsActive}
                                // disabled={true}
                                sx={{
                                    backgroundColor: colors.lightBrown,
                                    color: 'white',
                                    mr: '6px'
                                }}
                                onClick={handleUpdateContact}
                            >
                                Update Contact
                            </Button>
                        </ButtonGroup>
                    </Stack>
                    {/*<Typography sx={{ margin: 'auto' }}>Add new contact</Typography>*/}
                    <Button
                        variant="outlined"
                        onClick={openResetConfirmationDialog}
                        sx={{color: 'white'}}
                    >
                        RESET FORM
                    </Button>
                </Stack>
                {/* Reset Confirmation Dialog */}
                <Dialog open={isResetConfirmationOpen} onClose={handleCancelReset}>
                    <DialogTitle>Confirmation</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Are you sure you want to reset the form? This will delete all data
                            you have entered.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleConfirmReset}
                            variant="contained"
                            color="primary"
                        >
                            CONFIRM RESET
                        </Button>
                        <Button
                            onClick={handleCancelReset}
                            variant="outlined"
                            color="primary"
                        >
                            CANCEL
                        </Button>
                    </DialogActions>
                </Dialog>
            </DialogTitle>
            <DialogContent sx={{height: "100vh", pb: 0}}>
                {/*<Container maxWidth={false} sx={{mt: 4, mb: 4}}>*/}
                {accountInfo && (
                    // Display spinner while saving contact
                    <Stack direction="column" spacing={3} sx={{mt: 2}}>
                        <Backdrop
                            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                            open={isCreatingContact}
                        >
                            <Stack direction={"column"} spacing={3} alignItems="center" justifyContent="center">
                                <CircularProgress style={{color: colors.lightBlue}}/>
                                <Typography variant="body1" style={{marginTop: 16}}>
                                    Saving contact...
                                </Typography>
                            </Stack>
                        </Backdrop>
                        <Backdrop
                            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                            open={isUpdatingContact}
                        >
                            <Stack direction={"column"} spacing={3} alignItems="center" justifyContent="center">
                                <CircularProgress style={{color: colors.lightBlue}}/>
                                <Typography variant="body1" style={{marginTop: 16}}>
                                    Updating contact...
                                </Typography>
                            </Stack>
                        </Backdrop>
                        {/*Top section - Duplicate datagrid*/}
                        <DuplicateContactsDataGrid
                            results={duplicateCheckResponseData}
                            tableDataIsLoading={tableDataIsLoading}
                            rowSelection={rowSelection}
                            setRowSelection={setRowSelection}
                            // results={{"results":[]}}
                        />
                        {/*Left column*/}
                        <CallTakingForm
                            accountInfo={accountInfo}
                            setDuplicateCheckResponseData={setDuplicateCheckResponseData}
                            setTableDataIsLoading={setTableDataIsLoading}
                            performReset={performReset} //Pass to react-hook-form reset() function
                            setPerformReset={setPerformReset}
                            setErrorsStoredInParent={setErrorsStoredInParent}
                            performDialogSave={performDialogSave}
                            setPerformDialogSave={setPerformDialogSave}
                            performDialogUpdate={performDialogUpdate}
                            setPerformDialogUpdate={setPerformDialogUpdate}
                            setSubmitErrors={setSubmitErrors}
                            setIsCreatingContact={setIsCreatingContact}
                            setIsUpdatingContact={setIsUpdatingContact}
                            jnidToUpdate={jnidToUpdate}
                            setJnidToUpdate={setJnidToUpdate}
                            relatedContactToAdd={relatedContactToAdd}
                            setRelatedContactToAdd={setRelatedContactToAdd}
                            copyInfoContact={copyInfoContact}
                            skipInitialAttempt={skipInitialAttempt}
                            setSkipInitialAttempt={setSkipInitialAttempt}
                            setCopyInfoContact={setCopyInfoContact}
                            attemptingToCancelForm={attemptingToCancelForm}
                            setAttemptingToCancelForm={setAttemptingToCancelForm}
                            setCancelForm={setCancelForm}
                            setCallTakingDialogIsOpen={setCallTakingDialogIsOpen}
                        />
                    </Stack>
                )}
                {/*</Container>*/}
            </DialogContent>
            <DialogActions>
                <Box sx={{flexGrow: 1}}>
                    <Typography
                        sx={{color: "red", marginLeft: 2, marginRight: 2}}
                    >
                        {formatFormErrors(errorsStoredInParent)}
                    </Typography>
                    <Typography sx={{color: "red", marginLeft: 2, marginRight: 2}}>
                        {formatSubmitErrors()}
                    </Typography>
                </Box>
                <Button variant={"outlined"} onClick={handleDialogCancel}>
                    Cancel
                </Button>

                {
                    attempingToUpdateContact ?
                        <Button variant={"contained"} onClick={handleDialogUpdate}>
                            Update
                        </Button> :
                        <Button variant={"contained"} onClick={handleDialogSave}>
                            Save
                        </Button>
                }
            </DialogActions>
        </Dialog>
    );
};