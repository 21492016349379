// Existing Employee interface
interface Employee {
    id: string;
    role: string;
    name: string;
    cellPhone: string | null;
    email: string | null;
    googleAuthStatus: string;
    calendarId: string | null;
    calendarIdIsValid: boolean | null;
}

// LoadResponse with existing structure
interface LoadResponse {
    resources: Employee[];
    events: Event[];
    timeRanges: TimeRange[];
}

// Existing ExternalPushMapping interface
interface ExternalPushMapping {
    externalId: string;
    employee: Employee;
    externalPushStatus: string;
    lastPushAt: string;
    failedUpdateAttempts: number | null;
}

// Existing TimeRange interface
interface TimeRange {
    name: string;
    startDate: string;
    endDate: string;
    cls: string;
    iconCls: string | null;
    style: string | null;
    recurrenceRule: string | null;
    exceptionDates: any; // Consider specifying a more precise type if possible
}

// EventType based on Java Enum
// If there are more specific types for eventType, please replace the placeholder values below
type EventType = 'timeOff' | 'meeting' | 'appointment';

// DurationUnit based on Java Enum
// Replace with the actual values of DurationUnit if needed
type DurationUnit = 'Minutes' | 'Hours' | 'Days';

// Event interface, based on both existing and Java DTO
interface Event {
    phantomId: string; // Java DTO uses $PhantomId
    id: string;
    startDate: Date; // Assuming this is parsed as a Date object
    endDate: Date; // Assuming this is parsed as a Date object
    eventType: EventType;
    iconCls: string | null; // Ensure nullability if needed
    customerName: string;
    customerContact: string;
    customerType: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zip: string;
    name: string;
    eventColor: string;
    desc: string;
    resourceIds: Set<string>; // Could be Set or string[]
    allDay: boolean;
    draggable: boolean;
    resizable: boolean;
    duration: number;
    durationUnit: DurationUnit; // Changed to DurationUnit type
    cls: string;
    recurrenceRule: string;
    exceptionDates: string[]; // Assuming these are date strings
    externalPushMappings: Set<ExternalPushMapping>; // Could be Set or ExternalPushMapping[]
}

// EventIdOnly from Java DTO
interface EventIdOnly {
    id: string;
}

// Command enum remains unchanged
export enum Command {
    LOAD = "LOAD",
    BROADCAST_CHANGES = "BROADCAST_CHANGES",
    FORCE_SYNC = "FORCE_SYNC"
}

// SyncRequest interface with missing parts added
interface SyncRequest {
    type: string;
    requestId: number;
    events: {
        added: null | Event[]; // Assuming Event type
        updated: null | Event[]; // Assuming Event type
        removed: null | EventIdOnly[]; // EventIdOnly type
    };
}

// Events interface based on Java Events nested class
interface Events {
    rows: Event[]; // Assuming Event type
    removed: EventIdOnly[]; // EventIdOnly type
}

// SyncResponse interface including missing fields from Java DTO
interface SyncResponse {
    type: string;
    requestId: number;
    events: Events; // Using Events interface
    errors: SyncResponseError[]; // Added from Java DTO
}

// SyncResponseError nested inside SyncResponse, based on Java DTO
export interface SyncResponseError {
    event: Event; // Assuming Event type
    errorMsg: string; // Corresponding to errorMsg field
}

// WebSocketMessage with added SyncResponse and SyncRequest types
export interface WebSocketMessage {
    command: Command;
    loadResponse: LoadResponse;
    syncResponse: SyncResponse; // Using new SyncResponse interface
    syncRequest: SyncRequest; // Using new SyncRequest interface
    timeRanges: TimeRange[]; // Assuming array of TimeRange
}

