import {
    CombinedJobNimbusContactResponse,
    DuplicateCheckResponse,
    IAccountInfo,
    JobNimbusContact,
    JobNimbusIdArray,
    SearchContactResult
} from "./jobNimbusInterfaces.ts";
import {
    FormInputOptionPropsStringString,
    IFormInput,
    IFormInputArray
} from "../components/call-taking-form/FormInputProps.tsx";
import {
    fetchSearchContactResults,
    fetchSubcontractors,
    getContactByJnid,
    ISubcontractorWeb,
    postDuplicateCheck,
    postNewContact, postUpdateContact
} from "./jobNimbusApi.ts";
import {DuplicateCheckData} from "../components/call-taking-form/CallTakingDialog";

export const getNotesTypes = (accountInfo: IAccountInfo) => {
    return accountInfo.activityTypes.map<FormInputOptionPropsStringString>((activityType => ({
        value: activityType.TypeName,
        label: activityType.TypeName,
    })))
}

export const getContactTypes = (accountInfo: IAccountInfo) => {
    return accountInfo.workflows.filter(workflow => workflow.object_type === "contact")
        .map<FormInputOptionPropsStringString>((workflow) => ({
            label: workflow.name,
            value: workflow.name
        }));
}

export const getStatuses = (accountInfo: IAccountInfo, workflowName: string | null) => {
    //filter to find workflow
    //should only be a single one
    //get dropdown values from workflow.status array
    // console.log(accountInfo.workflows)
    let possibleWorkflows = accountInfo?.workflows.filter(workflow => (
        workflow.object_type === "contact" &&
        workflow.name === workflowName
    ));
    // console.log(possibleWorkflows)
    if (possibleWorkflows.length > 1) {
        console.log("Did not match example one workflow. Found this many: " + possibleWorkflows.length)
        console.log(possibleWorkflows)
    } else if (possibleWorkflows.length === 0) {
        console.log("No workflows that matched id: " + workflowName);
        console.log(accountInfo.workflows)
        return [];
    }

    return possibleWorkflows[0].status.map<FormInputOptionPropsStringString>(status => ({
        label: status.name,
        value: status.name,
    }))
};

export const getSalesReps = (accountInfo: IAccountInfo) => {

    //Get list of IDs from profiles with "Sales Rep" status
    let salesRepIds: string[] = [];

    accountInfo.profiles.forEach(profile => {
        if (profile.features.includes("salesdashboard") && profile.users) {
            profile.users.forEach(user => {
                if (!salesRepIds.includes(user)) {
                    salesRepIds.push(user);
                }
            });
        }
    });
    //Return list of users that match
    let salesReps = accountInfo.users.filter(user => salesRepIds.includes(user.id))
        .map<FormInputOptionPropsStringString>(user => ({
            label: user.name,
            value: user.name
        }))

    //Add blank option
    salesReps.unshift({
        label: "None",
        value: null
    })
    return salesReps;

};

export const getLeadSources = (accountInfo: IAccountInfo) => {
    let leadSources = accountInfo.sources
        .filter(source => source.IsActive) // Filter based on isActive property
        .sort((a, b) => a.SortOrder - b.SortOrder)
        .map<FormInputOptionPropsStringString>(source => ({
            label: source.SourceName,
            value: source.SourceName
        }));
    leadSources.unshift({
        label: "None",
        value: null
    });
    return leadSources;
};
export const getTags = (accountInfo: IAccountInfo) => {
    return accountInfo.tags;
}

export const getTeamMembers = (accountInfo: IAccountInfo) => {
    return accountInfo.users.map<FormInputOptionPropsStringString>(user => ({
        label: user.name,
        value: user.id
    }))
}

export const getSubcontractors = async () => {
    let subcontractors: ISubcontractorWeb[] = await fetchSubcontractors();
    return subcontractors.map<FormInputOptionPropsStringString>(subcontractor => ({
        label: subcontractor.display_name + " (" + subcontractor.company + ")",
        value: subcontractor.jnid
    }))

}

export const getSearchContactsResults = async (inputValue: string) => {
    let searchResults: SearchContactResult[] = await fetchSearchContactResults(inputValue)
    return searchResults;
}

export const transformRelatedContactResultsToAutocompleteOptions = (contactResults: SearchContactResult[]): FormInputOptionPropsStringString[] => {
    return contactResults.map(contactResult => ({
        label: contactResult.d,
        value: contactResult.i
    }))
}

export const fetchContactByJnid = async (jnid: string | undefined) => {
    if (!jnid) {
        return
    }
    let contact = await getContactByJnid(jnid)

    if (contact["Date of Loss"] === 0){
        contact["Date of Loss"] = null;
    } else if (typeof contact["Date of Loss"] === "number") {
        contact["Date of Loss"] = new Date(contact["Date of Loss"] * 1000)
    }

    return contact
}

function buildJobNimbusApiContactFromFormData(newContactFormData: IFormInput,accountInfo: IAccountInfo | undefined) {
    let dateOfLoss: number | null = null;

    if (newContactFormData['Date of Loss'] !== null) {
        dateOfLoss = Math.floor(new Date(newContactFormData['Date of Loss']).getTime() / 1000);
    }

    let newContact: JobNimbusContact = {
        ...newContactFormData,
        related: newContactFormData.related.map(
            (related_contact: IFormInputArray): JobNimbusIdArray => ({id: related_contact.value})
        ),
        subcontractors: newContactFormData.subcontractors.map(
            (subcontractor: IFormInputArray): JobNimbusIdArray => ({id: subcontractor.value})
        ),
        owners: newContactFormData.owners.map(
            (owner: IFormInputArray): JobNimbusIdArray => ({id: owner.value})
        ),
        'Date of Loss': dateOfLoss,
    }

    //Remove invalid source_name if it somehow makes it way into the form

    if (accountInfo){
        // Get the list of valid source names
        const validSourceNames = getLeadSources(accountInfo).map(option => option.value);

        // Check if the source_name property exists in the valid source names
        if (!validSourceNames.includes(newContact.source_name)) {
            // If not, set it to an empty string
            newContact.source_name = "";
        }
    }

    return newContact;
}

export const createNewContact = (newContactFormData: IFormInput, accountInfo: IAccountInfo | undefined): Promise<CombinedJobNimbusContactResponse>=> {

    console.log("createNewContact")

    //Transform to fit job nimbus api
    let newContact = buildJobNimbusApiContactFromFormData(newContactFormData, accountInfo);

    console.log(newContact)

    //Send post request
    return postNewContact(newContact)
}

export const updateContact = (jnidToUpdate : string, updateContactFormData: IFormInput, accountInfo: IAccountInfo | undefined): Promise<CombinedJobNimbusContactResponse>=> {

    console.log("updateContact")

    //Transform to fit job nimbus api
    let contactToUpdate = buildJobNimbusApiContactFromFormData(updateContactFormData,accountInfo);

    console.log(contactToUpdate)

    //Send post request
    return postUpdateContact(jnidToUpdate,contactToUpdate)
}


export const makeDuplicateCheckApiCall = async (duplicateCheckData: DuplicateCheckData): Promise<DuplicateCheckResponse> => {
    return await postDuplicateCheck(duplicateCheckData)
}


export const getStateOptions = () => {
    return [
        {"label": "Alabama", "value": "AL"},
        {"label": "Alaska", "value": "AK"},
        {"label": "Arizona", "value": "AZ"},
        {"label": "Arkansas", "value": "AR"},
        {"label": "California", "value": "CA"},
        {"label": "Colorado", "value": "CO"},
        {"label": "Connecticut", "value": "CT"},
        {"label": "Delaware", "value": "DE"},
        {"label": "Florida", "value": "FL"},
        {"label": "Georgia", "value": "GA"},
        {"label": "Hawaii", "value": "HI"},
        {"label": "Idaho", "value": "ID"},
        {"label": "Illinois", "value": "IL"},
        {"label": "Indiana", "value": "IN"},
        {"label": "Iowa", "value": "IA"},
        {"label": "Kansas", "value": "KS"},
        {"label": "Kentucky", "value": "KY"},
        {"label": "Louisiana", "value": "LA"},
        {"label": "Maine", "value": "ME"},
        {"label": "Maryland", "value": "MD"},
        {"label": "Massachusetts", "value": "MA"},
        {"label": "Michigan", "value": "MI"},
        {"label": "Minnesota", "value": "MN"},
        {"label": "Mississippi", "value": "MS"},
        {"label": "Missouri", "value": "MO"},
        {"label": "Montana", "value": "MT"},
        {"label": "Nebraska", "value": "NE"},
        {"label": "Nevada", "value": "NV"},
        {"label": "New Hampshire", "value": "NH"},
        {"label": "New Jersey", "value": "NJ"},
        {"label": "New Mexico", "value": "NM"},
        {"label": "New York", "value": "NY"},
        {"label": "North Carolina", "value": "NC"},
        {"label": "North Dakota", "value": "ND"},
        {"label": "Ohio", "value": "OH"},
        {"label": "Oklahoma", "value": "OK"},
        {"label": "Oregon", "value": "OR"},
        {"label": "Pennsylvania", "value": "PA"},
        {"label": "Rhode Island", "value": "RI"},
        {"label": "South Carolina", "value": "SC"},
        {"label": "South Dakota", "value": "SD"},
        {"label": "Tennessee", "value": "TN"},
        {"label": "Texas", "value": "TX"},
        {"label": "Utah", "value": "UT"},
        {"label": "Vermont", "value": "VT"},
        {"label": "Virginia", "value": "VA"},
        {"label": "Washington", "value": "WA"},
        {"label": "West Virginia", "value": "WV"},
        {"label": "Wisconsin", "value": "WI"},
        {"label": "Wyoming", "value": "WY"}
    ]

}