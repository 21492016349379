import {FormInputProps} from "../FormInputProps.tsx";
import {Controller} from "react-hook-form";
import {toCamelCaseValidHtmlId} from "../../../helpers/utility.tsx";
import {TextField} from "@mui/material";
import React from "react";


interface DisplayNameProps extends FormInputProps{
    updateDuplicateCheckData?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, name: string) => void;
}

export function DisplayNameField({name, control, label, multiline, required, updateDuplicateCheckData}: DisplayNameProps) {

    return (
        <Controller
            //@ts-expect-error
            name={name ? name : toCamelCaseValidHtmlId(label)}
            control={control}
            rules={{required: required}}
            render={({
                         field,
                         // field: {onChange, value},
                         fieldState: {error},
                         //formState,
                     }) => (
                <TextField
                    {...field}
                    label={label}
                    multiline={multiline}
                    size={"small"}
                    sx={{pb:2}}
                    // sx={{
                    //     pb:1,
                    //     '& input': {
                    //         fontSize: '0.85rem', // Adjust the font size as needed
                    //     },
                    //     '& .MuiInputLabel-root': {
                    //         fontSize: '0.85rem', // Adjust the label font size as needed
                    //     },
                    //     '& .MuiOutlinedInput-root': {
                    //         padding: '0px', // Adjust the padding as needed
                    //         '& fieldset': {
                    //             borderWidth: '1px', // Adjust the border width as needed
                    //         },
                    //     },
                    // }}
                    error={!!error}
                    onBlur={(e) => updateDuplicateCheckData?.(e, name as string)}
                    // helperText={error ? error.message : null}
                />
            )}
        />
    );
}