import {useEffect, useState} from "react";
import {getNotesTypes} from "../../../api/jobNimbusFunctions.ts";
import {FormInputOptionPropsStringString, FormInputProps} from "../FormInputProps.tsx";
import {BasicSelect} from "./common/BasicSelect.tsx";
import {BasicTextField} from "./common/BasicTextField.tsx";

interface NoteProps {
    nameSelect: string,
    nameTextField: string
}

export function NoteSelectAndContent({control, label, accountInfo, setValue, nameSelect,nameTextField}: FormInputProps & NoteProps) {

    const [noteTypes, setNoteTypes] = useState<FormInputOptionPropsStringString[]>([]);

    useEffect(() => {
        if (!accountInfo) {
            return;
        }
        let noteTypesTemp = getNotesTypes(accountInfo)
        // console.log(noteTypesTemp)
        setNoteTypes(noteTypesTemp);
    }, []);

    //Wait until noteTypes is populated before picking default option
    useEffect(() => {
        if (noteTypes && noteTypes.length > 0) {
            setValue(nameSelect, noteTypes[0].value)
        }
    }, [noteTypes]);


    return (
        <>
            <BasicSelect control={control}
                         label={label}
                         options={noteTypes}
                         name={nameSelect}
                         defaultValue={""}
            />
            <BasicTextField control={control}
                            label={"Enter note"}
                            name={nameTextField}
                            multiline
                            pbOverride={'0px'}
            /></>

    );
}