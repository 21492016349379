import {useEffect, useState} from "react";
import {getLeadSources} from "../../../api/jobNimbusFunctions.ts";
import {FormInputOptionPropsStringString, FormInputProps} from "../FormInputProps.tsx";
import {BasicSelect} from "./common/BasicSelect.tsx";

export function LeadSourceSelect({control, label, accountInfo, name}: FormInputProps) {

    const [leadSources, setLeadSources] = useState<FormInputOptionPropsStringString[]>([]);


    useEffect(() => {
        if (!accountInfo) {
            return;
        }
        let leadSourcesTemp = getLeadSources(accountInfo)
        setLeadSources(leadSourcesTemp);
        // if (leadSourcesTemp && leadSourcesTemp.length > 0) {
        //     setValue(name, leadSourcesTemp[0].value)
        // }

    }, []);


    return (

        <BasicSelect control={control}
                     label={label}
                     options={leadSources}
                     name={name}
                     defaultValue={leadSources && leadSources.length > 0 ? leadSources[0].value : ""}
        />
    );
}