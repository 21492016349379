export const colorList = [
    {"name": "Royal Blue", "hex": "#3F51B5"},
    {"name": "Pink", "hex": "#E91E63"},
    {"name": "Teal", "hex": "#009688"},
    {"name": "Deep Orange", "hex": "#FF5722"},
    {"name": "Deep Purple", "hex": "#673AB7"},
    {"name": "Lime", "hex": "#CDDC39"},
    {"name": "Amber", "hex": "#FFC107"},
    {"name": "Green", "hex": "#4CAF50"},
    {"name": "Orange", "hex": "#FF9800"},
    {"name": "Blue", "hex": "#2196F3"},
    {"name": "Purple", "hex": "#9C27B0"},
    {"name": "Light Green", "hex": "#8BC34A"},
    {"name": "Blue Grey", "hex": "#607D8B"},
    {"name": "Yellow", "hex": "#FFEB3B"},
    {"name": "Light Blue", "hex": "#03A9F4"},
    {"name": "Brown", "hex": "#795548"},
    {"name": "Red", "hex": "#F44336"},
    {"name": "Cyan", "hex": "#00BCD4"},
    {"name": "Deep Peach", "hex": "#FF9E80"},
]
