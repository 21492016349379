import * as React from "react";
import {useState} from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {AppBar, Box, Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import {useAuth0} from "@auth0/auth0-react";
import MenuIcon from "@mui/icons-material/Menu";
import {Link as RouterLink, Outlet, useLocation} from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import {Logout} from "@mui/icons-material";
import {CallTakingDialog} from "./call-taking-form/CallTakingDialog.tsx";
import {useColors} from "../hooks/useColors";
import {CalendarIcon} from "@mui/x-date-pickers";

const DISABLE_SCHEDULER = import.meta.env.VITE_DISABLE_SCHEDULER
const ALLOWED_USERS = import.meta.env.VITE_ALLOWED_USERS?.split(",")
function SettingsDropdown() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const {user, logout} = useAuth0();

    const handleLogout = async () => {
        // logoutBackend()
        logout({
            logoutParams: {
                returnTo: window.location.origin
            }
        })
    }

    const isUserAllowed = ALLOWED_USERS?.includes(user?.sub);
    // console.log(isUserAllowed,ALLOWED_USERS,user)

    // let colors = useColors();

    return (
        <>
            <Button
                id="dropdown-menu-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant={"contained"}
                // sx={{backgroundColor: colors.lightBrown}}
            ><MenuIcon/></Button>
            <Menu
                id={"settings-dropdown"}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'dropdown-menu-button',
                }}
            >
                <MenuItem
                    component={RouterLink}
                    to={"/"}
                    disabled={DISABLE_SCHEDULER === 'true'}
                    onClick={handleClose}
                >
                    <ListItemIcon><CalendarIcon/></ListItemIcon>
                    <ListItemText>Scheduler</ListItemText>
                </MenuItem>
                <MenuItem
                    component={RouterLink}
                    to={"/settings"}
                    disabled={DISABLE_SCHEDULER === 'true' || !isUserAllowed}
                    onClick={handleClose}
                >
                    <ListItemIcon><SettingsIcon/></ListItemIcon>
                    <ListItemText>Settings</ListItemText>
                </MenuItem>
                <Divider/>
                <MenuItem
                    onClick={handleLogout}
                >
                    <ListItemIcon><Logout/></ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
}

export function AppHeader() {

    const location = useLocation();
    const isHomePage = location.pathname === "/";

    //Call taking dialog box
    const [callTakingDialogBoxIsOpen, setCallTakingDialogBoxIsOpen] = useState(false);


    const attemptToOpenCallTakingDialog = () => {
        setCallTakingDialogBoxIsOpen(true);
    }

    let colors = useColors();

    const {user} = useAuth0();

    return (
        <>
            <AppBar sx={{backgroundColor: colors.darkBlue}} >
                <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                    }}
                >
                    {/*<IconButton*/}
                    {/*    edge="start"*/}
                    {/*    color="inherit"*/}
                    {/*    aria-label="open drawer"*/}
                    {/*    onClick={toggleDrawer}*/}
                    {/*    sx={{*/}
                    {/*        marginRight: '36px',*/}
                    {/*        ...(open && {display: 'none'}),*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <MenuIcon/>*/}
                    {/*</IconButton>*/}
                    <img src={"/images/Brown-Boys-20-Year-Guarantee-Logo-1-300x300.png"}
                         alt="Logo"
                         style={{
                             height: '140px',
                             marginRight: '10px',
                             position: 'absolute',
                             transform: 'translate(0px,20px)'
                         }}
                    />
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{flexGrow: 1, transform: 'translate(150px)'}}
                    >
                        Logged in as: {user?.name}
                    </Typography>
                    <Box>
                        {/* Can only use this button on homepage, due to logic */}
                        {/* After user creates contact, they have the option to create appointment and pull in info */}
                        {isHomePage && (
                            <Button variant="contained" color={"warning"}
                                    onClick={attemptToOpenCallTakingDialog}
                                    sx={{ mr: 2 }}
                            >NEW / UPDATE CONTACT</Button>
                        )}

                        <SettingsDropdown/>

                        {/*<IconButton color="inherit">*/}
                        {/*    <Badge badgeContent={4} color="secondary">*/}
                        {/*        <NotificationsIcon/>*/}
                        {/*    </Badge>*/}
                        {/*</IconButton>*/}
                    </Box>
                </Toolbar>
                <CallTakingDialog callTakingDialogIsOpen={callTakingDialogBoxIsOpen}
                                  setCallTakingDialogIsOpen={setCallTakingDialogBoxIsOpen}/>
            </AppBar>
            <Outlet/>
        </>
    );
}