import {ChangeEvent, useState} from "react";
import {Box, Paper, Tab, Tabs, Typography,} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import {SettingsPageColorsTab} from "./colors-tab/SettingsPageColorsTab.tsx";
import {SettingsPageWorkingHoursTab} from "./working-hours-tab/SettingsPageWorkingHoursTab.tsx";
import {SettingsPageEmployeesTab} from "./employees-tab/SettingsPageEmployeesTab";
import {Navigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";

const ALLOWED_USERS = import.meta.env.VITE_ALLOWED_USERS?.split(",")
export function SettingsPage() {

    //Only certain users can visit this page
    const {user} = useAuth0();
    const isUserAllowed = ALLOWED_USERS?.includes(user?.sub);
    if (!isUserAllowed) {
        return <Navigate to="/" replace />;
    }

    const [tabValue, setTabValue] = useState<number>(0);

    const handleTabChange = (_: ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                height: "100vh",
            }}
        >
            <Toolbar/>

            <Box sx={{mt: 4, mb: 4, height: "100%", mx: 4}}>
                <Paper elevation={3} sx={{p: 3, mb: 4, height: "90%"}}>
                    <Typography variant="h6" mb={2}>
                        Settings
                    </Typography>
                    <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary">
                        {/*<Tab label="General"/>*/}
                        <Tab label={"City Colors"}/>
                        <Tab label={"Working Hours"}/>
                        <Tab label={"Employees"}/>
                    </Tabs>

                    <Box mt={2}>
                        {/*{tabValue === 0 && <SettingsPageGeneralTab/>}*/}
                        {tabValue === 0 && <SettingsPageColorsTab/>}
                        {tabValue === 1 && <SettingsPageWorkingHoursTab/>}
                        {tabValue === 2 && <SettingsPageEmployeesTab/>}
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
}
