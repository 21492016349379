import {BryntumSchedulerProps} from "@bryntum/scheduler-react";
import {MessageDialog, StringHelper} from "@bryntum/scheduler";
import {getContactTypes} from "../../api/jobNimbusFunctions";
import {IAccountInfo} from "../../api/jobNimbusInterfaces";
import {FormInputOptionPropsStringString} from "../../components/call-taking-form/FormInputProps";
import {ExternalPushMapping, ExternalPushStatus} from "../settings/employees-tab/ManageEmployees";
import {SchedulerEventModel} from "./schedulerEventModel";


const apiBaseUrl = import.meta.env.VITE_REACT_APP_API_SERVER_URL;
// const apiBaseUrlProxy = apiBaseUrl + "/api1/proxy";
const apiBaseUrlEndpoints = apiBaseUrl + "/api1";

export enum eventType {
    APPOINTMENT = "appointment",
    MEETING = "meeting",
    TIME_OFF = "timeOff"
}

// Define the change event handler for eventTypeField

export const onEventTypeChangeHideOrShowFieldsHandler = (event: any) => {
    const eventEditor = event.source.up('eventeditor');
    // console.log(event)
    onEventTypeChangeHideOrShowFields(eventEditor,event.value)
}

// @ts-ignore
export const onEventTypeChangeHideOrShowFields = (eventEditor, value) => {

    if (!eventEditor) {
        console.error("onEventTypeChangeHideOrShowFields: eventEditor is undefined");
        return
    }

    // Get the relevant fields
    const apptFields = ['customerContactField','addressLine1Field', 'addressLine2Field', 'cityField', 'stateField', 'zipField', 'customerTypeField'];
    const meetingFields = ['recurrenceCombo'];
    // @ts-ignore
    const timeOffFields = ['recurrenceCombo']

    // Show specific fields based on the selected radio option
    if (value === 'appointment') {
        meetingFields.forEach((field) => {
            eventEditor.widgetMap[field].hidden = true;
        });
        // @ts-ignore
        timeOffFields.forEach((field) => {
            eventEditor.widgetMap[field].hidden = true;
        });
        //these last so they're shown
        apptFields.forEach((field) => {
            eventEditor.widgetMap[field].hidden = false;
        });
    } else if (value === 'meeting') {
        apptFields.forEach((field) => {
            eventEditor.widgetMap[field].hidden = true;
        });
        // @ts-ignore
        timeOffFields.forEach((field) => {
            eventEditor.widgetMap[field].hidden = true;
        });
        //These last so they're shown
        meetingFields.forEach((field) => {
            eventEditor.widgetMap[field].hidden = false;
        });
    } else if (value === 'timeOff') {
        apptFields.forEach((field) => {
            eventEditor.widgetMap[field].hidden = true;
        });
        meetingFields.forEach((field) => {
            eventEditor.widgetMap[field].hidden = true;
        });
        //These last so they're shown
        timeOffFields.forEach((field) => {
            eventEditor.widgetMap[field].hidden = false;
        });
    } else if (value === '') { //should never hit this last section
        apptFields.forEach((field) => {
            eventEditor.widgetMap[field].hidden = true;
        });
        meetingFields.forEach((field) => {
            eventEditor.widgetMap[field].hidden = true;
        });
        // @ts-ignore
        timeOffFields.forEach((field) => {
            eventEditor.widgetMap[field].hidden = true;
        });
    }
};

export const dynamicallyCustomerTypes = (eventEditor: any, accountInfo: IAccountInfo) => {
    // if (source.editor.widgetMap["customerTypeField"].items.length === 0) {
    //     // const jobCodes = await fetchJobCodes();
    //     let contactTypes = getContactTypes(accountInfo);
    //     source.editor.widgetMap["customerTypeField"].items = contactTypes.map((customerType: FormInputOptionPropsStringString) => customerType)
    // }
    if (eventEditor.widgetMap["customerTypeField"].items.length === 0) {
        // const jobCodes = await fetchJobCodes();
        let contactTypes = getContactTypes(accountInfo);
        // console.log("getContactTypes",contactTypes)
        eventEditor.widgetMap["customerTypeField"].items = contactTypes.map((customerType: FormInputOptionPropsStringString) => customerType)
    }
}

// const customizeEventRendering = ({eventRecord, resourceRecord, assignmentRecord, renderData}) => {
//     // Customize the event content
//     // renderData.iconCls = 'b-fa b-fa-clock'; // You can set an icon class if needed
//     // renderData.eventContent = eventRecord.name || 'Custom Event'; // Set custom name or a default value
//
//     // console.log("eventRecord",eventRecord)
//
//     const jobCodeString = eventRecord.data?.jobCode ? `[${eventRecord.data?.jobCode.code}]` : ''
//
//     // return StringHelper.xss`${DateHelper.format(eventRecord.startDate, 'YYYY-MM-DD')}: ${eventRecord.name}`;
//     return StringHelper.xss`${jobCodeString} ${eventRecord.name}`;
// }


function editorFieldMaxLength(label: string, data: any, maxLength: number) {
    if (data.value.length > maxLength) {
        data.source.clearError()
        data.source.setError(`${label} is too long (${data.value.length}/${maxLength})`);
    } else {
        data.source.clearError()
    }
}


export const schedulerConfig: BryntumSchedulerProps = {
    // resourceImagePath : 'users/',


    //TODO: Check for stored date, otherwise today

    // startDate : new Date((new Date).setHours(0, 0, 0, 0)),
    // endDate   : new Date((new Date).setHours(23, 59, 59, 999)),
    startDate: new Date((new Date).setHours(6, 0, 0, 0)),
    endDate: new Date((new Date).setHours(19, 0, 0, 0)),


    // viewPreset : 'hourAndDay',
    viewPreset: 'hourAndDay',
    zoomKeepsOriginalTimespan: true,
    zoomOnTimeAxisDoubleClick: false,
    zoomOnMouseWheel: false,
    // zoomLevel: 16,
    // maxZoomLevel: 10,
    // minZoomLevel: 5,
    // zoomOnMouseWheel: false,
    infiniteScroll: false,

    timeResolution: {
        unit: 'day',
        increment: 1
    },

    crudManager: {

        validateResponse: false,

        eventStore: {
            modelClass: SchedulerEventModel
        },

        transport: {

            sync: {
                url: apiBaseUrlEndpoints + '/sync',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("accessToken")
                }
                // params: {
                //     startDate:
                // }
            },

            load: {
                // url : 'data/data.json'
                url: apiBaseUrlEndpoints + '/load',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("accessToken")
                }
            }
        },



        autoSync: true,
        // autoLoad : true //Not needed, initial load fires in useEffect

        resourceStore: {
            sorters: [{
                field: "name",
            }]
        }
    },


    enableRecurringEvents: true,


    features: {

        //group employees by their role
        group: 'role',

        //Disable click and drag to create event. There is some wierd bug going on with it and it keeps defaulting to
        eventDragCreate: false,

        //Disable copy/paste. It doesn't play well with the current setup.
        eventCopyPaste: false,

        resourceTimeRanges: {
            // Enable the resource time range elements to be reachable in the DOM (to show tooltips etc.)
            enableMouseEvents: true
        },

        timeAxisHeaderMenu: {
            disabled: true
        },

        cellMenu: {
            disabled: true,
        },

        labels: {
            top: {
                renderer: ({eventRecord, resourceRecord}: any) => {
                    let renderString = ''

                    // let uploadIcon = '<i class="b-fa b-fa-umbrella-beach"/>'
                    const externalPushMappings: ExternalPushMapping[] = eventRecord.data?.externalPushMappings?.filter((extPushMapping: ExternalPushMapping) => {
                        return extPushMapping.employee.id === resourceRecord.data.id
                    })

                    if (externalPushMappings && externalPushMappings.length > 0) {
                        const externalPushMapping = externalPushMappings[0];
                        const externalPushStatus = externalPushMapping?.externalPushStatus;

                        let dateTimeString = "";
                        if (externalPushMapping.lastPushAt) {
                            const lastPushDate = new Date(externalPushMapping.lastPushAt);
                            dateTimeString = `${lastPushDate.toLocaleTimeString()} ${lastPushDate.toLocaleDateString()}`;
                        }

                        const pushSuccessIcon = `<i class="b-fa b-fa-envelope-circle-check" title="Event pushed successfully @ ${dateTimeString}"/>`;
                        const pushErrorIcon = '<i class="b-fa b-fa-triangle-exclamation" title="Unable to push event due to an error. Check the employee\'s Google Auth Status"/>';
                        const pushNeededIcon = '<i class="b-fa b-fa-refresh" title="Event changes will soon be pushed to employee\'s Google Calendar"/>';

                        if ([ExternalPushStatus.NO_ATTEMPTS].includes(externalPushStatus)) {
                            renderString += ""
                        } else if ([ExternalPushStatus.COMPLETE].includes(externalPushStatus)) {
                            renderString += pushSuccessIcon;
                        } else if ([ExternalPushStatus.NEEDS_UPDATE].includes(externalPushStatus)) {
                            renderString += pushNeededIcon;
                        } else if (
                            [ExternalPushStatus.ERROR,
                                ExternalPushStatus.AUTH_ERROR,
                                ExternalPushStatus.GOOGLE_ERROR,
                                ExternalPushStatus.NEEDS_GOOGLE_AUTH
                            ].includes(externalPushStatus)) {
                            renderString += pushErrorIcon;
                        }
                    }

                    let labelDetails = ''
                    if (eventRecord.data.eventType == eventType.APPOINTMENT
                        && eventRecord.data?.customerType) {
                        labelDetails += `[${eventRecord.data?.customerType}] `
                    }
                    if ((eventRecord.data.eventType == eventType.APPOINTMENT || eventRecord.data.eventType == eventType.APPOINTMENT)
                        && eventRecord.data?.city) {
                        labelDetails += `${eventRecord.data?.city} `
                    }
                    renderString += StringHelper.xss`${labelDetails}`
                    // console.log(eventRecord.data.eventType,eventType.APPOINTMENT)

                    return renderString;
                }
            }
        },


        eventEdit: {

            // @ts-ignore
            autoClose: false,

            items: {
                eventTypeField: {
                    type: 'radiogroup',
                    name: 'eventType',
                    label: 'Event Type',
                    flex: '1 1 auto',
                    weight: 100,
                    //@ts-ignore
                    value: "appointment",
                    options: {
                        appointment: 'Appointment',
                        meeting: 'Meeting',
                        timeOff: 'Time Off',
                    },
                    listeners: {
                        change: onEventTypeChangeHideOrShowFieldsHandler
                    }
                },

                //Move resource field to the top
                nameField: {
                    label: 'Name',
                    name: 'name',
                    placeholder: '',
                    // maxLength : 255, //This sets hard limit with no error message. Dont do this
                    weight: 200,
                    onInput: (data: any) => {
                        editorFieldMaxLength('Name',data,200);
                    }
                },
                //JobCode dropdown
                // jobCodeField: {
                //     type: 'combo',
                //     name: 'jobCode',
                //     valueField: 'id',
                //     displayField: 'code',
                //     label: 'Job Code',
                //     placeholder: '',
                //     weight: 250,
                //     items: [],
                //
                // },

                //CustomerType dropdown
                customerTypeField: {
                    type: 'combo',
                    name: 'customerType',
                    valueField: 'value',
                    displayField: 'label',
                    label: 'Customer Type',
                    placeholder: '',
                    weight: 250,
                    items: [],
                },
                //CustomerName
                // customerNameField: {
                //     label: 'Customer Name',
                //     name: 'customerName',
                //     placeholder: '',
                //     maxLength : 255,
                //     weight: 230
                // },
                //CustomerContact
                customerContactField: {
                    type: 'textarea',
                    label: 'Customer Contact',
                    name: 'customerContact',
                    placeholder: '',
                    weight: 255,
                    width: 100,
                    onInput: (data: any) => {
                        editorFieldMaxLength('Customer Contact',data,400);
                    }
                },
                //Description
                descField: {
                    type: 'textarea',
                    label: 'Description',
                    name: 'desc',
                    weight: 300,
                    onInput: (data: any) => {
                        editorFieldMaxLength('Description',data,6000);
                    }
                },
                resourceField: {
                    label: 'Employees',
                    required: true,
                    weight: 400
                },
                startDateField: {
                    weight: 500
                },
                startTimeField: {
                    weight: 600
                },
                endDateField: {
                    weight: 700
                },
                endTimeField: {
                    weight: 800
                },

                recurrenceCombo: {
                    weight: 850,
                },

                addressLine1Field: {
                    type: 'textfield',
                    label: 'Address Line 1',
                    name: 'addressLine1',
                    weight: 900,
                    onInput: (data: any) => {
                        editorFieldMaxLength('Address Line 1',data,200
                        );
                    }
                },
                addressLine2Field: {
                    type: 'textfield',
                    label: 'Address Line 2',
                    name: 'addressLine2',
                    weight: 1000,
                    onInput: (data: any) => {
                        editorFieldMaxLength('Address Line 2',data,200);
                    }
                },
                cityField: {
                    type: 'textfield',
                    label: 'City',
                    name: 'city',
                    width: 200,
                    weight: 1100,
                    onInput: (data: any) => {
                        editorFieldMaxLength('City',data,200);
                    }
                },
                stateField: {
                    type: 'textfield',
                    label: 'State',
                    name: 'state',
                    width: 100,
                    weight: 1200,
                    onInput: (data: any) => {
                        editorFieldMaxLength('State',data,200);
                    }
                },
                zipField: {
                    type: 'textfield',
                    label: 'Zip',
                    name: 'zip',
                    inputWidth: 120, //
                    weight: 1300,
                    onInput: (data: any) => {
                        editorFieldMaxLength('Zip',data,10);
                    }
                },

            }
        }
    },

    columns: [
        {
            type: 'resourceInfo',
            text: 'Employee',
            // showImage : false,
            width: 130,
            readOnly: true,
            sortable : {
                property         : 'name',
                direction        : 'DESC',
            },
        },
    ],

    listeners: {
        // @ts-ignore
        beforeEventDelete: async (props) => {

            const {eventRecords} = props
            if (!eventRecords) {
                console.error("Trying to delete event, but eventRecords is undefined")
                return false;
            }
            if (eventRecords.length !== 1) {
                console.error("Trying to delete event, but somehow eventRecords.length !== 1. " +
                    "How did additional records get in this delete attempt? " +
                    "Returning false to try to prevent bad things from happening")
                return false;
            }
            const event = eventRecords[0]
            const result = await MessageDialog.confirm({
                title: 'Delete',
                message: StringHelper.xss`Are you sure you want to delete this event?<br><br>${event.title}`,
                okButton: 'Yes',
                cancelButton: 'No'
            });
            if (result === MessageDialog.cancelButton) {
                return false
            } else {
                return true
            }

        }
    },

    timeRangesFeature: {
        narrowThreshold: 10
    },

}