
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {Controller} from "react-hook-form";
import {FormInputProps} from "../FormInputProps";
import {toCamelCaseValidHtmlId} from "../../../helpers/utility";
import {Button, Stack} from "@mui/material";



export function DateOfLossDatePicker({ name, control, label }: FormInputProps) {

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
                // @ts-expect-error
                name={name ? name : toCamelCaseValidHtmlId(label)}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <Stack direction="row" sx={{ pb: 2 }}>
                        <DatePicker
                            value={value}
                            onChange={(newValue) => {
                                // If the newValue is null or undefined, set value to null, else set the newValue
                                onChange(newValue ? newValue : null);
                            }}
                            label={label}
                            slotProps={{ textField: { size: "small" } }}
                        />
                        <Button onClick={() => onChange(null)}>Clear</Button>
                    </Stack>
                )}
            />
        </LocalizationProvider>
    );
}