import React, {useEffect, useState} from "react";
import {useColors} from "../../../hooks/useColors";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    FormControl,
    List,
    ListItem,
    ListItemText,
    Stack,
    Switch
} from "@mui/material";
import {LocalizationProvider, TimePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import instance from "../../../providers/AxiosWithAuthProvider";

const apiBaseUrl = import.meta.env.VITE_REACT_APP_API_SERVER_URL;
// const apiBaseUrlProxy = apiBaseUrl + "/api1/proxy";
const apiBaseUrlEndpoints = apiBaseUrl + "/api1";

export interface Day {
    day: string;
    nonWorking: boolean;
    startTime: string;
    endTime: string;
}

export function WorkingDays() {

    const [isLoading, setIsLoading] = useState(true);
    const [days, setDays] = useState<Day[]>([]);
    const [originalDays, setOriginalDays] = useState<Day[]>([]);
    const [hasValidChanges, setHasValidChanges] = useState(false);

    useEffect(() => {
        const timePattern = /^(0[1-9]|1[0-2]):[0-5][0-9] [APMapm]{2}$/;
        const hasDifferentChanges = days.some(
            (day, index) =>
                //Day info has to have changed
                (day.nonWorking !== originalDays[index].nonWorking ||
                day.startTime !== originalDays[index].startTime ||
                day.endTime !== originalDays[index].endTime) &&
                //Make sure the time is full selected and valid
                (timePattern.test(day.startTime) && timePattern.test(day.endTime)) &&
                //Make it ensure that endTime comes after startTime. Also maybe check error state of timepickers
                ((new Date(`01/01/1970 ${day.endTime}`)).getTime() > (new Date(`01/01/1970 ${day.startTime}`)).getTime())
        );
        setHasValidChanges(hasDifferentChanges);
    }, [days]);

    useEffect(() => {
        console.log("originalDays changed",originalDays)
    }, [originalDays]);

    useEffect(() => {
        // Load working days from the controller
        instance.get(apiBaseUrlEndpoints + "/working-days")
            .then((response) => {
                setDays(response.data);
                const copiedDays = JSON.parse(JSON.stringify(response.data));
                setOriginalDays(copiedDays);
                setIsLoading(false);
                console.log("days", response.data);
            })
            .catch((error) => {
                console.error("Error loading working days:", error);
            });
    }, []);

    const handleStartTimeChange = (index: number, value: Date | null) => {
        const updatedDays = [...days];
        updatedDays[index].startTime = value
            ? value.toLocaleTimeString([], {hour: "2-digit", minute: "2-digit"})
            : "";
        setDays(updatedDays);
        setHasValidChanges(true);
    };

    const handleEndTimeChange = (index: number, value: Date | null) => {
        const updatedDays = [...days];
        updatedDays[index].endTime = value
            ? value.toLocaleTimeString([], {hour: "2-digit", minute: "2-digit"})
            : "";
        setDays(updatedDays);
        setHasValidChanges(true);
    };

    const handleSaveChanges = () => {
        // Update working days in the database
        instance.put(apiBaseUrlEndpoints + "/working-days", days, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                console.log("Working days updated:", response.data);
                setHasValidChanges(false);
            })
            .catch((error) => {
                console.error("Error updating working days:", error);
            });
    };

    const handleCancelChanges = () => {
        setDays(originalDays);
        setHasValidChanges(false);
    };

    const colors = useColors();
    const handleSwitchChange = (dayToSwitch: Day) => {
        const daysToUpdate = days.map(day => {
            if (day.day === dayToSwitch.day) {
                day.nonWorking = !day.nonWorking;
            }
            return day;
        })
        setDays(daysToUpdate)
    }
    return (

        <Card elevation={4}>
            <CardHeader title="Working Days"/>
            <CardContent style={{
                borderTop: '1px solid grey',
                borderBottom: '1px solid grey',
                maxHeight: '50vh',
                overflow: 'auto'
            }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    {isLoading ? <CircularProgress style={{color: colors.lightBlue}}/> :
                        <List>
                            {days.map((day, index) => (
                                <React.Fragment key={`fragment-${day.day}`}>
                                    <ListItem key={"list-" + day.day}
                                    >
                                        {/*<ListItemIcon><Square sx={{color: cityColor.hexColor}}/></ListItemIcon>*/}
                                        {/*<Stack*/}
                                        {/*    justifyContent={"space-between"}*/}
                                        {/*    direction={"row"}*/}
                                        {/*    // spacing={2}*/}
                                        {/*>*/}
                                        <ListItemText primary={day.day}
                                                      secondary={day.nonWorking ? "Non-working" : "Working"}
                                        />
                                        <Switch
                                            checked={!day.nonWorking}
                                            onChange={() => {
                                                handleSwitchChange(day)
                                            }}
                                        />

                                        <FormControl>
                                            <Stack direction={"row"}>
                                                <TimePicker
                                                    label={`Start time`}
                                                    disabled={day.nonWorking}
                                                    sx={{maxWidth: "180px"}}
                                                    value={
                                                        day.startTime
                                                            ? new Date(`01/01/1970 ${day.startTime}`)
                                                            : null
                                                    }
                                                    onChange={(value) =>
                                                        handleStartTimeChange(index, value)
                                                    }
                                                    maxTime={
                                                        day.endTime
                                                            ? new Date(`01/01/1970 ${day.endTime}`)
                                                            : new Date('01/01/1970 23:59')
                                                    }
                                                />
                                                <TimePicker
                                                    label={`End time`}
                                                    disabled={day.nonWorking}
                                                    sx={{maxWidth: "180px"}}
                                                    value={
                                                        day.endTime
                                                            ? new Date(`01/01/1970 ${day.endTime}`)
                                                            : null
                                                    }
                                                    onChange={(value) =>
                                                        handleEndTimeChange(index, value)
                                                    }
                                                    minTime={
                                                        day.startTime
                                                            ? new Date(`01/01/1970 ${day.startTime}`)
                                                            : new Date('01/01/1970 00:00')
                                                    }
                                                />
                                            </Stack>
                                        </FormControl>
                                    </ListItem>
                                    {index < days.length - 1 && <Divider/>}
                                </React.Fragment>
                            ))}
                        </List>}
                </LocalizationProvider>


            </CardContent>
            <CardActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveChanges}
                    disabled={!hasValidChanges}
                    sx={{mr: 2}}
                >
                    Save Changes
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleCancelChanges}
                >
                    Cancel Changes
                </Button>
            </CardActions>
        </Card>
    )
        ;
}