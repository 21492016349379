import {useEffect, useRef, useState} from 'react';
import {BryntumScheduler, BryntumSchedulerProps} from "@bryntum/scheduler-react";
import {dynamicallyCustomerTypes, onEventTypeChangeHideOrShowFields, schedulerConfig} from "./schedulerConfig.ts";
import {MessageDialog, Model, StringHelper, Toast} from "@bryntum/scheduler";
import {TOAST_TIMEOUT} from "../../constants";
import {CombinedJobNimbusContactResponse, IAccountInfo} from "../../api/jobNimbusInterfaces";
import {fetchAccountInfo} from "../../api/jobNimbusApi";
import instance from "../../providers/AxiosWithAuthProvider";
import {CLEAR_LAST_CONTACT_MSG_VERBIAGE} from "../../helpers/constants";
import useWebSocket from "react-use-websocket";
import Typography from "@mui/material/Typography";
import {Command, SyncResponseError, WebSocketMessage} from "../../interfaces/SchedulerInterfaces";
import {useLastContactStore} from "../../hooks/useLastContactStore";
import {useAuth0} from "@auth0/auth0-react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';


const apiBaseUrl = import.meta.env.VITE_REACT_APP_API_SERVER_URL;
const wsUrl = import.meta.env.VITE_REACT_APP_WS_SERVER_URL;
const wsUseSsl = import.meta.env.VITE_REACT_APP_WS_USE_SSL;
const apiBaseUrlEndpoints = apiBaseUrl + "/api1";



type ErrorTab = {
    label: string;
    content: React.ReactNode; // Replace React.ReactNode with the appropriate type for your content
};

interface TabPanelProps {
    children: React.ReactNode;
    value: number;
    index: number;
    // Add any other props you expect to pass to TabPanel here
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`error-tabpanel-${index}`}
            aria-labelledby={`error-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography sx={{ whiteSpace: 'pre-line' }}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const CustomScheduler = () => {

    function formatErrorMessage(error: SyncResponseError, resources: Data[]) : string {
        const {event, errorMsg} = error;

        // Function to format the employees list
        function formatEmployeeListByIds(employees: Data[], ids: Set<string>): string {
            // Create a map for fast lookup of employees by id
            const employeeMap = new Map(employees.map(emp => [emp.id, emp]));

            return Array.from(ids)
                .map(id => {
                    const employee = employeeMap.get(id);
                    return employee ? `${employee.name ?? 'Unknown Name'} - ID# ${employee.id}` : `Unknown ID# - ${id}`;
                })
                .join(', ');
        }
        return `
    ERROR: There was a problem saving the event. Please do the following:
    1. Save this event information elsewhere
    2. Forward this entire message to the developer: mcarner@dfimail.com 
    \n
    Event Details:

    Name: ${event.name}
    Description: ${event.desc}
    Event Type: ${event.eventType}
    Start Date: ${event.startDate.toLocaleString()}
    End Date: ${event.endDate.toLocaleString()}

    Customer Details:
    - Type: ${event.customerType}
    - Name: ${event.customerName}
    - Contact: ${event.customerContact}

    Location:
    - Address: ${event.addressLine1}, ${event.addressLine2}
    - City: ${event.city}
    - State: ${event.state}
    - ZIP: ${event.zip}

    Employees: ${formatEmployeeListByIds(resources, event.resourceIds)}

    Color: ${event.eventColor}
    Class: ${event.cls}

    Recurrence Rule: ${event.recurrenceRule || 'None'}
    Exception Dates: ${event.exceptionDates.join(', ') || 'None'}
    
    Error Message: 
    ${errorMsg}
    `
    }


    // Error Dialog - START

    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorTabs, setErrorTabs] = useState<ErrorTab[]>([]);
    const [tabIndex, setTabIndex] = useState(0);

    // @ts-ignore
    const handleTabChange = (event: any, newValue: number) => {
        setTabIndex(newValue);
    };

    // @ts-ignore
    const handleErrorDialogClose = (event: any, reason: string) => {
        if (reason && reason === "backdropClick"){
            return;
        }
        setOpenErrorDialog(false);
    };


    // Error Dialog - END

    const schedulerRef = useRef<BryntumScheduler>(null);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());

    // Lock buttons while editing an event
    const [isEditingEvent, setIsEditingEvent] = useState<boolean>(false);

    // Prevent crudManager.load() from happening while certain operations are in progress
    const lockLoadingRef = useRef<boolean>(false)
    useEffect(() => {
        console.log("lockLoadingRef", lockLoadingRef.current)
        if (schedulerRef.current) {
            if (!lockLoadingRef.current && !pendingSyncRef) {
                console.log("load() after unlocking lockLoadingRef")
                schedulerRef.current.instance.crudManager.load()
            }

            if (lockLoadingRef.current && pendingSyncRef.current) {
                console.log("no load() after unlocking lockLoadingRef due to pendingSync")
            }
        }

    }, [lockLoadingRef.current]);
    const [lockLoading, setLockLoading] = useState<boolean>(false);
    useEffect(() => {
        console.log("lockLoading", lockLoading)
    }, [lockLoading]);

    function lockLoadingFunction(b: boolean) {
        setLockLoading(b)
        lockLoadingRef.current = b
    }

    // Same as lockLoadingRef, however specific to while sync() is in progress
    const pendingSyncRef = useRef<boolean>(false)
    // useEffect(() => {
    //     console.log("pendingSyncRef", pendingSyncRef.current)
    // }, [pendingSyncRef.current]);


    const {isLoading, isAuthenticated} = useAuth0();
    const accountInfoRef = useRef<IAccountInfo | undefined>(undefined);

    useEffect(() => {
        if (isAuthenticated && !isLoading) {
            (async () => {
                let accountInfoTemp = await fetchAccountInfo();
                // console.log("accountInfo", accountInfoTemp);
                accountInfoRef.current = accountInfoTemp;
            })();
        }

    }, [isAuthenticated, isLoading]);

    const accessToken = localStorage.getItem("accessToken")
    if (!accessToken) {
        return (
            <Typography>
                accessToken not available
            </Typography>
        )
    }

    //lastContact START
    const lastContactStore = useLastContactStore()
    const lastContactRef = useRef(lastContactStore.lastContact);

    // Update the ref whenever the store changes
    useEffect(() => {
        lastContactRef.current = lastContactStore.lastContact;
    }, [lastContactStore.lastContact]);
    //lastContact END


    //WebSockets START
    const wsProtocol = wsUseSsl == "TRUE" ? "wss" : "ws"
    const socketUrl = `${wsProtocol}://${wsUrl}/ws/scheduler`


    // @ts-ignore
    //TODO: Implement readyState handling. Need to display error message if it fails.
    const {lastMessage, readyState} = useWebSocket(socketUrl, {
            protocols: ["Authorization", accessToken],
            share: true,
            retryOnError: true,
            // @ts-ignore
            shouldReconnect: (e) => true,
            reconnectInterval: 4,
            reconnectAttempts: 2,
            heartbeat: {
                message: "ping",
                returnMessage: "pong"
            },
            onError: (e) => {
                console.error('Error in websocket', e)
            },
            // @ts-ignore
            onOpen: (e) => {
                Toast.show({
                    html: `Server sync established`,
                    timeout: TOAST_TIMEOUT,
                });
            },
            // @ts-ignore
            onClose: (e) => {
                Toast.show({
                    html: `Warning: Server sync lost. You may not see updates from the server. 
                    Reload the page to fix this issue.`,
                    timeout: Infinity,
                });
            },
        },
        Boolean(accessToken && schedulerRef?.current?.instance)
    );

    useEffect(() => {
        if (lastMessage === null) {
            return;
        }
        //lastMessage updates when we receive a message
        // console.log("lastMessage", lastMessage)
        const response: WebSocketMessage = JSON.parse(lastMessage?.data)
        // console.log("response", response)
        if (schedulerRef.current && response) {
            const scheduler = schedulerRef.current.instance

            // console.log("command", response.command)
            if (response?.command === Command.FORCE_SYNC) {

                if (!lockLoadingRef.current) {
                    console.log("FORCE_SYNC load()")
                    scheduler.crudManager.load()
                } else {
                    console.log("FORCE_SYNC load() delayed because of lockLoading")
                }

            }
            if (response?.command === Command.BROADCAST_CHANGES) {

                if (!lockLoadingRef.current) {
                    console.log("BROADCAST_CHANGES load()")
                    scheduler.crudManager.load()
                } else {
                    console.log("BROADCAST_CHANGES load() delayed because of lockLoading")
                }

                // //Using the syncRequest for comparison, sort the syncResponse into the correct bucket
                // const eventChanges = {
                //     added: response.syncResponse.events.rows?.filter(row => {
                //         // Check if the event.id is found within response.syncResponse.events.rows
                //         return response.syncRequest.events.added?.some(event => event.id === row.id);
                //     }) || [],
                //     updated: response.syncResponse.events.rows?.filter(row => {
                //         // Check if the event.id is found within response.syncResponse.events.rows
                //         return response.syncRequest.events.updated?.some(event => event.id === row.id);
                //     }) || [],
                //     removed: response.syncResponse.events?.removed ? response.syncResponse.events.removed : []
                // }
                // scheduler.crudManager.applyChangeset(eventChanges)


            }
        }
    }, [lastMessage]);

    // const [isLoading, setIsLoading] = useState<boolean>(false);
    // useEffect(() => {
    //     console.log(`Websocket state: ${ReadyState[readyState]}`)
    //     if (schedulerRef.current){
    //         if (readyState === ReadyState.CONNECTING){
    //             console.log("scheduler.maskBody(\"Connecting to server...\")")
    //             schedulerRef.current.instance.maskBody("Connecting to server...")
    //         }
    //         if (readyState === ReadyState.OPEN && !isLoading) {
    //             //Set timespan and load initial data
    //             console.log("Calling initial LOAD")
    //             setIsLoading(true)
    //             updateSchedulerTimeSpan(selectedDate);
    //         }
    //     }
    // }, [readyState,schedulerRef.current]);
    //WebSockets END

    //UPDATE CURRENT_TIME TIMERANGE start

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //
    //         if (schedulerRef.current?.instance?.crudManager) {
    //             var crudManager = schedulerRef.current?.instance?.crudManager
    //             // @ts-ignore
    //             console.log('Executing function every 5 seconds...', crudManager?.timeRangeStore?._data);
    //             // @ts-ignore
    //             const currentDateTimeString = getCurrentDateTimeWithOffset();
    //             // const newTimeRange = {
    //             //     name: getCurrentTime12HourFormat(),
    //             //     id: "CURRENT_TIME",
    //             //     startDate: getCurrentDateTimeString() + "000000",
    //             //     endDate: getCurrentDateTimeString() + "000000",
    //             // }
    //
    //             const newTimeRange = {
    //                 name: currentDateTimeString,
    //                 id: "CURRENT_TIME",
    //                 startDate: currentDateTimeString,
    //                 endDate: currentDateTimeString,
    //             }
    //
    //             const changeSet = {
    //                 timeRanges: {
    //                     added: [],
    //                     modified: [newTimeRange],
    //                     removed: []
    //                 }
    //             }
    //             crudManager?.applyChangeset(changeSet)
    //
    //             // console.log(timeRangeChangeSet)
    //             // @ts-ignore
    //             // crudManager?.inlineData.timeRangesData.map(timeRange => {
    //             //     if (timeRange.id === "CURRENT_TIME") {
    //             //         console.log("before update inlineData.timeRangesData",timeRange.startDate)
    //             //         const currentDateTimeString = getCurrentDateTimeWithOffset();
    //             //         return {
    //             //             ...timeRange,
    //             //             // name: getCurrentTime12HourFormat(),
    //             //             name: currentDateTimeString,
    //             //             startDate: currentDateTimeString,
    //             //             endDate: currentDateTimeString,
    //             //         }
    //             //     }
    //             //     return timeRange;
    //             // })
    //
    //
    //             // crudManager?.timeRangeStore.applyChangeset(timeRangeChangeSet)
    //             // @ts-ignore
    //             // console.log(crudManager?.timeRangeStore._data)
    //             // @ts-ignore
    //             // crudManager?.timeRangeStore._data.map(timeRange => {
    //             //     if (timeRange.id === "CURRENT_TIME") {
    //             //         console.log("after applyChangeSet",timeRange.startDate)
    //             //     }
    //             //     return timeRange;
    //             // })
    //         }
    //     }, 5000); // 15 seconds in milliseconds
    //
    //     // Clear interval when component unmounts to prevent memory leaks
    //     return () => clearInterval(intervalId);
    // }, []); // Empty dependency array means this effect runs only once after initial render
    //
    // function getCurrentDateTimeString(): string {
    //     const now = new Date();
    //     const year = now.getFullYear();
    //     const month = String(now.getMonth() + 1).padStart(2, '0');
    //     const day = String(now.getDate()).padStart(2, '0');
    //     const hours = String(now.getHours()).padStart(2, '0');
    //     const minutes = String(now.getMinutes()).padStart(2, '0');
    //     const seconds = String(now.getSeconds()).padStart(2, '0');
    //     const milliseconds = String(now.getMilliseconds()).padStart(3, '0');
    //
    //     return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    // }
    // function getCurrentTime12HourFormat(): string {
    //     const now = new Date();
    //     let hours = now.getHours();
    //     const minutes = String(now.getMinutes()).padStart(2, '0');
    //     const ampm = hours >= 12 ? 'PM' : 'AM';
    //     hours = hours % 12;
    //     hours = hours ? hours : 12; // Convert midnight (0 hours) to 12 AM
    //
    //     return `${hours}:${minutes}${ampm}`;
    // }
    //
    // function getCurrentDateTimeWithOffset(): string {
    //     const now = new Date();
    //     const isoString = now.toISOString();
    //     const timezoneOffsetHours = Math.floor(now.getTimezoneOffset() / 60);
    //     const timezoneOffsetMinutes = Math.abs(now.getTimezoneOffset() % 60);
    //     const timezoneOffsetFormatted = `${timezoneOffsetHours < 0 ? '-' : '+'}${String(Math.abs(timezoneOffsetHours)).padStart(2, '0')}:${String(timezoneOffsetMinutes).padStart(2, '0')}`;
    //
    //     return isoString.replace('Z', timezoneOffsetFormatted);
    // }

    //UPDATE CURRENT_TIME TIMERANGE end


    const prepopulateEventEditor = (eventRecord: any, eventEditor: any, lastContactRef: CombinedJobNimbusContactResponse | null) => {
        if (eventRecord.isCreating) {
            // let data = eventRecord.originalData
            //Always set "appointment" as the default radio option:
            eventEditor.widgetMap["eventTypeField"].value = "appointment"
            // data.eventType = "appointment"

            console.log("lastContact", lastContactRef)

            //If lastContact is available, plug in the info
            if (lastContactRef) {

                // console.log(eventEditor.widgetMap)

                const customerContact = `${lastContactRef.jobNimbusContactResponse.first_name} ${lastContactRef.jobNimbusContactResponse.last_name}\nC: ${lastContactRef.jobNimbusContactResponse.mobile_phone}\nW: ${lastContactRef.jobNimbusContactResponse.mobile_phone}\nH: ${lastContactRef.jobNimbusContactResponse.mobile_phone}`

                eventEditor.widgetMap["nameField"].value = lastContactRef.jobNimbusContactResponse.display_name
                eventEditor.widgetMap["customerTypeField"].value = lastContactRef.jobNimbusContactResponse.record_type_name
                eventEditor.widgetMap["customerContactField"].value = customerContact
                eventEditor.widgetMap["addressLine1Field"].value = lastContactRef.jobNimbusContactResponse.address_line1
                eventEditor.widgetMap["addressLine2Field"].value = lastContactRef.jobNimbusContactResponse.address_line2
                eventEditor.widgetMap["cityField"].value = lastContactRef.jobNimbusContactResponse.city
                eventEditor.widgetMap["stateField"].value = lastContactRef.jobNimbusContactResponse.state_text
                eventEditor.widgetMap["zipField"].value = lastContactRef.jobNimbusContactResponse.zip
                if (lastContactRef?.createActivityResponse?.note) {
                    eventEditor.widgetMap["descField"].value = lastContactRef.createActivityResponse.note
                }


                // Toast.show({
                //     // html: `Successfully sent Google Auth email to : ${eventRecord.email}`,
                //     html: `Event automatically filled out with info from ` + StringHelper.xss`${lastContactRef.jobNimbusContactResponse.display_name}` + "" +
                //         "<br><br> If you don't want this to happen, click the 'Forget Last Contact' button",
                //     timeout: TOAST_TIMEOUT,
                // });

            }

        }
    }



    useEffect(() => {

        if (schedulerRef.current) {
            const scheduler = schedulerRef.current.instance
            //Set event listeners
            scheduler.on('beforeEventEdit', (event: any) => {
                console.log("beforeEventEdit", event)
                // lockLoadingRef(true)
                lockLoadingFunction(true)
                setIsEditingEvent(true)
                return event;
            });
            scheduler.on('beforeEventEditShow',  async (props: any) => {

                console.log("beforeEventEditShow", event)
                const {editor: eventEditor, eventRecord} = props;

                //Keep editor open, even if it loses focus
                eventEditor.autoClose = false


                //Always hide/show fields depending on EventType
                const value = eventRecord?.data?.eventType ? eventRecord.data.eventType : "appointment"
                onEventTypeChangeHideOrShowFields(eventEditor, value)

                //Grab and load customer types from JobNimbus accountInfo
                if (accountInfoRef.current) {
                    dynamicallyCustomerTypes(eventEditor, accountInfoRef.current)
                }

                //Fill out fields given certain conditions
                prepopulateEventEditor(eventRecord, eventEditor, lastContactRef.current);


            })

            scheduler.on('afterEventEdit', (event: any) => {
                console.log("afterEventEdit", event)
                // setLockLoading(false)
                lockLoadingFunction(false)
                setIsEditingEvent(false)
                return event;
            });

            scheduler.on('afterEventSave', (event: any) => {
                pendingSyncRef.current = true
                lockLoadingFunction(true)
                console.log("afterEventSave", event)
                return event;
            })
            // scheduler.on('onBeforeEventSave', (event: any) => {
            //     return event;
            // })

            scheduler.crudManager.on('beforeSync', (thisObj: any) => {
                console.log("beforeSync", thisObj)
                pendingSyncRef.current = true
                lockLoadingFunction(true)
                return thisObj;
            });

            scheduler.crudManager.on('sync', (thisObj: any) => {
                console.log('sync', thisObj);
                pendingSyncRef.current = false;
                lockLoadingFunction(false);

                const errors = thisObj?.response?.errors || [];
                if (errors.length > 0) {

                    // console.log(scheduler.crudManager.resourceStore.records)
                    // @ts-ignore
                    const resourceList = scheduler.crudManager.resourceStore.records.map((record: Model) => record.data);
                    setErrorTabs(errors.map((error: SyncResponseError, index: number) => ({
                        label: `Error ${index + 1}`,
                        content: formatErrorMessage(error, resourceList),
                    })));
                    setOpenErrorDialog(true);
                }

                return thisObj;
            });

            scheduler.crudManager.on('syncFail', (event: any) =>{
                console.log('syncFail',event)
            })

            // scheduler.crudManager.on('beforeSyncApply', (thisObj: any) => {
            //     console.log("beforeSyncApply",thisObj)
            //     return thisObj;
            // });

            // scheduler.crudManager.on('beforeLoadApply', (thisObj: any) => {
            //     console.log("beforeLoadApply",thisObj)
            //     return thisObj;
            // });
            // scheduler.crudManager.on('hasChanges', (thisObj: any) => {
            //     console.log("hasChanges",thisObj)
            //     return thisObj;
            // });

            //EVENT DRAGGING - start
            //Lock loading before dragging starts
            scheduler.on('beforeEventDrag', (thisObj: any) => {
                console.log("beforeEventDrag", thisObj)
                lockLoadingFunction(true)
                return thisObj;
            });

            // Unlock loading after dropping event in new location
            scheduler.on('afterEventDrag', (thisObj: any) => {
                console.log("afterEventDrag", thisObj)
                lockLoadingFunction(false)
                return thisObj;
            });

            //Unlock loading if aborted or in same location
            scheduler.on('eventDragAbort', (thisObj: any) => {
                console.log("eventDragAbort", thisObj)
                lockLoadingFunction(false)
                return thisObj;
            });

            //Unlock loading if drag operation is stopped for any reason
            scheduler.on('eventDragReset', (thisObj: any) => {
                console.log("eventDragReset", thisObj)
                lockLoadingFunction(false)
                return thisObj;
            });
            //EVENT DRAGGING - END

            //EVENT RESIZE - start
            scheduler.on('eventResizeStart', (thisObj: any) => {
                console.log("eventResizeStart", thisObj)
                lockLoadingFunction(true)
                return thisObj;
            });
            scheduler.on('eventResizeEnd', (thisObj: any) => {
                console.log("eventResizeEnd", thisObj)
                lockLoadingFunction(false)
                return thisObj;
            })
            //EVENT RESIZE - end

            //Load data for selected timespan
            updateSchedulerTimeSpan(selectedDate);
        }


    }, [schedulerRef.current])

    const updateSchedulerTimeSpan = async (date: Date) => {
        const scheduler = schedulerRef?.current?.instance
        // console.log("updateSchedulerTimeSpan")
        // let startDate = new Date(date.setHours(0, 0, 0, 0))
        // let endDate = new Date(date.setHours(23, 59, 59, 999))
        let startDate = new Date(date.setHours(6, 0, 0, 0))
        let endDate = new Date(date.setHours(19, 0, 0, 0))
        if (scheduler?.crudManager) {

            //UpdateTimespan
            await scheduler.setTimeSpan(startDate, endDate);


            //Update load params

            // @ts-ignore
            scheduler.crudManager.on('beforeLoad', (event) => {
                // console.log(event)
                event.pack.startDate = startDate.toISOString()
                event.pack.endDate = endDate.toISOString()
                return event;
            });

            //load data
            // await scheduler.crudManager.load();
            try {
                console.log("updateSchedulerTimespan load()")
                await scheduler.crudManager.load();
            } catch (e) {
                console.log("ERROR scheduler.crudManager.load:", e)
            }

            // //Load data
            // if (!isLoading){
            //     console.log("scheduler.maskBody(\"Loading data from server...\")")
            //     scheduler.maskBody("Loading data from server...")
            //     const loadRequest = {
            //         command: Command.LOAD,
            //         startDate: startDate,
            //         endDate: endDate,
            //     }
            //
            //     sendMessage(JSON.stringify(loadRequest));
            // }

        }
    };

    // @ts-ignore
    const handlePreviousDay = async () => {
        const scheduler = schedulerRef?.current?.instance
        // console.log("handlePreviousDay clicked")
        if (scheduler && selectedDate) {
            await scheduler.maskBody("Loading...")
            const newDate = new Date(selectedDate);
            newDate.setDate(selectedDate.getDate() - 1);
            setSelectedDate(newDate);
            updateSchedulerTimeSpan(newDate);
            await scheduler.unmaskBody()
        }
    };

    const handleNextDay = async () => {
        const scheduler = schedulerRef?.current?.instance
        // console.log("handleNextDay clicked")
        if (scheduler && selectedDate) {
            await scheduler.maskBody("Loading...")
            const newDate = new Date(selectedDate);
            newDate.setDate(selectedDate.getDate() + 1);
            setSelectedDate(newDate);
            await updateSchedulerTimeSpan(newDate);
            await scheduler.unmaskBody()
        }
    };


    const handleDatePickerChange = async (date: Date) => {
        console.log(date)
        const scheduler = schedulerRef?.current?.instance
        if (scheduler && date) {
            await scheduler.maskBody("Loading...")
            setSelectedDate(date);
            await updateSchedulerTimeSpan(date);
            await scheduler.unmaskBody()
        }
    };

    const postTriggerEventPush = async (eventId: string, employeeId: number): Promise<string> => {

        const response = await instance.post(`${apiBaseUrlEndpoints}/pushevent/${eventId}/${employeeId}`);

        // Check if the request was successful (status code in the range 200-299)
        if (response.status == 200) {
            // Return the response body as a string
            return response.data;
        } else if (response.status == 400) {
            // If there was an error, throw an error with the status text
            throw new Error(`${response.data}`);
        } else {
            // If there was an error, throw an error with the status text
            throw new Error(`Request failed with status ${response.status}: ${response.statusText}`);
        }

    };


    // @ts-ignore
    const handlePushEventMenuItemClick = async ({source: scheduler, eventRecord, eventElement, resourceRecord}) => {

        //Confirmation Dialog
        const result = await MessageDialog.confirm({
            title: 'Push Event',
            message: StringHelper.xss`Are you sure you want to push this event?<br><br>${eventRecord.title}`,
            okButton: 'Yes',
            cancelButton: 'No'
        });
        if (result === MessageDialog.cancelButton) {
            return;
        }

        const resourceId = eventElement.dataset.resourceId
        const eventId = eventRecord.originalData.id

        // //Check employees' role's. If it's inspector, display additional dialog
        // const employees = scheduler._resourceStore._storage._values.filter((resource: any) => {
        //     return (eventRecord.originalData.resourceIds.includes(resource._id) &&
        //         resource?.originalData?.role.toLowerCase() === Role.INSPECTOR.toLowerCase())
        // })
        // if (employees && employees.length > 0) {
        //     // const inspectorList = inspectors.map((inspector: any) => inspector?.originalData.name).join("<br>")
        //     const inspectorList = employees.filter((employee: any) => (employee?.id === resourceId))
        //         .map((employee: any) => employee.name)
        //
        //     const result2 = await MessageDialog.confirm({
        //         title: 'Push Event',
        //         message: StringHelper.xss`The following employee is a ${Role.INSPECTOR.toUpperCase()}, do you still want to push this event?<br><br>${inspectorList}`,
        //         okButton: 'Yes',
        //         cancelButton: 'No'
        //     });
        //     if (result2 === MessageDialog.cancelButton) {
        //         return;
        //     }
        // }

        //Push event to resourceId's Google Calendars
        //Api call to endpoint
        try {
            schedulerRef.current?.instance.maskBody("Pushing event...")
            await postTriggerEventPush(eventId, resourceId)

            Toast.show({
                // html: `Successfully sent Google Auth email to : ${eventRecord.email}`,
                html: `Event has been pushed to employee's Google Calender`,
                timeout: TOAST_TIMEOUT,
            });
        } catch (e: any) {
            console.log(e)
            Toast.show({
                // html: `Error pushing event to employee's Google Calender: ` + StringHelper.xss`${e.message}`,
                html: `Error pushing event to employee's Google Calender. Make sure their Google Calendar is authorized`,
                timeout: TOAST_TIMEOUT,
            });
        } finally {
            console.log("after manual push event load()")
            await schedulerRef.current?.instance.crudManager.load()
            // await schedulerRef.current?.instance.repaintEventsForResource(resourceRecord)
            schedulerRef.current?.instance.unmaskBody()
        }

    };

    //Passed to datepicker calendar - WIP and not working yet

    const handleForgetLastContact = async () => {
        //Check if there is a Last Contact
        if (!lastContactStore.lastContact) {
            Toast.show({
                // html: `Successfully sent Google Auth email to : ${eventRecord.email}`,
                html: `There is no last contact to forget`,
                timeout: TOAST_TIMEOUT,
            });
            return;
        }
        //Show MessageDialog asking if we want to clear it
        const result = await MessageDialog.confirm({
            title: 'Forget Last Contact',
            message: StringHelper.xss`${CLEAR_LAST_CONTACT_MSG_VERBIAGE}<br><br>Contact:<br>${lastContactStore.lastContact?.jobNimbusContactResponse?.display_name}`,
            okButton: 'Yes',
            cancelButton: 'No'
        });
        if (result === MessageDialog.cancelButton) {
            return;
        }

        //If yes, clear it
        lastContactStore.setLastContact(null)


        Toast.show({

            html: StringHelper.xss`Last contact forgotten: ${lastContactStore.lastContact?.jobNimbusContactResponse?.display_name}`,
            timeout: TOAST_TIMEOUT,
        });
    }


    const addnConfig: BryntumSchedulerProps = {

        tbar: [
            '->',
            {
                type: 'widget',
                html: lastContactStore.lastContact ? "<label>" + StringHelper.xss`Last Contact: ${lastContactStore.lastContact?.jobNimbusContactResponse?.display_name}` + "</label>" : "<label>Last Contact: None</label>"
            },
            {
                type: 'button',
                ref: 'clearLastContact',
                cls: '',
                icon: '',
                text: 'Forget Last Contact',
                disabled: lastContactStore.lastContact === null,
                onClick: () => {
                    handleForgetLastContact()
                }
            },
            {
                type: 'button',
                ref: 'previousDay',
                cls: '',
                icon: '',
                text: 'Previous Day',
                // disabled: isEditingEvent || lockLoadingRef.current,
                disabled: isEditingEvent || lockLoading,
                onClick: async () => {
                    handlePreviousDay()
                }
            },
            {
                type: 'button',
                ref: 'nextDay',
                cls: '',
                icon: '',
                text: 'Next Day',
                // disabled: isEditingEvent || lockLoadingRef.current,
                disabled: isEditingEvent || lockLoading,
                onClick: async () => {
                    // console.log("NEXT DAY clicked")
                    handleNextDay()
                }
            },
            {
                // hidden: true, //TODO: Fix this and unhide it later
                type: 'datefield',
                ref: 'datepicker',
                label: 'Selected Date',
                value: selectedDate, // Set default date value
                disabled: isEditingEvent || lockLoading || pendingSyncRef.current,
                picker: {
                    listeners: {
                        // @ts-ignore
                        // beforeShow: (thisObj) => {
                        //     console.log("onBeforeShow - source",thisObj)
                        //     // await handleDatePickerBeforeShow()
                        //     // await setLockLoading(true);
                        //     // lockLoadingFunction(true)
                        //     // console.log("onBeforeHide - lockLoadingRef.current", lockLoadingRef.current)
                        //     return thisObj
                        // },
                        // // @ts-ignore
                        // hide: (event: any) => {
                        //     console.log("onBeforeHide")
                        //     // setLockLoading(false);
                        //     lockLoadingFunction(false)
                        //     console.log("onBeforeHide - lockLoadingRef.current", lockLoadingRef.current)
                        // },
                    }

                },

                // onHide: () => {
                //     console.log("onHide")
                // },
                onChange: event => {
                    // Handle date change event
                    console.log('Selected Date:', event.value);
                    handleDatePickerChange(event.value);
                    return event;
                },
            }

        ],

        eventMenuFeature: {
            items: {
                unassignEvent: false,
                pushEvent: {
                    text: 'Push Event',
                    icon: 'b-icon b-fa-share-from-square', // Change to the desired icon
                    weight: 200, // Adjust the weight to control the position in the context menu
                    onItem: handlePushEventMenuItemClick
                },
            }
        },
    }


    // @ts-ignore
    return (
        <>
            <BryntumScheduler
                ref={schedulerRef}
                {...schedulerConfig}
                {...addnConfig}
            />
            <Dialog
                open={openErrorDialog}
                onClose={handleErrorDialogClose}
                disableEscapeKeyDown={true}
                aria-labelledby="error-dialog-title"
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle id="error-dialog-title">
                    Sync Errors
                    {/*// @ts-ignore*/}
                    <IconButton
                        aria-label="close"
                        onClick={handleErrorDialogClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="error tabs">
                            {errorTabs.map((tab, index) => (
                                <Tab label={tab.label} id={`error-tab-${index}`} aria-controls={`error-tabpanel-${index}`} />
                            ))}
                        </Tabs>
                    </Box>
                    {errorTabs.map((tab, index) => (
                        <TabPanel value={tabIndex} index={index}>
                            {tab.content}
                        </TabPanel>
                    ))}
                </DialogContent>
            </Dialog>
        </>
    );
};


