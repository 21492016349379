import {create} from "zustand";
import {CombinedJobNimbusContactResponse} from "../api/jobNimbusInterfaces";

interface LastContactStore {
    lastContact: CombinedJobNimbusContactResponse | null;
    setLastContact: (contact: CombinedJobNimbusContactResponse | null) => void;
}

export const useLastContactStore = create<LastContactStore>((set) => ({
    lastContact: null,
    setLastContact: (contact) => set({ lastContact: contact }),
}));