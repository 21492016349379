import './App.css'
import SchedulerPage from "./pages/scheduler/SchedulerPage.tsx";
import {Box} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import {SettingsPage} from "./pages/settings/SettingsPage.tsx";
import {AuthenticationGuard} from "./components/AuthenticationGuard.tsx";
import {AppHeader} from "./components/AppHeader.tsx";
import {useEffect} from "react";
import {useAuth0} from "@auth0/auth0-react";


function App() {

    const {getIdTokenClaims, isLoading, isAuthenticated, user} = useAuth0();

    const storeAccessToken = async () => {
        try {
            const idToken = await getIdTokenClaims();
            localStorage.setItem("accessToken", idToken?.__raw as string);
        } catch (error) {
            console.error("Error storing access token:", error);
        }
    };

    const storeUser = async () => {
        if (user) {
            localStorage.setItem("authUser", JSON.stringify(user))
        }
    }

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            storeAccessToken();
            storeUser();
        }
    }, [isLoading, isAuthenticated]);

    return (
        <Box className={"app"} display={"flex"} sx={{height: '100vh'}}>

            <Routes>
                <Route
                    path={"/"}
                    element={<AuthenticationGuard component={AppHeader}/>}
                >
                    <Route
                        path={"/"}
                        element={<AuthenticationGuard component={SchedulerPage}/>}
                    />
                    <Route
                        path={"settings"}
                        element={<AuthenticationGuard component={SettingsPage}/>}
                    />
                </Route>

            </Routes>
        </Box>
    )
}

export default App
