import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Edit, Error, Google, Pending, Send, Unsubscribe} from "@mui/icons-material";
import {Toast} from "@bryntum/scheduler";
import {TOAST_TIMEOUT} from "../../../constants";
import {useColors} from "../../../hooks/useColors";
import instance from "../../../providers/AxiosWithAuthProvider";

export interface ExternalPushMapping {
    externalId: null | string;
    employee: Employee;
    externalPushStatus: ExternalPushStatus;
    lastPushAt: null | Date;
    failedUpdateAttempts: null | number;
}

export enum ExternalPushStatus {
    COMPLETE = "COMPLETE",
    NEEDS_UPDATE = "NEEDS_UPDATE",
    ERROR = "ERROR",
    GOOGLE_ERROR = "GOOGLE_ERROR",
    AUTH_ERROR = "AUTH_ERROR",
    NO_ATTEMPTS = "NO_ATTEMPTS",
    NEEDS_GOOGLE_AUTH = "NEEDS_GOOGLE_AUTH"

}


export enum GoogleAuthStatus {
    NOT_STARTED = 'NOT_STARTED',
    PENDING = 'PENDING',
    AUTHORIZED = 'AUTHORIZED',
    DENIED = 'DENIED',
    REVOKED = 'REVOKED',
    ERROR = 'ERROR',
}

export enum GoogleCalendarIdStatus {
    NOT_STARTED = 'NOT_STARTED',
    CONFIRMED = 'CONFIRMED',
    ERROR = 'ERROR'
}

export interface Employee {
    id?: string;
    role: Role | null;
    externalId?: string;
    name: string;
    cellPhone: string;
    email: string;
    googleAuthStatus?: GoogleAuthStatus; // Include the GoogleAuthStatus field
    calendarId?: string;
    calendarIdIsValid?: boolean;
}

export enum Role {
    ROOFER = "Roofer",
    INSPECTOR = "Inspector",
    SALES = "Sales",
    PRODUCTION = "Production",
    MANAGER = "Manager"
}

const apiBaseUrl = import.meta.env.VITE_REACT_APP_API_SERVER_URL;
// const apiBaseUrlProxy = apiBaseUrl + "/api1/proxy";
const apiBaseUrlEndpoints = apiBaseUrl + "/api1";

export function isValidEmail(email: string) {
    // Define a regular expression pattern for email validation.
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
}

const EmployeeGoogleAuth: React.FC<{ employee: Employee, loadEmployees: () => void }> = ({employee, loadEmployees}) => {

    const [isHovered, setIsHovered] = useState(false);

    const colors = useColors();

    //Did they enter an email?
    if (!employee.email || employee.email === "") {
        return (
            <ListItemIcon>
                <Tooltip title={`Please provide an email`}>
                    <Unsubscribe sx={{color: "grey"}}/>
                </Tooltip>
            </ListItemIcon>
        )
    }

    //Is email valid?
    if (!isValidEmail(employee.email)) {
        return (
            <ListItemIcon>
                <Tooltip title={`Email is not valid: ${employee.email}`}>
                    <Unsubscribe sx={{color: "grey"}}/>
                </Tooltip>
            </ListItemIcon>
        )
    }

    const postTriggerSendGAuthEmail = async (employee: Employee): Promise<Employee> => {

        const response = await instance.post(`${apiBaseUrlEndpoints}/employees/${employee.id}/google-authenticate`);

        if (response.status !== 200) {
            console.log(response);
            const responseText = await response.data;
            // @ts-ignore
            throw new Error(`${responseText}`);
        }

        const data = response.data;
        // console.log('Parsed Data:', data);

        return data;
    };

    const handleSendEmail = (employee: Employee) => {

        (async () => {
            // Toast.show({
            //     html: `Sending Google Auth email to: ${employee.email}`,
            //     timeout: TOAST_TIMEOUT,
            // });

            try { //Make api call to send email
                const responseEmployee: Employee = await postTriggerSendGAuthEmail(employee);
                if (responseEmployee.googleAuthStatus !== GoogleAuthStatus.PENDING) {
                    // @ts-ignore
                    throw new Error("Tried to send Google Auth email but returned status was not PENDING")
                }
                Toast.show({
                    html: `Successfully sent Google Auth email to : ${employee.email}`,
                    timeout: TOAST_TIMEOUT,
                });

                loadEmployees()

            } catch (e) {
                Toast.show({
                    html: `Error while sending Google Auth Email: ${employee.email}\n${e}`,
                    timeout: TOAST_TIMEOUT,
                });
            }

        })();


    };

    //Email is valid, ready to start google auth process
    if (employee.googleAuthStatus === GoogleAuthStatus.NOT_STARTED) {
        return (
            <ListItemIcon
                onClick={() => handleSendEmail(employee)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <Tooltip title={`Click to send Google Auth email to ${employee.email}`}>
                    <Send sx={{color: isHovered ? colors.lightBlue : "grey"}}/>
                </Tooltip>
            </ListItemIcon>
        )
    }

    //Google Auth email has been sent
    if (employee.googleAuthStatus === GoogleAuthStatus.PENDING) {

        const [resendEmailDialogIsOpen, setResendEmailDialogIsOpen] = useState(false);
        return (
            <>
                <ListItemIcon
                    onClick={() => setResendEmailDialogIsOpen(true)}
                >
                    <Tooltip title={`Email sent, awaiting confirmation. Click to send again`}>
                        <Pending sx={{color: colors.lightBlue}}/>
                    </Tooltip>
                </ListItemIcon>
                <Dialog open={resendEmailDialogIsOpen}>
                    <DialogContent>
                        <Typography>You've already sent an email to {employee.name} at {employee.email}.</Typography>
                        <Typography>Do you want to send another one?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"contained"}
                                onClick={() => {
                                    setResendEmailDialogIsOpen(false)
                                    handleSendEmail(employee)
                                }}
                        >
                            Yes
                        </Button>
                        <Button variant={"outlined"}
                                onClick={() => setResendEmailDialogIsOpen(false)}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    //Google auth received!
    if (employee.googleAuthStatus === GoogleAuthStatus.AUTHORIZED) {
        return (
            <ListItemIcon>
                <Tooltip title={`Successfully authenticated with Google!`}>
                    <Google sx={{color: "green"}}/>
                </Tooltip>
            </ListItemIcon>
        )
    }

    //Error!
    if (employee.googleAuthStatus === GoogleAuthStatus.ERROR) {
        const [resendEmailDialogIsOpen, setResendEmailDialogIsOpen] = useState(false);
        return (
            <>
                <ListItemIcon
                    onClick={() => setResendEmailDialogIsOpen(true)}
                >
                    <Tooltip title={`There was an error during the Google Authentication process. Click here to send another email and try again`}>
                        <Error sx={{color: colors.errorRed}}/>
                    </Tooltip>
                </ListItemIcon>
                <Dialog open={resendEmailDialogIsOpen}>
                    <DialogContent>
                        <Typography>There was an error during the Google Authentication process</Typography>
                        <Typography>Most times this is fixed by retrying the process</Typography>
                        <Typography><br /></Typography>
                        <Typography>Do you want to send another email to {employee.name} at {employee.email}?</Typography>
                        <Typography><br /></Typography>
                        <Typography>NOTE: If this keeps happening, please send a message to the developer</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"contained"}
                                onClick={() => {
                                    setResendEmailDialogIsOpen(false)
                                    handleSendEmail(employee)
                                }}
                        >
                            Yes
                        </Button>
                        <Button variant={"outlined"}
                                onClick={() => setResendEmailDialogIsOpen(false)}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    //Revoked!
    if (employee.googleAuthStatus === GoogleAuthStatus.REVOKED) {
        const [resendEmailDialogIsOpen, setResendEmailDialogIsOpen] = useState(false);
        return (
            <>
                <ListItemIcon
                    onClick={() => setResendEmailDialogIsOpen(true)}
                >
                    <Tooltip title={`Google authentication has been revoked. You will need to restart the auth process. Click here to send another email and try again`}>
                        <Error sx={{color: colors.errorRed}}/>
                    </Tooltip>
                </ListItemIcon>
                <Dialog open={resendEmailDialogIsOpen}>
                    <DialogContent>
                        <Typography>Google authentication for this email address has been revoked by the user.</Typography>
                        <Typography>This means they have manually requested that scheduler access to their calendar to be disabled.</Typography>
                        <Typography>To regain scheduler access, you will need to restart the authentication process.</Typography>
                        <Typography><br /></Typography>
                        <Typography>Do you want to send another email to {employee.name} at {employee.email}?</Typography>
                        <Typography><br /></Typography>
                        <Typography>NOTE: If this happens without the employee actually revoking access, please send a message to the developer</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"contained"}
                                onClick={() => {
                                    setResendEmailDialogIsOpen(false)
                                    handleSendEmail(employee)
                                }}
                        >
                            Yes
                        </Button>
                        <Button variant={"outlined"}
                                onClick={() => setResendEmailDialogIsOpen(false)}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    //Denied!
    if (employee.googleAuthStatus === GoogleAuthStatus.DENIED) {
        const [resendEmailDialogIsOpen, setResendEmailDialogIsOpen] = useState(false);
        return (
            <>
                <ListItemIcon
                    onClick={() => setResendEmailDialogIsOpen(true)}
                >
                    <Tooltip title={`Google authentication has been denied. You will need to restart the auth process. Click here to send another email and try again`}>
                        <Error sx={{color: colors.errorRed}}/>
                    </Tooltip>
                </ListItemIcon>
                <Dialog open={resendEmailDialogIsOpen}>
                    <DialogContent>
                        <Typography>Google authentication for this email address has been denied by the user.</Typography>
                        <Typography>This means they followed the authentication email but chose to deny access.</Typography>
                        <Typography>To regain scheduler access, you will need to restart the authentication process and make sure they approve access.</Typography>
                        <Typography><br /></Typography>
                        <Typography>Do you want to send another email to {employee.name} at {employee.email}?</Typography>
                        <Typography><br /></Typography>
                        <Typography>NOTE: If this happens without the employee actually denying access, please send a message to the developer</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"contained"}
                                onClick={() => {
                                    setResendEmailDialogIsOpen(false)
                                    handleSendEmail(employee)
                                }}
                        >
                            Yes
                        </Button>
                        <Button variant={"outlined"}
                                onClick={() => setResendEmailDialogIsOpen(false)}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }


    //now to pick which calendar these go to
    //Need to validate calendarId
    // if (employee.googleAuthStatus === GoogleAuthStatus.NOT_STARTED) {
    //     return (
    //         <ListItemIcon
    //             onClick={() => handleSendEmail(employee)}
    //             onMouseEnter={() => setIsHovered(true)}
    //             onMouseLeave={() => setIsHovered(false)}
    //         >
    //             <Tooltip title={`Click to send Google Auth email to ${employee.email}`}>
    //                 <Send sx={{color: isHovered ? colors.lightBlue : "grey"}}/>
    //             </Tooltip>
    //         </ListItemIcon>
    //     )
    // }


    return null;
}

export const ManageEmployees = () => {

    const [employees, setEmployees] = useState<Employee[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadEmployees = async () => {
        try {
            console.log("Fetching employees")
            setIsLoading(true)
            await new Promise((resolve) => setTimeout(resolve, 250));
            const response = await instance.get(`${apiBaseUrl}/api1/employees`);
            const data: Employee [] = response.data;
            console.log("employees", data)
            // setEmployees([...data, ...data]);
            setEmployees([...data]);
            setIsLoading(false)
        } catch (error) {
            console.error('Error loading employees:', error);
        }
    };
    useEffect(() => {
        loadEmployees()
    }, []);


    //EDIT EMPLOYEE start
    const [editEmployeeDialogIsOpen, setEditEmployeeDialogIsOpen] = useState<boolean>(false);
    const [employeeToEdit, setEmployeeToEdit] = useState<Employee | null>(null);
    const [editName, setEditName] = useState<string>("");
    const [editPhone, setEditPhone] = useState<string>("");
    const [editEmail, setEditEmail] = useState<string>("");
    const [editRole, setEditRole] = useState<Role | null>(null);
    const handleEditEmployee = (employee: Employee) => {
        // console.log("employeeToEdit:",employee)
        setEmployeeToEdit(employee)
        setEditName(employee.name ? employee.name : "")
        setEditPhone(employee.cellPhone ? employee.cellPhone : "")
        setEditEmail(employee.email ? employee.email : "")
        setEditRole(employee.role ? employee.role : null)
        setEditEmployeeDialogIsOpen(true)
    };
    const handleEditDialogClose = () => {
        setEmployeeToEdit(null)
        setEditEmployeeDialogIsOpen(false)
    };

    const handleEditDialogSave = async () => {
        let employeeToSave: Employee = {
            id: employeeToEdit?.id,
            name: editName,
            cellPhone: editPhone,
            email: editEmail,
            role: editRole
        }
        await updateEmployee(employeeToSave)
        setEditEmployeeDialogIsOpen(false)
        loadEmployees()
        Toast.show({
            html: `Employee updated: ${employeeToSave.name}`,
            timeout: TOAST_TIMEOUT,
        });
    }
    const updateEmployee = async (updatedEmployee: Employee) => {
        try {
            console.log("Updating employee:", updatedEmployee);

            if (!updatedEmployee?.id) {
                console.error("Failed to update employee, no id was available", updatedEmployee);
                return;
            }

            // Replace the endpoint with the actual endpoint for updating employees
            const response = await instance.put(`${apiBaseUrl}/api1/employees/${updatedEmployee.id}`, updatedEmployee);

            if (response.status === 200) {
                console.log("Employee updated successfully");

                // If you want to refresh the list of employees after an update, you can call your loadEmployees function here.
                // await loadEmployees();
            } else {
                console.error('Failed to update employee:', response.statusText);
            }
        } catch (error) {
            console.error('Error updating employee:', error);
        }
    };
    //EDIT EMPLOYEE end

    //CREATE EMPLOYEE start
    const [createEmployeeDialogIsOpen, setCreateEmployeeDialogIsOpen] = useState<boolean>(false);
    const [createName, setCreateName] = useState<string>("");
    const [createPhone, setCreatePhone] = useState<string>("");
    const [createEmail, setCreateEmail] = useState<string>("");
    const [createRole, setCreateRole] = useState<Role>(Role.ROOFER);

    function handleCreateEmployee() {
        setCreateName("")
        setCreatePhone("")
        setCreateEmail("")
        setCreateRole(Role.ROOFER)
        setCreateEmployeeDialogIsOpen(true)
    }

    const handleCreateDialogClose = () => {
        setCreateEmployeeDialogIsOpen(false)
    };

    const handleCreateDialogSave = async () => {
        let employeeToSave: Employee = {
            name: createName,
            cellPhone: createPhone,
            email: createEmail,
            role: createRole
        }
        await createEmployee(employeeToSave)
        setCreateEmployeeDialogIsOpen(false)
        await loadEmployees();
        Toast.show({
            html: `Employee created: ${employeeToSave.name}`,
            timeout: TOAST_TIMEOUT,
        });
    }
    const createEmployee = async (createdEmployee: Employee) => {
        try {
            console.log("Creating employee:", createdEmployee);

            // Replace the endpoint with the actual endpoint for updating employees
            const response = await instance.post(`${apiBaseUrl}/api1/employees`, createdEmployee);

            if (response.status === 200) {
                console.log("Employee created successfully");

                // If you want to refresh the list of employees after creating one, you can call your loadEmployees function here.
                // await loadEmployees();
            } else {
                console.error('Failed to create employee:', response.statusText);
            }
        } catch (error) {
            console.error('Error creating employee:', error);
        }
    };
    //CREATE EMPLOYEE end

    const colors = useColors()

    return (
        <>
            <Card elevation={4}>
                <CardHeader title="Manage Employees"/>
                <CardContent style={{
                    borderTop: '1px solid grey',
                    borderBottom: '1px solid grey',
                    maxHeight: '50vh',
                    overflow: 'auto'
                }}>
                    <List>
                        {isLoading ? <CircularProgress style={{color: colors.lightBlue}}/> :
                            employees
                                .slice() // Create a copy to avoid modifying the original array
                                .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically by name
                                .map((employee: Employee, index: number) => (
                                    <React.Fragment key={`fragment-${employee.id}`}>
                                        <ListItem key={`list-${employee.id}`}>

                                            <EmployeeGoogleAuth employee={employee} loadEmployees={loadEmployees}/>

                                            <ListItemText
                                                primary={`${employee.name} (${employee.role})`}
                                                secondary={
                                                    <React.Fragment>
                                                        <span>{employee.cellPhone}</span>
                                                        <br/>
                                                        <span>{employee.email}</span>
                                                    </React.Fragment>
                                                }
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton edge="end" aria-label="edit"
                                                            onClick={() => handleEditEmployee(employee)}>
                                                    <Edit/>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        {index < employees.length - 1 && <Divider/>}
                                    </React.Fragment>
                                ))}
                    </List>

                </CardContent>
                <CardActions sx={{ml: "16px", mr: "16px", mt: "8px", mb: "8px"}}>
                    {/* Additional buttons for the action bar */}
                    {/*<IconButton aria-label="add">*/}
                    <Button
                        variant={"contained"}
                        onClick={handleCreateEmployee}
                    >
                        Add Employee</Button>
                    {/* Add your icon or button component */}
                    {/*</IconButton>*/}
                    {/* Add more buttons as needed */}
                </CardActions>
            </Card>
            <Dialog open={editEmployeeDialogIsOpen} onClose={handleEditDialogClose} maxWidth="sm" fullWidth>
                <DialogTitle>Edit Employee</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="role-label">Role</InputLabel>
                        <Select
                            labelId="role-label"
                            id="role"
                            name="role"
                            label="Role"
                            value={editRole}
                            onChange={(e) => setEditRole(e.target.value as Role)}
                        >
                            {Object.values(Role).map((role) => (
                                <MenuItem key={role} value={role.toUpperCase()}>
                                    {role}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        margin="dense"
                        label="Name"
                        name="name"
                        value={editName}
                        onChange={(e) => setEditName(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        margin="dense"
                        label="Cell Phone"
                        name="cellPhone"
                        value={editPhone}
                        onChange={(e) => setEditPhone(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        margin="dense"
                        label="Email"
                        name="email"
                        value={editEmail}
                        onChange={(e) => setEditEmail(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleEditDialogSave}
                        color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={createEmployeeDialogIsOpen} onClose={handleCreateDialogClose} maxWidth="sm" fullWidth>
                <DialogTitle>Create Employee</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="role-label">Role</InputLabel>
                        <Select
                            labelId="role-label"
                            id="role"
                            name="role"
                            label="Role"
                            value={createRole}
                            onChange={(e) => setCreateRole(e.target.value as Role)}
                        >
                            {Object.values(Role).map((role) => (
                                <MenuItem key={role} value={role.toUpperCase()}>
                                    {role}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        margin="dense"
                        label="Name"
                        name="name"
                        value={createName}
                        onChange={(e) => setCreateName(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        margin="dense"
                        label="Cell Phone"
                        name="cellPhone"
                        value={createPhone}
                        onChange={(e) => setCreatePhone(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        margin="dense"
                        label="Email"
                        name="email"
                        value={createEmail}
                        onChange={(e) => setCreateEmail(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreateDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleCreateDialogSave}
                        color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}