import React, {useMemo} from 'react';
import {DuplicateCheckResult} from "../../api/jobNimbusInterfaces";
import {MaterialReactTable, MRT_ColumnDef, MRT_RowSelectionState, useMaterialReactTable} from "material-react-table";

interface Result {
    jnid: string;
    date_created: number;
    access: boolean;
    display_name: string;
    first_name: string;
    last_name: string;
    company: string;
    matched: boolean;
    number: string;
    status_name: string;
    record_type_name: string;
    address_line1: string;
    address_line2: string;
    city: string;
    zip: string;
    state_text: string;
    email: string;
    home_phone: string;
    mobile_phone: string;
    work_phone: string;
    fax_number: string;
    image_id: string | null;
    country: string | null;
}

export interface Response {
    results: Result[];
    count: number;
    valid: boolean;
    message: string;
}

interface Props {
    results: DuplicateCheckResult[];
    tableDataIsLoading: boolean;
    rowSelection: MRT_RowSelectionState;
    setRowSelection: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>;

}

export const DuplicateContactsDataGrid: React.FC<Props> = ({ results, tableDataIsLoading, rowSelection,setRowSelection }) => {
    let cellPreStyles = {
        marginTop: '6px',
        marginBottom: '6px'
    };
    const columns = useMemo<MRT_ColumnDef<Result>[]>(
        () => [
            {
                accessorFn: (row) => `N: ${row.first_name} ${row.last_name}\nD: ${row.display_name}\nC: ${row.company}`,
                id: "name",
                header: "Name",
                Cell: ({ cell }) => (
                    <pre style={cellPreStyles}>
                        {cell.getValue<string>()}
                    </pre>
                )
            },
            {
                accessorFn: (row) => `${row.address_line1}\n${row.address_line2}\n${row.city}, ${row.state_text} ${row.zip}`,
                id: "address",
                header: "Address",
                Cell: ({ cell }) => (
                    <pre style={cellPreStyles}>
                        {cell.getValue<string>()}
                    </pre>
                )
            },
            {
                accessorFn: (row) => `M: ${row.home_phone}\nC: ${row.mobile_phone}\nW: ${row.work_phone}\nF: ${row.fax_number}`,
                header: "Phone",
                Cell: ({ cell }) => (
                    <pre style={cellPreStyles}>
                        {cell.getValue<string>()}
                    </pre>
                )
            },
            {
                accessorKey: "email",
                header: "Email",
                Cell: ({ cell }) => (
                    <pre style={cellPreStyles}>
                        {cell.getValue<string>()}
                    </pre>
                )
            },
            {
                accessorKey: "record_type_name",
                header: "Contact Type",
                Cell: ({ cell }) => (
                    <pre style={cellPreStyles}>
                        {cell.getValue<string>()}
                    </pre>
                )
            }
        ],
        []
    );


    const data = useMemo(() => (results.length > 0 ? results : []), [results]);
    const isLoading = useMemo(() => (tableDataIsLoading ? tableDataIsLoading : false), [tableDataIsLoading]);


    const table = useMaterialReactTable({
        columns,
        data,
        state: {
            isLoading: isLoading,
            rowSelection
        },

        //Row selection
        onRowSelectionChange: setRowSelection, //Comes from CallTakingDialog component




        enableStickyHeader: true,
        enableFilters: false,
        enableRowSelection: true,
        enableMultiRowSelection: false,
        enableColumnResizing: true,

        // enablePagination: false,
        // enableBottomToolbar: true,
        enableTopToolbar: false,
        // renderToolbarInternalActions: ({ table }) => (
        //     <>
        //         <Button variant={"contained"}
        //                 sx={{
        //                     backgroundColor: colors.darkBlue,
        //                     color: 'white',
        //                     mr: '6px'}}
        //         >
        //             Copy Info
        //         </Button>
        //         <Button variant={"contained"}
        //                 sx={{
        //                     backgroundColor: colors.darkBlue,
        //                     color: 'white',
        //                     mr: '6px'}}
        //         >
        //             Add As Related
        //         </Button>
        //         {/* built-in buttons (must pass in table prop for them to work!) */}
        //         <MRT_ShowHideColumnsButton table={table} />
        //         <MRT_ToggleFullScreenButton table={table} />
        //     </>
        // ),


        //clicking anywhere on the row will select it
        muiTableBodyRowProps: ({ row }) => ({
            onClick: row.getToggleSelectedHandler(),
            sx: { cursor: 'pointer' },
        }),

        //Shrink cell padding
        muiTableBodyCellProps: {
            sx: {
                paddingTop: '2px', //add a border between columns
                paddingBottom: '2px'
            },
        },

        //Make it the right height , set height property here
        muiTablePaperProps: {
            sx: {
                mb: 2,
                mt: '0px !important',
                height: "35vh",
            },
        },
        muiTableContainerProps: {
            sx: {
                height: "100%",
            },
        },
    });

    return <MaterialReactTable table={table} />;
};

export default DuplicateContactsDataGrid;
