import {FormInputOptionPropsStringString} from "../components/call-taking-form/FormInputProps.tsx";

export const toCamelCaseValidHtmlId = (str: string): string => {
    let camelCaseStr = str.replace(/\s(.)/g, function ($1) {
        return $1.toUpperCase();
    })
        .replace(/\s/g, '')
        .replace(/^(.)/, function ($1) {
            return $1.toLowerCase();
        });

    // Replace invalid id characters with "_"
    let validId = camelCaseStr.replace(/[^a-zA-Z0-9-_:]/g, "_");

    // If the first character is not a letter, prepend an "id" prefix
    if (!validId.match(/^[a-zA-Z]/)) {
        validId = 'id' + validId;
    }

    return validId;
}

export const genDataObj = (labels: string[]): FormInputOptionPropsStringString[] => {
    return labels.map((label) => {
        return {
            label: label,
            value: toCamelCaseValidHtmlId(label)
        };
    });
};

export function waitAtleastXMilliseconds(waitTime: number = 500): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, waitTime);
    });
}

// export function printPrettyDateTime(currentDate: Date | null): string {
//     if (!currentDate){
//         return ""
//     }
//     currentDate = new Date(currentDate)
//
//     const optionsDate: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'CST' };
//     const optionsTime: Intl.DateTimeFormatOptions = { timeStyle: 'short', timeZone: 'CST' };
//
//     const formatterDate = new Intl.DateTimeFormat('en-US', optionsDate);
//     const formatterTime = new Intl.DateTimeFormat('en-US', optionsTime);
//
//     const formattedDate = formatterDate.format(currentDate);
//     const formattedTime = formatterTime.format(currentDate);
//
//     return `${formattedTime} ${formattedDate}`
//
// }