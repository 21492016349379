import {FormInputProps} from "../../FormInputProps.tsx";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {Controller} from "react-hook-form";
import {toCamelCaseValidHtmlId} from "../../../../helpers/utility.tsx";


export function BasicSelect({name, control, label, options, defaultValue}: FormInputProps) {


    return (
        <FormControl
            size="small"
            fullWidth
            sx={{pb:2}}
            // sx={{
            //     '& input': {
            //         fontSize: '0.85rem', // Adjust the font size as needed
            //     },
            //     '& .MuiInputLabel-root': {
            //         fontSize: '0.85rem', // Adjust the label font size as needed
            //     },
            //     '& .MuiOutlinedInput-root': {
            //         padding: '0px', // Adjust the padding as needed
            //         '& fieldset': {
            //             borderWidth: '1px', // Adjust the border width as needed
            //         },
            //     },
            // }}
        >
            <InputLabel>{label}</InputLabel>
            <Controller
                //@ts-expect-error
                name={name ? name : toCamelCaseValidHtmlId(label)}
                control={control}
                defaultValue={defaultValue ? defaultValue : ""}
                render={({
                             field: {onChange, value},
                             // fieldState: {error},
                             //formState,
                         }) => (
                    <Select
                        label={label}
                        onChange={onChange}
                        value={value}
                    >
                        {options && options.length > 0 && [options.map(option => (
                            // @ts-ignore
                            <MenuItem value={option.value}>{option.label}</MenuItem>
                        ))]}
                    </Select>
                )}
            />
        </FormControl>
    );
}