import {FormInputOptionPropsStringString, FormInputProps} from "../FormInputProps.tsx";
import {toCamelCaseValidHtmlId, waitAtleastXMilliseconds} from "../../../helpers/utility.tsx";
import {Controller} from "react-hook-form";
import {Autocomplete, AutocompleteChangeReason, CircularProgress, debounce, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {DuplicateCheckResult} from "../../../api/jobNimbusInterfaces.ts";
import {
    getSearchContactsResults,
    transformRelatedContactResultsToAutocompleteOptions
} from "../../../api/jobNimbusFunctions.ts";


interface RelatedContactsAutocompleteProps extends FormInputProps {
    relatedContactToAdd: DuplicateCheckResult | null;
    setRelatedContactToAdd: React.Dispatch<React.SetStateAction<DuplicateCheckResult | null>>;
}


export function RelatedContactsAutocomplete({
                                                control,
                                                label,
                                                name,
                                                relatedContactToAdd,
                                                setRelatedContactToAdd,
                                                getValues,
                                                setValue
                                            }: RelatedContactsAutocompleteProps) {
    let nameNormalized = toCamelCaseValidHtmlId(label);

    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState<FormInputOptionPropsStringString[]>([]);
    // const [relatedContactsResults, setRelatedContactsResults] = useState<SearchContactResult[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const debouncedInputValueChange = debounce((value) => {
        setInputValue(value);
    }, 750);

    useEffect(() => {
        console.log(inputValue);
        (async () => {
            if (inputValue && inputValue !== "" && inputValue.length > 2) {
                // console.log("refetching triggered from debounce")
                const waiting = waitAtleastXMilliseconds(500)
                setIsLoading(true)
                // console.log(inputValue)
                //TODO: Am I using the right interface here? Double check it
                let relatedContactsResultsTemp = await getSearchContactsResults(inputValue);
                let optionsTemp = transformRelatedContactResultsToAutocompleteOptions(relatedContactsResultsTemp);
                await waiting;
                console.log("inputValue useEffect: optionsTemp", optionsTemp)
                setOptions(optionsTemp);
                // setRelatedContactsResults(relatedContactsResultsTemp);
                // console.log(relatedContactsResultsTemp)
                setIsLoading(false)
            }
        })();
    }, [inputValue]);


    const shouldWeClearOptions = (reason: AutocompleteChangeReason) => {
        if (reason === "selectOption") {
            // console.log("selectOption triggered: clearing options")
            setOptions([])
            // setRelatedContactsResults([])
        }
    };


    //relatedContactToAdd START

    //To keep it simple, we are just going to replace the current list of options with the result
    //Force the dropdown open, and the user can select it manually
    useEffect(() => {
        console.log("RelatedContactsAutocomplete - relatedContactToAdd", relatedContactToAdd)
        if (relatedContactToAdd) {

            const relatedContactToAddFormatted: FormInputOptionPropsStringString = {
                label: relatedContactToAdd.display_name,
                value: relatedContactToAdd.jnid,
            }

            //Update related value
            // @ts-ignore
            const currentValue = getValues("related")
            setValue("related",[...currentValue,relatedContactToAddFormatted])


            setRelatedContactToAdd(null);
            //
            // const isItAlreadyInOptionsArray = (contact: FormInputOptionPropsStringString): boolean => {
            //     options.map(option => {
            //         if (option.label == contact.label &&
            //             option.value == contact.value) {
            //             return true;
            //         }
            //     })
            //     return false;
            // }
            //
            // if (!isItAlreadyInOptionsArray(relatedContactToAddFormatted)) {
            //     setOptions(() => [{
            //         label: relatedContactToAddFormatted.label,
            //         value: relatedContactToAddFormatted.value
            //     }]);
            //     setRelatedContactToAdd(null);
            //     // openDropdown();
            // }

        }
    }, [relatedContactToAdd]);
    // const autocompleteRef = useRef<any>(null);
    //
    // const openDropdown = () => {
    //     // Focus the `Autocomplete` input field
    //     if (autocompleteRef.current) {
    //         const input = autocompleteRef.current.querySelector('input');
    //         if (input) {
    //             // Focus the input to open the dropdown
    //             input.focus();
    //             // Dispatch a keydown event to open the dropdown
    //             setTimeout(() => {
    //                 input.dispatchEvent(new MouseEvent('mousedown', { bubbles: true, cancelable: true }));
    //             }, 100); // 100ms timeout
    //         }
    //     }
    // };
    //relatedContactToAdd END

    // const related = useWatch({control, name:"related"});
    //
    // useEffect(() => {
    //     console.log("related",related)
    //     setOptions(related)
    // }, [related]);



    return (
        <Controller
            control={control}
            //@ts-expect-error
            name={name ? name : nameNormalized}
            render={({field: {onChange, value}}) => (
                <Autocomplete
                    // ref={autocompleteRef}
                    freeSolo={!inputValue?.length}
                    loading={isLoading}
                    loadingText={"Searching..."}
                    noOptionsText={"No matches..."}
                    size="small"
                    sx={{pb: 2,}}
                    multiple
                    filterSelectedOptions
                    //@ts-expect-error
                    onChange={(event, selectedOptions, reason) => {
                        //export type AutocompleteChangeReason =
                        //   | 'createOption'
                        //   | 'selectOption'
                        //   | 'removeOption'
                        //   | 'clear'
                        //   | 'blur';
                        shouldWeClearOptions(reason);
                        // console.log(reason)
                        // console.log(items);
                        onChange(selectedOptions);
                    }}
                    // options={options ? options : []}
                    // options={Array.isArray(options) ? options : options === undefined ? [] : [options]}
                    options={options}
                    getOptionLabel={(option) => (option as FormInputOptionPropsStringString)?.label}
                    // @ts-ignore
                    value={Array.isArray(value) ? value : value === undefined ? [] : [value]}

                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            onChange={(e) => {
                                console.log("detected change: " + e.target.value)
                                debouncedInputValueChange(e.target.value);
                                // onChange(e);
                                // setTextFieldValue(e.target.value)
                            }}
                            // value={value}
                            // value={textFieldValue}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {isLoading ? <CircularProgress color="inherit" size={20}/> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            )}
        />
    );
}