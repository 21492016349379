import {Auth0Provider, useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";
import React, {ReactNode} from "react";

interface Auth0ProviderWithNavigateProps {
    children: ReactNode;
}
// const apiBaseUrl = import.meta.env.VITE_REACT_APP_API_SERVER_URL;
// const apiBaseUrlEndpoints = apiBaseUrl + "/api1";
// export const logoutBackend = async () => {
//     try {
//         instance.post(`${apiBaseUrlEndpoints}/logout`);
//     } catch (error) {
//         throw error;
//     }
// }

export const Auth0ProviderWithNavigate: React.FC<Auth0ProviderWithNavigateProps> = ({children}) => {
    const navigate = useNavigate();
    const { isLoading } = useAuth0();

    const domain = import.meta.env.VITE_REACT_APP_AUTH0_DOMAIN;
    const clientId = import.meta.env.VITE_REACT_APP_AUTH0_CLIENT_ID;
    const redirectUri = import.meta.env.VITE_REACT_APP_AUTH0_CALLBACK_URL;
    const audience = import.meta.env.VITE_REACT_APP_AUTH0_AUDIENCE;

    if (!isLoading) {
        return null
    }

    //NOTE: Moved isAuthenticated and token storage to <App>.
    //      For some reason, the flow wasn't right and isAuthenticated was never true in this component

    const onRedirectCallback = (appState: any) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    if (!(domain && clientId && redirectUri && audience)) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                audience: audience,
                redirect_uri: redirectUri,
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
}