import {IFormInput} from "../components/call-taking-form/FormInputProps.tsx";
import {
    CombinedJobNimbusContactResponse,
    DuplicateCheckResponse,
    IAccountInfo,
    JobNimbusContact,
    SearchContactResult
} from "./jobNimbusInterfaces.ts";
import {DuplicateCheckData} from "../components/call-taking-form/CallTakingDialog";
import instance from "../providers/AxiosWithAuthProvider";


const apiBaseUrl = import.meta.env.VITE_REACT_APP_API_SERVER_URL;
// const apiBaseUrlProxy = apiBaseUrl + "/api1/proxy";
const apiBaseUrlEndpoints = apiBaseUrl + "/api1";


export const getContactByJnid = async (jnid: string) => {
    try {
        const response = await instance.get(`${apiBaseUrlEndpoints}/getcontact/${jnid}`);

        if (!response.data) {
            console.log(response);
            throw new Error('No data found');
        }

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const postNewContact = async (newContact: JobNimbusContact): Promise<CombinedJobNimbusContactResponse> => {
    try {
        const response = await instance.post(`${apiBaseUrlEndpoints}/createcontact`, newContact);

        if (!response.data) {
            console.log(response);
            throw new Error('No data found');
        }

        if (response.status == 200) {
            // Return the response body as a string
            console.log('Contact Created:', response.data);
            return response.data as CombinedJobNimbusContactResponse;
        } else if (response.status == 400) {
            // If there was an error, throw an error with the status text
            throw new Error(`${response.data}`);
        } else {
            // If there was an error, throw an error with the status text
            throw new Error(`Request failed with status ${response.status}: ${response.statusText}`);
        }

    } catch (error) {
        console.log("postNewContact error", error)
        throw error;
    }


};

export const postUpdateContact = async (jnidToUpdate: string, contactToUpdate: JobNimbusContact): Promise<CombinedJobNimbusContactResponse> => {
    try {
        const response = await instance.put(`${apiBaseUrlEndpoints}/updatecontact/${jnidToUpdate}`, contactToUpdate);

        if (!response.data) {
            console.log(response);
            throw new Error('No data found');
        }

        console.log('Contact Updated:', response.data);

        return response.data as CombinedJobNimbusContactResponse;
    } catch (error) {
        throw error;
    }
};

export const fetchSubcontractors = async (): Promise<ISubcontractorWeb[]> => {
    try {
        const response = await instance.get(`${apiBaseUrlEndpoints}/getsubcontractors`);

        if (!response.data) {
            console.log(response);
            throw new Error('No data found');
        }

        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const fetchSearchContactResults = async (inputValue: string): Promise<SearchContactResult[]> => {
    try {
        const response = await instance.get(`${apiBaseUrlEndpoints}/searchcontacts?term=${inputValue}`);

        if (!response.data) {
            console.log(response);
            throw new Error('No data found');
        }

        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};
export const fetchAccountInfo = async (): Promise<IAccountInfo | undefined> => {
    try {
        const response = await instance.get(`${apiBaseUrlEndpoints}/account`);

        if (!response.data) {
            console.log(response);
            throw new Error('No data found');
        }

        return response.data as IAccountInfo;
    } catch (error) {
        console.error(error);
    }
};

export const postDuplicateCheck = async (duplicateCheckData: DuplicateCheckData): Promise<DuplicateCheckResponse> => {
    try {
        const response = await instance.post(`${apiBaseUrlEndpoints}/duplicatecheck`, duplicateCheckData);

        if (!response.data) {
            console.log(response);
            throw new Error('No data found');
        }

        return response.data;
    } catch (error) {
        throw error;
    }
};

export function formatForJobNimbusContactSearchQueryBoolMatch(obj: QueryObject): string {
    const should: Array<{ match: { [key: string]: string } }> = [];

    for (const key in obj) {
        // @ts-ignore
        if (typeof obj[key] === 'string' && obj[key].trim() !== '') {
            should.push({
                match: {
                    [key]: obj[key] as string,
                },
            });
        }
    }

    return JSON.stringify({
        query: {
            bool: {
                should,
                minimum_should_match: 1,
            },
        },
    });
}


export function formatForJobNimbusContactSearchQueryHardFilter(obj: QueryObject): string {
    const should: Array<{ match: { [key: string]: string } }> = [];

    for (const key in obj) {

        console.log(obj[key] + " is typeof " + typeof obj[key])
        // @ts-ignore
        if (typeof obj[key] === 'string' && obj[key].trim() !== '') {
            should.push({
                match: {
                    [key]: obj[key] as string,
                },
            });
        }
    }

    return JSON.stringify({
        should,
        minimum_should_match: 1,
    });
}

export function formatForJobNimbusContactSearchQueryFuzzyMatch(obj: QueryObject): string {
    const should: Array<{ match_phrase_prefix: { [key: string]: { query: string; fuzziness?: number } } }> = [];

    for (const key in obj) {
        // @ts-ignore
        if (typeof obj[key] === 'string' && obj[key].trim() !== '') {
            should.push({
                match_phrase_prefix: {
                    [key]: {
                        query: obj[key] as string,
                        fuzziness: key === 'display_name' ? 2 : undefined,
                    },
                },
            });
        }
    }

    return JSON.stringify({
        should,
        minimum_should_match: 1,
    });
}

export function formatFormObjForRelatedContacts(obj: IFormInput): QueryObject {
    console.log(obj)
    return {
        first_name: obj.first_name,
        last_name: obj.last_name,
        display_name: obj.display_name,
    }
}

interface QueryObject {
    [key: string]: string | boolean | string[] | null;
}


export interface IJobNimbusResponse {
    count: number,
    results: IContact[],
}

export interface IRelatedContact {
    display_name: string,
}

export interface IContact {
    actual_time: number;
    address_line1: string;
    address_line2: string;
    approved_estimate_total: number;
    approved_invoice_due: number;
    approved_invoice_total: number;
    attachment_count: number;
    cf_boolean_1: boolean;
    cf_date_1: number;
    cf_date_3: number;
    city: string;
    class_id: string | null;
    class_name: string | null;
    color: string;
    color_shade: string;
    company: string;
    country_name: string;
    created_by: string;
    created_by_name: string;
    customer: string;
    date_created: number;
    date_end: number;
    date_start: number;
    date_status_change: number;
    date_updated: number;
    description: string | null;
    display_name: string;
    email: string;
    estimated_time: number;
    external_id: null;
    fax_number: string;
    fieldassists: any[];
    first_name: string;
    geo: {
        lat: number;
        lon: number;
    };
    globalpaymentsCardTokens: any[];
    home_phone: string;
    image_id: null;
    is_active: boolean;
    is_archived: boolean;
    is_closed: boolean;
    is_lead: boolean;
    is_sub_contractor: boolean;
    is_user: boolean;
    jnid: string;
    last_budget_gross_margin: number;
    last_budget_gross_profit: number;
    last_budget_revenue: number;
    last_estimate: number;
    last_estimate_date_created: number;
    last_estimate_date_estimate: number;
    last_estimate_jnid: null;
    last_estimate_number: null;
    last_invoice: number;
    last_invoice_date_created: number;
    last_invoice_date_invoice: number;
    last_invoice_jnid: null;
    last_invoice_number: null;
    last_name: string;
    location: {
        id: number;
    };
    merged: null;
    mobile_phone: string;
    number: string;
    open_edge_id: null;
    owners: {
        id: string;
    }[];
    recid: number;
    record_type: number;
    record_type_name: string;
    related: any[];
    rules: any[];
    sales_rep: string | null;
    sales_rep_name: string | null;
    source: number | null;
    source_name: string | null;
    state_text: string;
    status: number;
    status_name: string;
    subcontractors: any[];
    sunlightEvents: null;
    sunlightStatusAndId: null;
    tags: any[];
    task_count: number;
    type: string;
    website: string;
    work_phone: string;
    zip: string;
    "Date of Loss": number;
    "Contract Date": number;
    "Gated Community": boolean;
}

export interface ISubcontractorWeb {
    Name: string;
    jnid: string;
    first_name: string;
    last_name: string;
    company: string;
    display_name: string;
    Logo: string;
    Value: string;
    Color: string;
    color_shade: string;
    Email: string;
    IsActive: boolean;
    RecordType: number;
}