
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { PageLoader } from "./PageLoader.tsx";
import React from "react";

interface AuthenticationGuardProps {
  component: React.ComponentType<any>;
}

export const AuthenticationGuard: React.FC<AuthenticationGuardProps> = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
        <div className="page-layout">
            <PageLoader />
            </div>
    ),
  });

  return <Component />;
};