import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {format} from "date-fns";
import {useColors} from "../../../hooks/useColors";
import {convertExceptionDateTimes, WorkingExceptionFields} from "./WorkingExceptionFields";
import instance from "../../../providers/AxiosWithAuthProvider";

export interface WorkException {
    id?: number;
    reason: string;
    dateStart: Date;
    dateEnd: Date;
    allDay: boolean;
    startTime: Date;
    endTime: Date;
}

export interface TimeOffResponse {
    id: number;
    reason: string;
    dateStart: string;
    dateEnd: string;
    allDay: boolean;
    startTime: string;
    endTime: string;
}


const apiBaseUrl = import.meta.env.VITE_REACT_APP_API_SERVER_URL;
// const apiBaseUrlProxy = apiBaseUrl + "/api1/proxy";
const apiBaseUrlEndpoints = apiBaseUrl + "/api1";

export function WorkExceptions() {

    const getDefaultWorkException = (): WorkException => {
        // if (e.target.checked) {
        //     exceptionInProgress.startTime = new Date((new Date).setHours(0, 0, 0, 0));
        //     exceptionInProgress.endTime = new Date((new Date).setHours(23, 59, 59, 999));
        // } else {
        //     exceptionInProgress.startTime = new Date((new Date).setHours(8, 0, 0, 0));
        //     exceptionInProgress.endTime = new Date((new Date).setHours(17, 0, 0, 0));
        // }
        return {
            reason: "",
            dateStart: new Date((new Date).setHours(0, 0, 0, 0)),
            dateEnd: new Date((new Date).setHours(23, 59, 59, 999)),
            allDay: true,
            startTime: new Date((new Date).setHours(0, 0, 0, 0)),
            endTime: new Date((new Date).setHours(23, 59, 59, 999)),
        }
    }

    const [isLoading, setIsLoading] = useState(false);
    const [exceptions, setTimeOffs] = useState<WorkException[]>([]);

    useEffect(() => {
        // Fetch exceptions from the backend API
        fetchWorkExceptions();
    }, []);

    const fetchWorkExceptions = async () => {
        try {
            setIsLoading(true);
            const response = await instance.get(apiBaseUrlEndpoints + "/working-day-exceptions");
            const exceptionResponses: TimeOffResponse[] = response.data;
            const exceptions = exceptionResponses.map(convertResponseToException);
            setTimeOffs(exceptions);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching exceptions:", error);
        }
    };

    function convertResponseToException(exceptionResponse: TimeOffResponse): WorkException {
        return {
            id: exceptionResponse.id,
            reason: exceptionResponse.reason,
            allDay: exceptionResponse.allDay,
            dateStart: new Date(exceptionResponse.dateStart),
            dateEnd: new Date(exceptionResponse.dateEnd),
            startTime: new Date(exceptionResponse.startTime),
            endTime: new Date(exceptionResponse.endTime)
        };
    }


    //EDIT EXCEPTION start
    const [isEditDialogOpen, setEditDialogOpen] = useState(false);
    const [originalWorkException, setOriginalWorkException] = useState<WorkException | null>(null);
    const [editWorkException, setEditWorkException] = useState<WorkException | null>(null);


    const isSaveEditDisabled = () => {
        if (!editWorkException){
            return true
        }
        const {startDateTime, endDateTime} = convertExceptionDateTimes(editWorkException)
        if (startDateTime > endDateTime){
            return true
        }

        if (JSON.stringify(originalWorkException) === JSON.stringify(editWorkException)){
            return true
        }
        return false
    };

    const handleEditException = (index: number) => {
        setOriginalWorkException({...exceptions[index]})
        setEditWorkException({...exceptions[index]});
        setEditDialogOpen(true);
    };

    const handleUpdateException = () => {
        updateException();
    };

    const handleCancelEdit = () => {
        setEditDialogOpen(false);
    };


    const updateException = async () => {
        try {
            const response = await instance.put(apiBaseUrlEndpoints + `/working-day-exceptions/${editWorkException?.id}`, editWorkException);
            // @ts-ignore
            const updatedExceptionResponse: TimeOffResponse = response.data;
            await fetchWorkExceptions();
            setEditWorkException(getDefaultWorkException);
            setEditDialogOpen(false);
        } catch (error) {
            console.error("Error updating exception:", error);
        }
    };

    //EDIT EXCEPTION end

    //CREATE EXCEPTION start
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);
    const [newWorkException, setNewWorkException] = useState<WorkException | null>(getDefaultWorkException());
    const [isCreateExceptionSaveDisabled, setIsCreateExceptionSaveDisabled] = useState<boolean>(true);

    const handleCancelCreate = () => {
        setNewWorkException(getDefaultWorkException());
        setIsCreateDialogOpen(false);
    };
    const handleCreateException = () => {
        createException();
    };
    const createException = async () => {
        try {
            const response = await instance.post(apiBaseUrlEndpoints + "/working-day-exceptions", newWorkException);
            const createdExceptionResponse: TimeOffResponse = response.data;
            const createdException: WorkException = convertResponseToException(createdExceptionResponse);
            setTimeOffs([...exceptions, createdException]);
            setNewWorkException(getDefaultWorkException());
            setIsCreateDialogOpen(false);
        } catch (error) {
            console.error("Error adding exception:", error);
        }
    };    //CREATE EXCEPTION end

    //DELETE EXCEPTION start
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

    const openDeleteDialog = (index: number) => {
        setDeleteDialogOpen(true);
        setDeleteIndex(index);
    };

    const handleConfirmDelete = () => {
        if (deleteIndex != null) {
            deleteException(exceptions[deleteIndex]?.id);
            setDeleteIndex(null);
        }
        setDeleteDialogOpen(false);
    };

    const handleCancelDelete = () => {
        setDeleteIndex(null);
        setDeleteDialogOpen(false);
    };

    const deleteException = async (id: number | undefined) => {
        if (!id) {
            return;
        }
        try {
            await instance.delete(apiBaseUrlEndpoints + `/working-day-exceptions/${id}`);
            const updatedExceptions: WorkException[] = exceptions.filter((exception) => exception.id !== id);
            setTimeOffs(updatedExceptions);
        } catch (error) {
            console.error("Error deleting exception:", error);
        }
    };
    //DELETE EXCEPTION end


    const colors = useColors();

    return (
        <>
            <Card elevation={4}>
                <CardHeader title="Exceptions/Holidays"/>
                <CardContent style={{
                    borderTop: '1px solid grey',
                    borderBottom: '1px solid grey',
                    maxHeight: '50vh',
                    overflow: 'auto'
                }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        {isLoading ? <CircularProgress style={{color: colors.lightBlue}}/> :
                            <List>
                                {exceptions.map((exception, index) => {
                                    let dateStartString = format(exception.dateStart, 'MM/dd/yyyy')
                                    let startTimeString = format(exception.startTime, 'h:mm a')
                                    let dateEndString = format(exception.dateEnd, 'MM/dd/yyyy')
                                    let endTimeString = format(exception.endTime, 'h:mm a')

                                    return (
                                        <React.Fragment key={`fragment-${index}`}>
                                            <ListItem key={"list-" + index}>
                                                <ListItemText
                                                    primary={exception.reason}
                                                    secondary={`Start: ${
                                                        dateStartString
                                                    } ${exception.allDay ? '' : startTimeString}, End: ${
                                                        dateEndString
                                                    } ${exception.allDay ? '' : endTimeString}`}
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="edit"
                                                        onClick={() => handleEditException(index)}
                                                    >
                                                        <Edit/>
                                                    </IconButton>
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="delete"
                                                        onClick={() => openDeleteDialog(index)}
                                                    >
                                                        <Delete/>
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            {index < exceptions.length - 1 && <Divider/>}
                                        </React.Fragment>
                                    )
                                })}
                            </List>}
                    </LocalizationProvider>


                </CardContent>
                <CardActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setIsCreateDialogOpen(true)}
                        sx={{ml: 2}}
                    >
                        Add Exception/Holiday
                    </Button>
                </CardActions>
            </Card>

            {/*CREATE DIALOG*/}
            <Dialog
                open={isCreateDialogOpen}
                onClose={handleCancelCreate}
                aria-labelledby="create-dialog-title"
            >
                <DialogTitle id="create-dialog-title">Create Exception/Holiday</DialogTitle>
                <DialogContent>
                    <WorkingExceptionFields
                        exception={newWorkException}
                        setException={setNewWorkException}
                        setIsSaveButtonDisabled={setIsCreateExceptionSaveDisabled}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelCreate} color="primary">
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleCreateException}
                        color="primary"
                        disabled={isCreateExceptionSaveDisabled}
                    >
                        Save Changes
                    </Button>
                </DialogActions>
            </Dialog>

            {/*EDIT DIALOG*/}
            <Dialog
                open={isEditDialogOpen}
                onClose={handleCancelEdit}
                aria-labelledby="edit-dialog-title"
            >
                <DialogTitle id="edit-dialog-title">Edit Exception</DialogTitle>
                <DialogContent>
                    <WorkingExceptionFields
                        exception={editWorkException}
                        setException={setEditWorkException}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelEdit} color="primary">
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleUpdateException}
                        color="primary"
                        disabled={isSaveEditDisabled()}
                    >
                        Save Changes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isDeleteDialogOpen}
                onClose={handleCancelDelete}
                aria-labelledby="delete-dialog-title"
            >
                <DialogTitle id="delete-dialog-title">
                    Are you sure you want to delete this exception?
                </DialogTitle>
                <DialogContent>
                    {deleteIndex !== null && (
                        <>
                            <Typography variant="body1">
                                Reason: {exceptions[deleteIndex].reason}
                            </Typography>
                            <Typography variant="body1" sx={{mt: 2}}>
                                {`Start: ${
                                    format(exceptions[deleteIndex].dateStart, 'MM/dd/yyyy')
                                } ${exceptions[deleteIndex].allDay ? '' :
                                    format(exceptions[deleteIndex].startTime, 'h:mm a')}`
                                }
                            </Typography>
                            <Typography variant="body1" sx={{mt: 2}}>
                                {`End: ${
                                    format(exceptions[deleteIndex].dateEnd, 'MM/dd/yyyy')
                                } ${exceptions[deleteIndex].allDay ? '' :
                                    format(exceptions[deleteIndex].endTime, 'h:mm a')}`
                                }
                            </Typography>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                        No
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
